<template>
	<div class="b5-container-xl py-3 text-sm">
		<div class="w-100 d-flex justify-content-between">
			<div>
				&copy; {{ toUserTz().format('YYYY') }} <b>{{ $globalData.coachdata.fullname }}</b>
			</div>
			<div>
				Powered by <a href="https://build5.app" target="_blank" rel="nofollow" class="text-reset text-decoration-none text-secondary fw-medium">Build5</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
		}
	}
</script>