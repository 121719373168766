<template>
	<div :class="getClasses()">
		<dynamic-element-render 
			v-for="(item, index) in instance.childrens" :key="'col-'+makeid(12)+'-'+item.id"
			group="page" :instance="item" />
	</div>
</template>

<script>
export default {
	props: {
		instance: null
	},
	methods: {
		getClasses: function() {
			var classes = [ 'row' ];
			
			if (! this.instance.options.hasOwnProperty('container_size')) {
				classes.push('b5-container-lg');
			}
			else {
				if (this.instance.options.container_size == 'small') {
					classes.push('b5-container-sm');	
				}
				else if (this.instance.options.container_size == 'default') {
					classes.push('b5-container-lg');	
				}
				else if (this.instance.options.container_size == 'large') {
					classes.push('b5-container-xl');	
				}
				else if (this.instance.options.container_size == 'xl') {
					classes.push('b5-container-xxl');	
				}
				else if (this.instance.options.container_size == 'full-width') {
					classes.push('w-100');	
				}
			}
			
			return classes.join(' ');
		}
	},
}
</script>