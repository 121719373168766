var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasProcessedData() && _vm.instance.processed_data.length)?_c('div',{staticClass:"elements--post-category--post-collection-smart"},[_c('div',{staticClass:"b5-container-xl"},[_c('div',{staticClass:"heading mb-2 lh-sm"},[_c('div',{staticClass:"d-flex justify-content-between align-items-top"},[(_vm.instance.options.title)?_c('h4',{staticClass:"mb-0 fw-bold text-opposite"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.instance.options.title)+"\n\t\t\t\t")]):_vm._e(),_vm._v(" "),(_vm.hasCtaAction() && _vm.hasCtaText())?_c('b5-link',{staticClass:"btn btn-ghost text-primary text-nowrap text-decoration-none p-0",attrs:{"href":_vm.instance.options.cta_web_url}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.instance.options.cta_text)+"\n\t\t\t\t\t\n\t\t\t\t\t"),_c('i',{staticClass:"b5-fa-icon fa-chevron-right"})]):_vm._e()],1),_vm._v(" "),(_vm.instance.options.excerpt)?_c('p',{staticClass:"mb-0 text-secondary"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.instance.options.excerpt)+"\n\t\t\t")]):_vm._e()]),_vm._v(" "),(_vm.hasProcessedData() && _vm.instance.processed_data.length)?_c('div',{class:_vm.getRowClasses()},_vm._l((_vm.instance.processed_data),function(item,index){return _c('div',{class:_vm.getColumnClasses()},[_c('router-link',{staticClass:"d-block w-100 text-reset text-decoration-none b5-bg-body2 b5-transitionable b5-hover-scale card-boxed rounded-app h-100",attrs:{"to":{
					name: 'fitchallenge.preset.show',
					params: {
						challengePresetId: item.id,
					}
				}}},[_c('div',{staticClass:"d-flex align-items-stretch w-100 h-100"},[(_vm.getFileVersionUrl(item.thumbnail))?_c('div',{staticClass:"col-3 col-lg-4"},[_c('div',{staticClass:"bg-cover rounded-start text-center d-flex w-100 h-100 lazy",attrs:{"data-src":_vm.getFileVersionUrl(item.thumbnail)}},[_c('img',{staticClass:"opacity-0 w-100",attrs:{"src":_vm.getFileVersionUrl(item.thumbnail),"alt":""}})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col lh-sm p-2 py-lg-4 px-lg-3 align-self-center"},[_c('h5',{staticClass:"mb-0 text-opposite"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(item.name)+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),(item.excerpt)?_c('small',{staticClass:"text-sm text-secondary"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(item.excerpt)+"\n\t\t\t\t\t\t\t")]):_vm._e()])])])],1)}),0):_c('div',{class:_vm.getRowClasses()},_vm._l((10),function(item){return _c('div',{class:_vm.getColumnClasses()},[_vm._m(0,true),_vm._v(" "),_vm._m(1,true)])}),0)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"placeholder-wave"},[_c('div',{staticClass:"ratio ratio-16x9 mb-1"},[_c('div',{staticClass:"placeholder rounded-app"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"placeholder-glow"},[_c('div',{staticClass:"col-8 placeholder placeholder-lg mb-0"})])
}]

export { render, staticRenderFns }