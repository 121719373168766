<template>
	<div>
				<div class="row g-0 align-items-stretch">
					<div class="col-4 col-lg-3">
						<button v-if="selected_phone_prefix" type="button" class="btn btn-ghost d-block w-100 h-100 border text-start" @click="selected_phone_prefix=null">
							<span :class="'fi rounded border me-1 fi-'+selected_phone_prefix.code.toLowerCase()">
								<span class="visually-hidden">{{ selected_phone_prefix.code }}</span>
							</span>
							{{ selected_phone_prefix.dial_code }}
							
							<i class="fas fa-fw fa-edit ms-1"></i>
						</button>
						<div v-else>
							<div class="form-floating">
								<input type="text" class="form-control" placeholder="+39" @keyup="phone_edited=true" v-model="filter_phone_prefix" v-bind:class="isValidField('phone_prefix').valid ? '' : 'is-invalid'" autocomplete="off">
								<label>Prefisso</label>
							</div>
							<ul class="list-group overflow-auto border shadow" style="max-height:180px" v-if="filtered_phone_prefixes.length">
								<li v-for="prefix in filtered_phone_prefixes" @click="selectPhonePrefix(prefix)" class="list-group-item list-group-item-action cursor-pointer">
									<span :class="'fi rounded border me-1 fi-'+prefix.code.toLowerCase()">
										<span class="visually-hidden">{{ prefix.code }}</span>
									</span>
									{{ prefix.dial_code }}
								</li>
							</ul>
						</div>
					</div>
					<div class="col-8 col-lg-9">
						<div class="form-floating">
							<input type="text" class="form-control" placeholder="+39" minlength="6" maxlength="30" v-model="phone" v-bind:class="isValidField('phone').valid ? '' : 'is-invalid'" @keyup="phone_edited=true" autocomplete="tel-national">
							<label>Numero di telefono</label>
						</div>
					</div>
				</div>
				<div v-if="!isValidField('phone').valid" class="invalid-feedback">
					{{ isValidField('phone').message }}
				</div>
	
	</div>
</template>
<script>
export default {
	props: ['phone_prefixes'],
  data: function() {
	return {
	  isLoading: false,

		phone_prefix: null,
		phone: null,
		phone_edited: false,
		  
	  selected_phone_prefix: null,
	  filter_phone_prefix: null,
	}
  },
  
  watch: {
	  phone: function(newVal) {
		  this.sendData();
	  },
	  filter_phone_prefix: function(newVal) {
		  this.sendData();
	  },
  },
  
  computed: {
	  filtered_phone_prefixes: function() {
		  if (this.phone_prefixes && Array.isArray(this.phone_prefixes) && this.phone_prefixes.length) {
				if (this.filter_phone_prefix) {
					const vm = this;
					return this.phone_prefixes.filter(function(el) {
						return el.dial_code.includes(vm.filter_phone_prefix);
					});
				}
		  }
		  else if (this.filter_phone_prefix) { // questo consente di accettare il prefisso anche se per qualche motivo la lista dei prefissi non è caricata
			  this.phone_prefix = this.filter_phone_prefix;
		  }
		  
		  return [];
	  },
  },
  
  methods: {
	  
	  sendData: function() {
		  this.$emit('updated', {
			  phone: this.phone,
			  prefixobj: this.selected_phone_prefix,
			  phoneprefix: this.selected_phone_prefix ? this.selected_phone_prefix.dial_code : this.phone_prefix,
			  isvalid: this.isAllValid() == 1,
		  })
	  },
	  
	  selectPhonePrefix: function(data) {
		  this.selected_phone_prefix = data;
		  this.phone_prefix = data.dial_code;
		  this.filter_phone_prefix = data.dial_code;
		  this.sendData();
	  },
	  
	  isAllValid: function() {
		  return (this.phone && this.phone_prefix
				   && this.isValidField('phone').valid
				   && this.isValidField('phone_prefix').valid
		  ) ? 1 : 0;
	  },
	
	isValidField: function(field) {
		if (field == 'phone_prefix') {
			if (!this.phone_edited) {
				return {
					valid: true,
				};
			}
			if (this.phone_prefixes && Array.isArray(this.phone_prefixes) && this.phone_prefixes.length) {
				if (this.selected_phone_prefix && this.selected_phone_prefix.dial_code == this.phone_prefix) {
					return {
						valid: true,
					};
				}
			}
			else if (this.phone_prefix) { // questo consente di accettare il prefisso anche se per qualche motivo la lista dei prefissi non è caricata
				return {
					valid: true,
				};
			}
		}
		else if (field == 'phone') {
			if (!this.phone_edited) {
				return {
					valid: true,
				};
			}
			
			var regex = /^[0-9 ()+-]+$/;
			
			if (this.phone !== null && this.phone != '' && this.phone.trim().length >= 7 && regex.test(this.phone)) {
				return {
					valid: true,
				};
			}
		}
		
		return {
			valid: false,
			message: 'Campo obbligatorio',
		};
	},
	
  },
  
  mounted: function() {
	  if (this.phone_prefixes && this.phone_prefixes.length && Vue.prototype.$globalData.websitedata.shop.current_country.countrycode) {
		  this.selected_phone_prefix = this.phone_prefixes.filter(function(el) {
			  return el.code == Vue.prototype.$globalData.websitedata.shop.current_country.countrycode;
		  })[0]; // pre-seleziono di base il primo valore possibile giusto per far capire che si tratta di un prefisso nazionale
	  }
  },
  
  beforeDestroy: function() {
	  
  },
  
}
</script>