<template>
	<div v-if="data">
		<div v-bind:class="selected_date ? 'b5-container-lg' : 'b5-container-sm'">
			<div class="card card-body shadow-sm border rounded-app mb-5">
			<div class="row align-items-stretch">
				<div v-bind:class="selected_date ? 'col-lg-5' : 'col-lg-6'" class="col-12 border-end p-lg-4 mb-4">
						
						<div class="mb-2 d-flex w-100 align-items-center">
							<div class="b5-bg-body4 rounded-circle bg-cover me-2" :style="'width:40px;height:40px;background-image: url('+$globalData.coachdata.avatar_url+');'"></div>
							<div class="fs-6 fw-medium text-secondary">{{ customcoachname || $globalData.coachdata.firstname }}</div>
						</div>
					
						<div v-if="selected_calendarly" class="mb-1">
							<div class="fs-2 fw-bold d-flex w-100 align-items-center">
								<!-- <span class="rounded-pill d-inline-block border me-2" :style="'width:24px;height:24px;background-color:'+selected_calendarly.accent_color"></span> -->
								{{ selected_calendarly.name }}
							</div>
							<div class="fs-5 fs-light mt-1" v-if="selected_calendarly.excerpt">{{ selected_calendarly.excerpt }}</div>
						</div>
						
						<div v-if="isCalendarlyLoading" class="d-flex justify-content-center pt-3">
							<div class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
						<div v-else-if="current_step==2 || current_step==3" class="">
							<div class="d-flex w-100 align-items-center justify-content-between rounded-app b5-bg-body p-3 mb-3">
								<div class="w-100 d-flex">
									<i class="fas fa-fw fa-calendar fs-5 me-2 text-primary"></i>
									<div>
										<div class="fw-bold">
											{{ toUserTz(selected_date+' '+selected_time, use_timezone).format('LLLL') }}
										</div>
										
										<div v-if="selected_calendarly.location_address">
											{{ selected_calendarly.location_address.location_formatted_address }}
										</div>
									</div>
								</div>
								
								<button type="button" class="btn btn-white border ms-2 rounded-pill shadow-sm" @click="resetSelection()" v-if="current_step==2">
									<i class="fas fa-fw fa-edit"></i>
								</button>
							</div>
							
							<ul class="list-unstyled">
								<li v-if="selected_calendarly.duration_mins" class="mb-2 fw-medium text-secondary">
									<i class="b5-fa-icon fa-fw me-2 fs-5 fa-clock text-secondary"></i>
									{{ selected_calendarly.duration_mins }} min
								</li>
								<li v-if="selected_calendarly.location_address" class="mb-2 fw-medium text-secondary">
									<i class="b5-fa-icon fa-fw me-2 fs-5 fa-location-dot text-secondary"></i>
									{{ selected_calendarly.location_address.location_formatted_address }}
									
									<div v-if="selected_calendarly && selected_calendarly.location_address" class="w-100 mt-4" style="height:180px">
										<iframe :src="'https://www.google.com/maps?q='+encodeURIComponent(selected_calendarly.location_address.location_formatted_address)+'&z=12&output=embed'" class="w-100 h-100 b5-bg-body4 rounded-app border-0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
									</div>
								</li>
								<li class="fw-medium text-secondary">
									<i class="b5-fa-icon fa-fw me-2 fs-5 fa-info-circle text-secondary"></i>
									Dopo la prenotazione riceverai un messaggio di conferma con tutti i dettagli dell'appuntamento
								</li>
							</ul>
						</div>
						<div v-else-if="selected_calendarly">
							<div class="mb-4 mt-3">
								<ul class="list-unstyled">
									<li v-if="selected_calendarly.duration_mins" class="mb-2 fw-medium text-secondary">
										<i class="b5-fa-icon fa-fw me-2 fs-5 fa-clock text-secondary"></i>
										{{ selected_calendarly.duration_mins }} min
									</li>
									<li v-if="selected_calendarly.location_address" class="mb-2 fw-medium text-secondary">
										<i class="b5-fa-icon fa-fw me-2 fs-5 fa-location-dot text-secondary"></i>
										{{ selected_calendarly.location_address.location_formatted_address }}
										
										<div v-if="selected_calendarly && selected_calendarly.location_address" class="w-100 mt-4" style="height:180px">
											<iframe :src="'https://www.google.com/maps?q='+encodeURIComponent(selected_calendarly.location_address.location_formatted_address)+'&z=12&output=embed'" class="w-100 h-100 b5-bg-body4 rounded-app border-0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
										</div>
									</li>
									<li class="fw-medium text-secondary">
										<i class="b5-fa-icon fa-fw me-2 fs-5 fa-info-circle text-secondary"></i>
										Dopo la prenotazione riceverai un messaggio di conferma con tutti i dettagli dell'appuntamento
									</li>
								</ul>
							</div>
							<div v-if="selected_calendarly.description && current_step < 2" v-html="selected_calendarly.description"></div>
						</div>
				</div>
				<div v-bind:class="selected_date ? 'col-lg-7' : 'col-lg-6'" class="col-12 p-lg-4">
					
					<div v-if="current_step==3" class="text-center">
						<i class="fas fw-fa fa-circle-check text-success display-4 mb-2"></i>
						<h1 class="display-3">Tutto perfetto!</h1>
						<p>
							Grazie per aver prenotato il tuo appuntamento. Nelle prossime ore ti invieremo un messaggio su Whatsapp o tramite SMS al numero di telefono da te indicato durante la prenotazione con tutti i dettagli necessari per vivere al meglio la tua esperienza.
						</p>
						<p v-if="selected_calendarly.metas?.typ_custom_text" v-html="selected_calendarly.metas?.typ_custom_text"></p>
						<!-- <p>
							A breve riceverai un'email di riepilogo dal nostro sistema automatico all'indirizzo da te inserito durante la prenotazione.<br>
							Se non la ricevi entro qualche minuto, controlla nella cartella <b>SPAM</b> e aggiungi l'indirizzo del mittente ai tuoi <span class="badge bg-warning text-uppercase"><i class="fas fa-fw fa-star"></i> Preferiti</span>, così da non perderti comunicazioni future.
						</p>
						<p class="lead">Per adesso non devi fare altro. Ti contatterò il prima possibile al numero da te indicato per tutti i dettagli e per darti conferma della prenotazione.</p> -->
					</div>
					
					<div v-else-if="current_step==2">
						<div class="">
							<div class="d-flex w-100 align-items-start">
								<button type="button" class="btn bg-white text-primary rounded-pill shadow-sm border fs-5 me-3" @click="goToStep(1)">
									<i class="fas fa-fw fa-arrow-left"></i>
								</button>
								<div class="w-100">
									<div class="fs-5 fw-bold">Su di te</div>
									<p class="text-secondary">Alcune informazioni importanti su di te che ci aiuteranno a organizzare il tutto al meglio</p>
								</div>
							</div>
							
							<div v-if="errormsg" class="alert alert-danger">
								{{ errormsg }}
							</div>
							
							<div class="row g-3 mb-3">
								<div class="col-12 col-lg-6">
									<div class="form-floating">
										<input type="text" class="form-control" placeholder="Nome" v-model="formvalues.firstname">
										<label>Nome <span class="text-warning fw-bold">*</span></label>
									</div>
								</div>
								<div class="col-12 col-lg-6">
									<div class="form-floating">
										<input type="text" class="form-control" placeholder="Cognome" v-model="formvalues.lastname">
										<label>Cognome</label>
									</div>
								</div>
							</div>
							
							<div class="form-floating mb-3">
								<input type="text" class="form-control" placeholder="Indirizzo email" v-model="formvalues.email">
								<label>Indirizzo email <span class="text-warning fw-bold">*</span></label>
							</div>
							
							<div class="mb-3">
								<label>Telefono <span class="text-warning fw-bold">*</span></label>
								<input-phone-with-prefix :phone_prefixes="data.phone_prefixes" @updated="phoneNumberUpdated" />
								
								<div class="b5-bg-body p-2 rounded text-sm">
									<i class="fas fa-fw fa-circle-info"></i> Ti invieremo un messaggio su Whatsapp/SMS con tutti i dettagli dell'appuntamento
								</div>
							</div>
							
							<div class="mb-3" v-for="question in selected_calendarly.questions">
								<label>{{ question.name }} <span class="text-warning fw-bold" v-if="question.required">*</span></label>
								
								<select class="form-select" v-model="formvalues[question.fieldnameid]" v-if="question.type == 'select'">
									<option v-for="opt in question.options.options" :value="opt.value">
										{{ opt.label }}
									</option>
								</select>
								
								<input v-else type="text" class="form-control" :placeholder="question.placeholder" v-model="formvalues[question.fieldnameid]" :required="question.required">
							</div>
							
							<div class="form-floating mb-3">
								<textarea class="form-control" rows="4" style="height:5rem" placeholder="Note (opzionale)" v-model="book_notes"></textarea>
								<label>Note (opzionale)</label>
							</div>
							
							<div class="form-check mb-4">
								<input class="form-check-input" type="checkbox" value="1" id="allterms" v-model="formvalues.terms">
								<label class="form-check-label" for="allterms">
									Dichiaro di aver letto, compreso e di accettare <a target="_blank" :href="'/'+$route.params.teamSlug+'/terms'" class="text-secondary text-decoration-underline fw-medium">Condizioni d'uso</a> e di aver letto la nostra <a target="_blank" :href="'/'+$route.params.teamSlug+'/privacy-policy'" class="text-secondary text-decoration-underline fw-medium">Informativa sulla privacy</a>.
								</label>
							</div>
							
							<div class="form-check mb-5" v-if="$globalData.teamdata.newsletter.is_available">
								<input class="form-check-input" type="checkbox" value="1" id="subscribenewsletter" v-model="subscribenewsletter">
								<label class="form-check-label" for="subscribenewsletter">
									Voglio restare aggiornato/a sulle ultime novità e ricevere email con consigli di benessere, nuovi prodotti e messaggi promozionali. <span class="b5-bg-body rounded-pill py-1 px-2 ms-1 fw-medium text-sm" style="background-color:var(--hlf-succulent)">Consigliato</span>
								</label>
							</div>
							
							<button v-if="isCalendarlyLoading" class="btn d-block w-100 btn-lg btn-primary rounded-pill" type="button" disabled>
							  <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
							  <span class="visually-hidden" role="status">Loading...</span>
							</button>
							<button v-else type="button" class="btn d-block w-100 btn-lg btn-primary rounded-pill" @click="goToStep(3)">
								Continua
							</button>
						</div>
					</div>
					
					<div v-else class="pt-3 position-relative">
						<div v-if="isCalendarlyLoading" class="position-absolute top-0 start-0 end-0 bottom-0 w-100 h-100 d-flex align-items-center justify-content-center overlay-light overlay-xl py-5 rounded">
							<div class="spinner-border text-primary" role="status">
								<span class="visually-hidden">Loading...</span>
							  </div>
						</div>
					<div class="fs-5 mb-3 fw-medium">Seleziona una data e un orario:</div>
					
					<div v-if="errormsg" class="alert alert-danger">
						{{ errormsg }}
					</div>
					
					<div class="row g-3">
						<div v-bind:class="selected_date ? 'col-lg-7' : ''" class="col-12 mb-4">
							<div class="">
								<div class="d-flex w-100 justify-content-center align-items-center mb-2">
									<button type="button" class="btn btn-ghost rounded-pill text-secondary" @click="selected_month=toUserTz(selected_month, use_timezone).subtract(1, 'months');resetSelection()" v-bind:disabled="toUserTz(selected_month).subtract(1, 'months').diff(toUserTz(), 'months') < 0">
										<i class="fas fa-fw fa-chevron-left"></i>
									</button>
									
									<button type="button" class="btn btn-ghost rounded-pill text-capitalize mx-2" @click="selected_month=toUserTz(undefined, use_timezone).format('YYYY-MM');resetSelection()">
										{{ toUserTz(selected_month, use_timezone).format('MMMM YYYY') }}
									</button>
									
									<button type="button" class="btn btn-ghost rounded-pill text-secondary" @click="selected_month=toUserTz(selected_month, use_timezone).add(1, 'months');resetSelection()" v-bind:disabled="toUserTz(selected_month).add(1, 'months').diff(toUserTz(), 'months') >= 1">
										<i class="fas fa-fw fa-chevron-right"></i>
									</button>
								</div>
								
								<div class="d-flex w-100 align-items-center justify-content-start mb-1">
									<div v-for="wn in [1,2,3,4,5,6,7]" class="text-center text-secondary text-uppercase text-sm" style="width:14%">
										{{ toUserTz(undefined, use_timezone).day(wn).format('ddd') }}
									</div>
								</div>
								
								<div v-for="week in weeksInMonth" class="d-flex w-100 align-items-center justify-content-start mb-1">
									<div class="text-center" v-for="i in daysOfWeek(week)" style="width:14%">
										<button type="button" :class="'btn btn-pick-date rounded-pill '+getDateButtonClasses(i)" @click="chooseDate(i)"
											v-bind:disabled="isDayDisabled(i)">
											{{ toUserTz(i, use_timezone).format('D') }}
											
											<div>
												<span v-if="i==toUserTz(undefined, use_timezone).format('YYYY-MM-DD')" class="rounded-pill bg-primary d-block mx-auto" style="width:8px;height:8px;">
													<span class="visually-hidden">today</span>
												</span>
											</div>
										</button>
									</div>
								</div>
								
								<div class="pt-4">
									<div class="fw-bold text-secondary text-sm mb-1">Fuso orario</div>
									<div class="dropdown">
										<button type="button" class="btn btn-ghost d-block btn-sm text-secondary dropdown-toggle border" data-bs-toggle="dropdown" aria-expanded="false">
											<i class="fas fa-fw fa-globe"></i> {{ use_timezone || toUserTz(undefined, use_timezone).format('zz') }} - {{ toUserTz(undefined, use_timezone).format('LT') }}
										</button>
										<ul class="dropdown-menu overflow-auto" style="max-height:230px">
											<li v-for="tz in Intl.supportedValuesOf('timeZone')"><button type="button" class="dropdown-item" @click="use_timezone=tz" v-bind:class="tz==use_timezone ? 'active' : ''">
												{{ tz }}
												<i class="fas fa-fw fa-check ms-auto" v-if="tz==use_timezone"></i>
											</button></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div v-if="selected_date" class="col-12 col-lg-5">
							<div class="px-3">
								<div class="fs-5 fw-medium mb-2">
									{{ toUserTz(selected_date, use_timezone).format('LL') }}
								</div>
								
								<div v-if="availableSlots.length">
									<div v-for="slot in availableSlots" class="mb-2 d-flex w-100 align-items-stretch">
										<button type="button" class="btn fs-5" @click="selected_time=slot.time_start"
											v-bind:class="{
												'btn-selected-timeslot disabled d-inline-block w-50': selected_time==slot.time_start,
												'btn-timeslot d-block w-100': selected_time!=slot.time_start,
											}">
											{{ toUserTz(selected_date+' '+slot.time_start, use_timezone).format('LT') }}
											<!-- -
											{{ toUserTz(selected_date+' '+slot.time_end, use_timezone).format('LT') }} -->
										</button>
										
										<button type="button" v-if="selected_time==slot.time_start" @click="goToStep(2)" class="btn btn-primary d-inline-block w-50">
											Conferma
										</button>
									</div>
								</div>
								<div v-else class="text-center text-secondary card card-body shadow-sm border-0 rounded-app">
									Nessun orario disponibile per questa data
								</div>
							</div>
						</div>
					</div>
					</div>
					
				</div>
			</div>
			</div>
		</div>
	</div>
	<div v-else class="d-flex justify-content-center">
		<div class="spinner-border" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
</div>
</template>
<style>
.btn-selected-timeslot {
	background: #777!important;
	color: #fff!important;
	border: 1px solid #777;
}
.btn-timeslot {
	background: #fff;
	color: var(--bs-primary);
	border: 1px solid var(--bs-primary);
}
.btn-timeslot:hover {
	background: rgba(var(--bs-primary-rgb), 0.2);
	color: var(--bs-primary);
	border: 1px solid var(--bs-primary);
}
.btn-pick-date {
	
}
.date-daynum {
	font-weight:normal;
}
.date-daynum.date-unavailable {
	opacity: 0.5;
	cursor: not-allowed!important;
	pointer-events: all!important;
}
.date-daynum.date-available:not(.is-past-date) {
	background:rgba(var(--bs-primary-rgb), 0.15);
	color: inherit;
	font-weight:bold;
}
.date-daynum.selected-date {
	background: var(--bs-primary)!important;
	color: #fff!important;
}
</style>
<script>
export default {
	props: ['data', 'origin_page_url', 'customcoachname'],
	data: function() {
		return {
			total_steps: 3,
			current_step: 0,
			
			selected_month: null,
			selected_date: null,
			selected_time: null,
			use_timezone: null,
			
			isCalendarlyLoading: 0,
			selected_calendarly: null,
			
			phoneobj: null,
			
			subscribenewsletter: false,
			
			errormsg: null,
			formdata: null,
			formvalues: null,
			book_notes: null,
		}
	},
	
	metaInfo() {
		 return {
			 title: this.data.name,
			 link: [
				{ rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.3/css/flag-icons.min.css' },
			 ],
		   }; 
	  },
	
	computed: {
		getMainAreaStyle: function() {
			return (this.showSignin==1 || this.showMainVideo==1) ? 'position-fixed top-0 start-0 end-0 bottom-0 w-100 h-100 d-none' : '';
		},
		
		weeksInMonth: function() {
			if (!this.selected_month) return [];
			var weeks=[];
			var firstWeek = this.toUserTz(this.selected_month, this.use_timezone).startOf('month').week();
			var lastWeek = this.toUserTz(this.selected_month, this.use_timezone).endOf('month').week();
			
			for (var i = firstWeek; i <= lastWeek; i++) {
				weeks.push(i);
			}
			
			return weeks;
		},
		
		availableSlots: function() {
			const vm = this;
			
			if (this.selected_date && this.selected_calendarly) {
				var ondate = this.selected_calendarly.available_dates?.filter(function(el) {
					return el.date == vm.selected_date;
				});
				
				if (ondate && ondate.length) {
					return ondate[0].slots || [];
				}
			}
			
			return [];
		},
	},
	
	methods: {
		
		phoneNumberUpdated: function(x) {
			this.phoneobj = x;
			this.formvalues.phoneprefix = (x.prefixobj && x.prefixobj.hasOwnProperty('dial_code')) ? x.prefixobj.dial_code : x.phoneprefix;
			this.formvalues.phonenumber = x.phone;
		},
		
		canSubmitForm: function() {
			var isvalid=false;
			const vm = this;
			
			var validfields = 0;
			var totalrequiredfields = 0;
			Object.keys(this.formdata).forEach(function(key, index) {
				if (vm.formdata[key].required) {
					if (vm.formvalues[key]) {
						validfields++;
					}
					
					totalrequiredfields++;
				}
			});
			
			this.data.questions?.forEach(function(el) {
				if (el.required) {
					if (vm.formvalues[el.fieldnameid]) {
						validfields++;
					}
					
					totalrequiredfields++;
				}
			});
			
			isvalid = (totalrequiredfields <= validfields);
			
			return isvalid;
		},
		
		goToStep: function(step) {
			const vm = this;
			if (step == 3) {
				if (! this.canSubmitForm()) {
					this.errormsg = 'Tutti i campi sono obbligatori';
				}
				else {
					this.isCalendarlyLoading = 1;
					axios.post('/api/v6/booking/'+vm.selected_calendarly.slug+'/book', {...this.data.trackingobj, ...{
						formaction: 'bookappointment',
						at_date: this.selected_date,
						at_time: this.selected_time,
						fields: this.formvalues,
						origin_url: this.origin_page_url,
						note: this.book_notes,
					}})
					.then(res => {
						if (res.data.status == 'success') {
							vm.errormsg = null;
							vm.resetForms();
							vm.current_step = step;
							this.scrollTopAnimated(0,500); // x mobile: scrollo alla scelta dell'orario
							vm.fbPixelEvent('Schedule'); // evento fb pixel
						}
						else if (res.data.message == 'Date or time slot is not available') {
							vm.errormsg = 'La data o l\'orario selezionato non è più disponibile';
						}
						else {
							vm.errormsg = res.data.message || 'Si è verificato un errore imprevisto. Riprova più tardi.';
							console.warn('errore nel caricamento disponibilità calendario');
						}
					})
					.finally(res => {
						vm.isCalendarlyLoading = 0;
					});
				}
			}
			else if (step == 2) {
				// Verifico se la data e ora selezionati sono ancora disponibili
				this.isCalendarlyLoading = 1;
				axios.post('/api/v6/booking/'+vm.selected_calendarly.slug+'/prebook', {
					at_date: vm.selected_date,
					at_time: vm.selected_time,
				})
				.then(res => {
					vm.isCalendarlyLoading=0;
					if (res.data.status == 'success') {
						vm.errormsg = null;
						vm.selected_date = res.data.date;
						vm.selected_time = res.data.time;
						vm.current_step = step;
						this.scrollTopAnimated(0,500); // x mobile: scrollo alla scelta dell'orario
					}
					else if (res.data.message == 'Date or time slot is not available') {
						vm.errormsg = 'La data o l\'orario selezionato non è più disponibile';
					}
					else {
						vm.errormsg = res.data.message || 'Si è verificato un errore imprevisto. Riprova più tardi.';
						console.warn('errore nel caricamento disponibilità calendario');
					}
				})
				.finally(res => {
					vm.isCalendarlyLoading = false;
					vm.resetForms();
				});
			}
			else {
				this.current_step = step;
				this.scrollTopAnimated(0,500); // x mobile: scrollo alla scelta dell'orario
			}
		},
		
		scrollTopAnimated: function(top=0,after=0) {
			setTimeout(function() {
				window.scrollTo({top: top, behavior: 'smooth'});
			}, after);
		},
		
		resetSelection: function(step=1) {
			this.selected_date=null;
			this.selected_time=null;
			if (step) {
				this.goToStep(step);
			}
		},
		
		chooseDate: function(i) {
			this.resetSelection();
			this.selected_date=i;
			this.scrollTopAnimated(1000,500); // x mobile: scrollo alla scelta dell'orario
		},
		
		isDayDisabled: function(i) {
			return (this.toUserTz(i, this.use_timezone).isBefore() && !this.toUserTz(i, this.use_timezone).isSame(this.toUserTz(undefined, this.use_timezone), 'day')) || !this.hasAvailability(i).length;
		},
		
		daysOfWeek: function(week) {
			var year = this.toUserTz(this.selected_month, this.use_timezone).format('YYYY');
			
			return [1, 2, 3, 4, 5, 6, 7].map(d => moment(year+'-'+week+'-' + d, 'YYYY-W-E').format('YYYY-MM-DD'));
		},
		
		hasAvailability: function(date) {
			if (this.selected_calendarly) {
				var ondate = this.selected_calendarly.available_dates?.filter(function(el) {
					return el.date == date;
				});
				
				if (ondate && ondate.length) {
					return ondate[0].slots || [];
				}
			}
			
			return [];
		},
		
		getDateButtonClasses: function(ymd) {
			var classes = [ 'btn-ghost date-daynum' ];
			
			if (! this.hasAvailability(ymd).length) {
				classes.push('date-unavailable');
			}
			else {
				classes.push('date-available');
			}
			
			if (this.toUserTz(ymd, this.use_timezone).isSame(this.toUserTz(undefined, this.use_timezone), 'day')) { // oggi
				classes.push('date-today');
			}
			else if (this.toUserTz(ymd, this.use_timezone).isBefore()) { // data passata
				classes.push('is-past-date');
			}
			
			if (ymd == this.selected_date) {
				classes.push('selected-date');
			}
			
			return classes.join(' ');
		},
		
		closeSignin: function() {
			this.$emit('close-calendarly');
		},
		
		/*
		getCalendarAvailability: function(cid) {
			const vm = this;
			this.isCalendarlyLoading = 1;
			axios.get('/api/v6/calendar/get-availability?calendarly_id='+cid)
			.then(res => {
				vm.isCalendarlyLoading=0;
				if (res.data.id) {
					if (vm.selected_calendarly && vm.selected_calendarly.id == res.data.id) {
						vm.selected_calendarly = res.data;
					}
				}
				else {
					console.warn('errore nel caricamento disponibilità calendario');
				}
			});
		},
		*/
		
		resetForms: function() {
			const vm = this;
			this.formdata = null;
			this.formvalues = null;
			this.formvalues = {};
			this.formdata = {};
			this.book_notes = null;
			
			this.formdata.firstname = {
				label: 'First name',
				excerpt: null,
				required: 1,
				type: 'firstname',
				value: null,
			};
			this.formdata.lastname = {
				label: 'Last name',
				excerpt: null,
				required: 0,
				type: 'lastname',
				value: null,
			};
			this.formdata.email = {
				label: 'Email',
				excerpt: null,
				required: 1,
				type: 'email',
				value: null,
			};
			this.formdata.phoneprefix = {
				label: 'Phone prefix',
				excerpt: null,
				required: 0,
				type: 'phoneprefix',
				value: null,
			};
			this.formdata.phonenumber = {
				label: 'Phone number',
				excerpt: null,
				required: 1,
				type: 'phonenumber',
				value: null,
			};
			this.formdata.terms = {
				label: 'Terms',
				excerpt: null,
				required: 1,
				type: 'terms',
				value: 0,
			};
			
			Object.keys(this.formdata).forEach(function(key, index) {
				vm.formvalues[key] = vm.formdata[key].value;
			});
			
			this.data.questions?.forEach(function(el) {
				vm.formvalues[el.fieldnameid] = el.options.default_value || null;
			});
		},
	},
	
	mounted() {
		const vm = this;
		this.use_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // setto il timezone dell'utente
		this.selected_month = this.toUserTz(undefined, this.use_timezone).format('YYYY-MM');
		this.selected_calendarly = this.data;
		
		this.resetForms();
	},
}
</script>