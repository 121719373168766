<template>
	<div class="">
		<div v-show="initDone">
			
			<div class="form-row b5-bg-body p-3 mb-3" v-if="is_valid && cf">
				<div class="form-group col-md-12">
					<label for="cf">Il tuo codice fiscale:</label>
					
					<div class="position-relative">
						<input type="text" class="form-control form-control-lg" readonly id="cf-1" v-model="cf" v-bind:class="{
							'is-valid': is_valid===1,
							'is-invalid': is_valid===0,
						}">
						
						<button type="button" class="btn btn-success btn-sm rounded-pill position-absolute top-50 end-0 translate-middle-y me-2" disabled v-if="copied">Copiato!</button>
						<button v-else class="btn btn-evidence rounded-pill btn-sm position-absolute top-50 end-0 translate-middle-y me-2" type="button" @click="copyCodiceFiscale()">Copia</button>
					</div>
					<div v-if="is_valid===0" class="invalid-feedback">
						Codice fiscale non valido
					</div>
				</div>
			</div>
			
			<div class="row g-3 mb-3">
				<div class="col-12 col-lg-6">
					<div class="form-floating">
						<input type="text" class="form-control" autocomplete="given-name" placeholder="Nome" v-model="firstname">
						<label>Nome</label>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="form-floating">
						<input type="text" class="form-control" placeholder="Cognome" v-model="lastname" autocomplete="family-name">
						<label>Cognome</label>
					</div>
				</div>
			</div>
			
			<div class="mb-3">
				<label>Sesso</label>
				<div class="d-flex w-100">
					<div class="form-check">
						  <input class="form-check-input" type="radio" name="flexRadioDefault" value="M" id="flexRadioDefault1" v-model="gender">
						  <label class="form-check-label" for="flexRadioDefault1">
							<i class="fas fa-fw fa-mars"></i> Uomo
						  </label>
						</div>
					<div class="form-check ms-3">
					  <input class="form-check-input" type="radio" name="flexRadioDefault" value="F" id="flexRadioDefault2" v-model="gender">
					  <label class="form-check-label" for="flexRadioDefault2">
						<i class="fas fa-fw fa-venus"></i> Donna
					  </label>
					</div>
				</div>
			</div>
				
			<div class="form-floating mb-3">
				<input class="form-control" type="date" v-model="birthday" required />
				<label>Data di nascita</label>
			</div>
			
			<div class="row g-3 mb-3">
				<div class="col-12 col-lg-6">
					<div class="form-floating">
						<select class="custom-select form-select" name="birthplace_province" :id="thekey+'birthplace_province'" v-model="birthplace_province" required></select>
						<label>Luogo di nascita</label>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="form-floating">
						<select class="custom-select form-select" name="birthplace" :id="thekey+'birthplace'" v-model="birthplace" required></select>
						<label>Provincia</label>
					</div>
				</div>
			</div>
			
			<div class="text-end">
				<button type="button" class="btn btn-primary rounded-pill px-4" @click="getCodiceFiscale()">Calcola</button>
			</div>
			<div v-if="is_valid===0" class="invalid-feedback text-center">
				Codice fiscale non valido
			</div>
		</div>
		<div v-show="!initDone">
			<div class="form-floating">
				<input type="text" class="form-control" v-bind:class="{
					'is-valid': is_valid===1,
					'is-invalid': is_valid===0,
				}" placeholder="Codice fiscale" pattern="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$" v-model="cf">
				<label>Codice fiscale</label>
			</div>
			<div v-if="is_valid===0" class="invalid-feedback">
				Codice fiscale non valido
			</div>
		</div>
		
	</div>
</template>
<script>
export default {
	props: ['data'],
	
	metaInfo() {
		 return {
			 title: 'Checkout',
			 link: [
				{ rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.3/css/flag-icons.min.css' },
			 ],
			 script: [
				 {
				   src: '/assets/js/libs/codicefiscale/codice.fiscale.var.js',
				   async: true,
				   defer: true,
				   callback: () => this.init() // will declare it in methods
				 },
			 ]
		   }; 
	  },
	  
	data: function() {
		return {
			thekey:'codicefiscalecalculator'+this.makeid(12),
			initDone: false,
			firstname: null,
			lastname: null,
			gender: null,
			birthday: null,
			birthplace_province: null,
			birthplace: null,
			cf: null,
			is_valid: null,
			copied: false,
		};
	},
	
	methods: {
		
		init: function() {
			CodiceFiscale.utils.birthplaceFields('#'+this.thekey+"birthplace_province", '#'+this.thekey+"birthplace");
			this.birthplace_province = 'EE';
			this.birthplace = 'Z404';
			
			var prov = document.getElementById(this.thekey+'birthplace_province');
			if (prov) {
				prov.dispatchEvent(new Event('change'));
			}
			
			this.initDone = true;
		},
		
		validateCodiceFiscale: function(e=null) {
			e?.preventDefault();
			this.is_valid = null;
			this.is_valid = CodiceFiscale.check(this.cf) === true ? 1 : 0;
		},
		
		reverseCodiceFiscale: function(e=null) {
			e?.preventDefault();
			this.validateCodiceFiscale();
			 
			if (CodiceFiscale.check(this.cf)) {
				let cfData = CodiceFiscale.computeInverse(this.cf);
				console.log(cfData);
				this.firstname = cfData.name;
				this.lastname = cfData.surname;
				this.gender = cfData.gender;
				this.birthplace = cfData.birthplace;
				this.birthplace_province = cfData.birthplaceProvincia;
				this.birthday = cfData.birthday;
			}
		},
		
		getCodiceFiscale: function(e=null) {
			e?.preventDefault();
			const vm = this;
			try {
				let cf = new CodiceFiscale({
					name : vm.firstname,
					surname : vm.lastname,
					gender : vm.gender,
					birthday : vm.birthday,
					birthplace : vm.birthplace,
				});
				this.cf = cf.toString();
				
				if (CodiceFiscale.check(this.cf)) { // se è valido lo invio al parent
					this.$emit('receive', this.cf);
				}
			}
			catch(err) {
				//
				console.warn(err);
			}
			this.validateCodiceFiscale();
		},
		
		copyCodiceFiscale: function() {
			this.copyToClipboard(this.cf);
			this.copied = true;
			const vm = this;
			setTimeout(()=>vm.copied=false, 3000);
		},
		
		copyToClipboard: function(str) {
			const el = document.createElement('textarea');
			el.value = str;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
		},
	},
	
	mounted() {
		
		if (typeof CodiceFiscale !== 'undefined') {
			this.init();
		}
		
		if (this.data) {
			this.firstname = this.data.firstname;
			this.lastname = this.data.lastname;
		}
	},
}
</script>