<template>
	<section :class="getContainerClasses()">
		<div :class="getSectionClasses()">
			<div :class="getOverlay()+' position-relative'">
				<div :class="getOverlayClasses()">
					<dynamic-element-render 
						v-for="(item, index) in instance.childrens" :key="'col-'+makeid(12)+'-'+item.id"
						group="page" :instance="item" />
				</div>
			</div>
		</div>
		<div v-html="customStyle"></div>
	</section>
</template>
<script>
export default {
	props: {
		instance: null,
	},
	
	data: function() {
		return {
			instanceid: 'pes-'+this.makeid(12),
		};
	},
	
	computed: {
		customStyle() {
return `<style>
:root {
	--`+this.instanceid+`-id-`+this.instance.id+`-bg-color:`+this.instance.options.backgroundcolor+`;
	--`+this.instanceid+`-id-`+this.instance.id+`-bg-image-mobile:url(`+this.getFileVersionUrl(this.instance.processed_options?.background_image_mobile_id?.media)+`);
	--`+this.instanceid+`-id-`+this.instance.id+`-bg-image-desktop:url(`+this.getFileVersionUrl(this.instance.processed_options?.background_image_desktop_id?.media)+`);
}
.page-element-section.`+this.instanceid+`-id-`+this.instance.id+` .section {
	background-color: var(--`+this.instanceid+`-id-`+this.instance.id+`-bg-color);
	background-image: var(--`+this.instanceid+`-id-`+this.instance.id+`-bg-image-mobile);
}
@media (min-width: 992px) {
	.page-element-section.`+this.instanceid+`-id-`+this.instance.id+` .section {
		background-image: var(--`+this.instanceid+`-id-`+this.instance.id+`-bg-image-desktop);
	}
}
</style>`;
		},
	  },
	
	methods: {
		
		hasBackgroundPicture: function() {
			return false;
		},
		
		getContainerClasses: function() {
			var classes = [];
			
			classes.push('page-element-section position-relative');
			classes.push(this.instanceid+'-id-'+this.instance.id);
			
			if (! this.instance.options.hasOwnProperty('container_size')) {
				classes.push('b5-container-lg');
			}
			else {
				if (this.instance.options.container_size == 'small') {
					classes.push('b5-container-sm');	
				}
				else if (this.instance.options.container_size == 'default') {
					classes.push('b5-container-lg');	
				}
				else if (this.instance.options.container_size == 'large') {
					classes.push('b5-container-xl');	
				}
				else if (this.instance.options.container_size == 'xl') {
					classes.push('b5-container-xxl');	
				}
				else if (this.instance.options.container_size == 'full-width') {
					classes.push('w-100');	
				}
			}
			
			return classes.join(' ');
		},
		
		getSectionClasses: function() {
			var classes = [
				'section d-block p-0 bg-cover' // b5-bg-body2?
			];
			
			classes.push('bg-gradient-'+this.getBackgroundGradient());
			
			if (this.instance.options.border_radius == 'default') {
				classes.push('rounded');
			}
			else if (this.instance.options.border_radius == 'small') {
				classes.push('rounded-sm');
			}
			else if (this.instance.options.border_radius == 'large') {
				classes.push('rounded-app');
			}
			
			if (this.hasBackgroundPicture()) {
				classes.push('lazy bg-cover');
			}
			
			return classes.join(' ');
		},
		
		getBackgroundGradient: function() {
			  return this.instance.options.background_gradient;
		  },
		
		getOverlay: function() {
			const vm = this;
			var str = '';
			
			
			if (this.instance.options.hasOwnProperty('full_height') && this.instance.options.full_height) {
				str = 'min-vh-90 d-flex align-items-center justify-content-center w-100';
			}
			
			if (this.instance.options.background_blur) {
				str += ' b5-bg-'+this.instance.options.background_blur;
			}
			
			if (this.instance.options.border_radius == 'default') {
				str += ' rounded';
			}
			else if (this.instance.options.border_radius == 'small') {
				str += ' rounded-sm';
			}
			else if (this.instance.options.border_radius == 'large') {
				str += ' rounded-app';
			}
			
			if (! vm.instance.options.hasOwnProperty('background_overlay')) {
				return str;
			}
			
			if (vm.instance.options.background_overlay == 'light-sm') {
				return str+' overlay overlay-light overlay-sm';
			}
			else if (vm.instance.options.background_overlay == 'light-md') {
				return str+' overlay overlay-light overlay-md';
			}
			else if (vm.instance.options.background_overlay == 'light-xl') {
				return str+' overlay overlay-light overlay-xl';
			}
			else if (vm.instance.options.background_overlay == 'dark-sm') {
				return str+' overlay overlay-dark overlay-sm';
			}
			else if (vm.instance.options.background_overlay == 'dark-md') {
				return str+' overlay overlay-dark overlay-md';
			}
			else if (vm.instance.options.background_overlay == 'dark-xl') {
				return str+' overlay overlay-dark overlay-xl';
			}
			
			return;
		},
		
		getOverlayClasses: function() {
			var classes = ['section-contents'];
			
			return classes.join(' ');
		},
	},
}
</script>