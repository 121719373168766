<template>
	<div :class="getClasses()" v-html="nl2br(urlify(encodeHTML( instance.options.text )))"></div>
</template>

<script>
export default {
	props: {
		instance: null
	},
	
	methods: {
		getClasses: function() {
			var classes = [];
			
			if (this.instance.options.hasOwnProperty('font_family')) {
				classes.push('font-'+this.instance.options.font_family);
			}
			
			if (this.instance.options.text_color == 'light') {
				classes.push('text-white');
			}
			else if (this.instance.options.text_color == 'dark') {
				classes.push('text-dark');
			}
			
			// text alignment
			if (this.instance.options.text_alignment == 'left') {
				classes.push('text-start');
			}
			else if (this.instance.options.text_alignment == 'center') {
				classes.push('text-center');
			}
			else if (this.instance.options.text_alignment == 'right') {
				classes.push('text-end');
			}
			
			if (this.instance.options.hasOwnProperty('size')) {
				if (this.instance.options.size == 'lead') {
					classes.push('lead lh-1 fw-medium');
				}
				else if (this.instance.options.size == 'h1') {
					classes.push('fs-1');
				}
				else if (this.instance.options.size == 'h2') {
					classes.push('fs-2');
				}
				else if (this.instance.options.size == 'h3') {
					classes.push('fs-3');
				}
				else if (this.instance.options.size == 'h4') {
					classes.push('fs-4');
				}
				else if (this.instance.options.size == 'h5') {
					classes.push('fs-5');
				}
				else if (this.instance.options.size == 'h6') {
					classes.push('fs-6');
				}
				else {
					classes.push('fw-bold '+this.instance.options.size);
				}
			}
			
			return classes.join(' ');
		},
	}
}
</script>