<template>
	<router-link :to="{ name:'wellness.index' }" class="text-decoration-none text-reset color-inherit">
		<widget-productivity-healthkitsteps :settings="{
			design: 'card',
		}" :class="getSectionClasses()"/>
	</router-link>
</template>

<script>
export default {
	props: {
		instance: null,
		settings: null,
	},
	
	methods: {
		getSectionClasses: function() {
			var classes = ['b5-container-xl'];
			
			if (this.settings?.classes) {
				classes.push(this.settings?.classes);
			}
			
			return classes.join(' ');
		},
	},
}
</script>