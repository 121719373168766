<template>
	<div class="elements--post-category--quicklinks-collection b5-container-xl" v-if="hasProcessedData() && instance.processed_data.length">
		<div class="mb-2 heading">
			<div class="d-flex justify-content-between align-items-top">
				<h4 v-if="instance.options.title" class="mb-0 fw-bold text-opposite">
					{{ instance.options.title }}
				</h4>
			
				<b5-link v-if="hasCtaAction() && hasCtaText()" :href="instance.options.cta_web_url" class="btn btn-ghost text-primary text-nowrap text-decoration-none p-0">
					{{ instance.options.cta_text }}
					
					<i class="b5-fa-icon fa-chevron-right"></i>
				</b5-link>
			</div>
			
			<p v-if="instance.options.excerpt" class="mb-0 text-secondary">
				{{ instance.options.excerpt }}
			</p>
		</div>
		
		<div v-if="instance.options.layout == 'full_list'" class="row g-2 align-items-stretch">
			<div class="col-6 align-self-stretch" v-for="(item, index) in instance.processed_data" :key="'quicklink-collection-'+index">
				<div class="card border-0 rounded-app h-100 w-100">
					<b5-link :href="item.url" class="target-blank card-body text-decoration-none text-center text-reset list-group-item list-group-item-action d-flex w-100 align-items-center justify-content-center flex-column">
						<div v-if="item.cc_thumbnail_720p_url" class="text-center mb-2">
							<img :src="item.cc_thumbnail_720p_url" alt="" class="img-fluid rounded-app" style="max-height:64px"/>
						</div>
						<div v-else-if="item.icon_type == 'emoji'">
						  <i class="fs-3 emoji">{{ item.icon }}</i>
						</div>
						<div v-else-if="item.icon_type == 'font-awesome' || item.icon_type == 'bootstrap-icons'">
						  <i class="fs-3 b5-fa-icon fa-fw" v-bind:class="item.icon"></i>
						</div>
						
						<span class="text-sm fw-bold lh-sm mb-1 text-opposite">{{ item.name }}</span>
					</b5-link>
				</div>
			</div>
		</div>
		<div v-else-if="instance.options.layout == 'scroll'" class="row g-2 flex-row flex-nowrap justify-content-start overflow-auto">
			<div class="b5-col-4 b5-col-md-5 b5-col-lg-7 b5-col-xl-7 mb-2 align-self-stretch" v-for="(item, index) in instance.processed_data" :key="index">
				<div class="card border-0 rounded-app h-100 w-100">
					<b5-link :href="item.url" class="target-blank card-body text-decoration-none text-center text-reset list-group-item list-group-item-action d-flex w-100 align-items-center justify-content-center flex-column">
						<div v-if="item.cc_thumbnail_720p_url" class="text-center mb-2">
							<img :src="item.cc_thumbnail_720p_url" alt="" class="img-fluid rounded-app" style="max-height:64px"/>
						</div>
						<div v-else-if="item.icon_type == 'emoji'">
						  <i class="fs-3 emoji">{{ item.icon }}</i>
						</div>
						<div v-else-if="item.icon_type == 'font-awesome' || item.icon_type == 'bootstrap-icons'">
						  <i class="fs-3 b5-fa-icon fa-fw" v-bind:class="item.icon"></i>
						</div>
						
						<span class="text-sm fw-bold lh-sm mb-1 text-opposite">{{ item.name }}</span>
					</b5-link>
				</div>
			</div>
		</div>
		<div v-else-if="instance.options.layout == 'full_width_menu'" class="list-group">
			<b5-link v-for="(item, index) in instance.processed_data" :key="index" :href="item.url" class="text-decoration-none text-reset list-group-item list-group-item-action d-flex w-100 align-items-center justify-content-between">
				<div v-if="item.cc_thumbnail_720p_url" class="text-center mb-2">
					<img :src="item.cc_thumbnail_720p_url" alt="" class="img-fluid rounded-app" style="max-height:64px"/>
				</div>
				<div v-else-if="item.icon_type == 'emoji'" class="me-2 lh-sm">
				  <i class="fs-3 emoji">{{ item.icon }}</i>
				</div>
				<div v-else-if="item.icon_type == 'font-awesome' || item.icon_type == 'bootstrap-icons'" class="me-2 lh-sm">
				  <i class="fs-3 b5-fa-icon fa-fw" v-bind:class="item.icon"></i>
				</div>
				
				<span class="fw-bold lh-sm w-100 text-opposite">{{ item.name }}</span>
				
				<i class="b5-fa-icon fa-chevron-right text-secondary ms-auto"></i>
			</b5-link>
		</div>
		
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {
					
				}
			};
		},
		
		methods: {
			
			hasProcessedData: function() {
				const vm = this;
				return (
					vm.instance.hasOwnProperty('processed_data')
					&& Array.isArray(this.instance.processed_data)
				);
			},
			
			
			hasCtaText: function() {
				return (
					this.instance.options.hasOwnProperty('cta_text')
					&& this.instance.options.cta_text !== null
					&& this.instance.options.cta_text.length
				);
			},
			
			hasCtaAction() {
				return (
					this.instance.options.hasOwnProperty('cta_web_url')
					&& this.instance.options.cta_web_url !== null
				);
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
		}
	}
</script>