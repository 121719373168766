var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.instance.childrens && _vm.instance.childrens.length)?_c('div',{class:{
	'b5-bg-gradient-body-transparent-bottom': (_vm.getStyle() == 'infinity'),
	'b5-bg-body2': (_vm.getStyle() != 'rounded'),
	'elements--post-category--post-collection-carousel': true,
}},[_c('div',{class:{
		'b5-container-xl': (_vm.getStyle() != 'infinity'),
		'px-0': (_vm.getStyle() != 'rounded')
	}},[_c('div',{staticClass:"carousel slide max-vh-50 carousel-scheme-auto",attrs:{"id":_vm.carousel_id,"data-bs-ride":"carousel"}},[_c('div',{staticClass:"carousel-indicators"},_vm._l((_vm.instance.childrens),function(item,index){return _c('button',{key:item.id,class:index == 0 ? 'active' : '',attrs:{"aria-current":index,"type":"button","data-bs-target":'#' + _vm.carousel_id,"data-bs-slide-to":index,"aria-label":'Slide ' + index}})}),0),_vm._v(" "),_c('div',{staticClass:"carousel-inner"},_vm._l((_vm.instance.childrens),function(item,index){return _c('elements-page-carousel-slide',{key:item.id,class:index == 0 ? 'active' : '',attrs:{"instance":item,"carousel":_vm.data}})}),1),_vm._v(" "),_c('button',{staticClass:"carousel-control-prev",attrs:{"type":"button","data-bs-target":'#' + _vm.carousel_id,"data-bs-slide":"prev"}},[_c('span',{staticClass:"carousel-control-prev-icon",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"visually-hidden"},[_vm._v("Previous")])]),_vm._v(" "),_c('button',{staticClass:"carousel-control-next",attrs:{"type":"button","data-bs-target":'#' + _vm.carousel_id,"data-bs-slide":"next"}},[_c('span',{staticClass:"carousel-control-next-icon",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"visually-hidden"},[_vm._v("Next")])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }