<template>
	<div class="elements--post-category--category-header">
    	<section v-if="hasBgGradient() || hasThumbnail()" :class="'text-light w-100 text-center d-block b5-bg-body2 bg-gradient-' + getBgGradient() + ' bg-cover lazy'" :data-src="hasThumbnail() ? getThumbnail() : ''">
    		<div class="py-5" v-bind:class="hasThumbnail() ? 'overlay overlay-dark' : ''">
    			<div class="mt-0 mb-3 my-lg-4">
					<div v-if="instance.options.icon_type == 'emoji'">
						<i class="display-4 emoji">{{ instance.options.icon }}</i>
					</div>
					<div v-else-if="instance.options.icon_type == 'font-awesome' || instance.options.icon_type == 'bootstrap-icons'">
						<i class="display-4 b5-fa-icon fa-fw" v-bind:class="instance.options.icon"></i>
					</div>
	    		
		    		<h1 class="mb-0 fw-bold display-4" v-if="instance.options.title">
						{{ instance.options.title }}
					</h1>
					
					<p class="mb-0" v-if="instance.options.excerpt" v-html="nl2br(urlify(encodeHTML(instance.options.excerpt)))"></p>
				</div>
			</div>
    	</section>
		<section class="b5-bg-body2 py-lg-4" v-else>
			<div class="b5-container-lg py-5 text-center">
				<h1 class="mb-0 fw-bold display-4" v-if="instance.options.title">
					{{ instance.options.title }}
				</h1>
				
				<p class="mb-0" v-if="instance.options.excerpt">
					{{ instance.options.excerpt }}
				</p>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			hasThumbnail: function() {
				
				if (this.instance.hasOwnProperty('processed_options') && this.instance.processed_options.hasOwnProperty('picture') && this.instance.processed_options.picture.hasOwnProperty('media')) {
					return this.getFileVersionUrl(this.instance.processed_options.picture.media);
				}
			  return false;
		  },
		  
		  getThumbnail: function() {
			  if (this.hasThumbnail()) {
				  return this.getFileVersionUrl(this.instance.processed_options.picture.media, {
					  types: [ 'image_1920', 'image_1080', 'image_720', 'original' ],
				  });
			  }
			  
			  return '';
		  },
		  
		  hasBgGradient: function() {
			  if (
				  this.instance.options.hasOwnProperty('bg_gradient')
				  && this.instance.options.bg_gradient !== null
				  && this.instance.options.bg_gradient !== 'none'
				  && this.instance.options.bg_gradient !== ''
			  ) {
				  return true;
			  }
			  
			  return false;
		  },
		  
		  getBgGradient: function() {
		  		if (this.hasBgGradient()) {
			  		return this.instance.options.bg_gradient;
		  		}
		  		return '';
		  },
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				loadLazy();
			});
		}
	}
</script>