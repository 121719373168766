<template>
	<div class="elements--post---zoom-call-button b5-container-lg">
		<div class="d-flex justify-content-between align-items-start mb-3" v-if="data.options.zoom_id">
			<div class="text-secondary">
				Zoom ID
			</div>
			<div class="text-end user-select-all">
				{{ formattedZoomId() }}
			</div>
		</div>
		
		<div class="d-flex justify-content-between align-items-start mb-3" v-if="data.options.zoom_password">
			<div class="text-secondary">
				Password di Zoom
			</div>
			<div class="text-end">
				<code class="user-select-all">{{ data.options.zoom_password }}</code>
			</div>
		</div>
		
		<div class="d-flex justify-content-between align-items-start mb-3" v-if="data.options.planned_date && data.options.planned_time">
			<div class="">
				{{ moment(data.options.planned_date+' '+data.options.planned_time).format('ll') }}
			</div>
			<div class="text-end">
				{{ moment(data.options.planned_date+' '+data.options.planned_time).format('LT') }}
			</div>
		</div>
		
		<a v-if="getZoomUrl()" :href="getZoomUrl()" class="btn text-white bg-blue btn-lg rounded-pill d-block w-100 target-blank bg-gradient shadow-sm" target="_blank" rel="nofollow">
			<i class="fas fa-video fs-4 me-1"></i> {{ getCta() }}
		</a>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
			moment: function(t) {
				return moment(t);
			},
			
			formattedZoomId: function() {
				return this.data.options.zoom_id.match(/.{1,3}/g).join(' ');
			},
			
			getZoomUrl: function() {
				if (this.data.options.zoom_url) {
					return this.data.options.zoom_url;
				}
				else {
					var url = 'https://us02web.zoom.us/j/';
					
					if (this.data.options.zoom_id) {
						return url+this.data.options.zoom_id.replace(/\s/g, '');
					}
				}
			},
			
			getCta: function() {
				if (this.data.options.cta_text) {
					return this.data.options.cta_text;
				}
				
				return 'Partecipa su Zoom';
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>