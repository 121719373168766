<template>
	<div class="elements--post-minibanner b5-container-xl px-0 position-relative">
		
		<b5-link :href="getDestination()" class="text-decoration-none text-reset b5-transitionable stretched-link d-block rounded-app banner-container border">
			<div :class="getClasses()" :style="getStyle()">
				<div :class="getMediaContainerClasses()" :style="getMediaContainerStyle()">
					<!-- <img v-if="hasMainPicture()" :src="getFileVersionUrl(instance.processed_options.main_picture.media)" class="img-fluid banner-picture h-100" alt="" /> -->
					<!-- <div class="img-bg lazy bg-cover position-absolute top-0 start-0 end-0 bottom-0 w-100 h-100" :data-src="getFileVersionUrl(instance.processed_options.main_picture.media)"></div> -->
				</div>
				
				<div :class="getMainColClasses()" :style="getMainColStyle()">
					<div class="h-100">
						<div :class="getBodyClasses()">
							<p v-if="instance.options.slogan" :class="'mb-0 fw-medium'" v-html="nl2br(urlify(encodeHTML(instance.options.slogan)))"></p>
							
							<h5 v-if="instance.options.title" :class="'fw-bold mb-0'" v-html="nl2br(urlify(encodeHTML(instance.options.title)))"></h5>
							
							<p v-if="instance.options.excerpt" :class="'mb-0'" v-html="nl2br(urlify(encodeHTML(instance.options.excerpt)))"></p>
							
							<div class="mt-auto pt-3" v-if="instance.options.subtext || instance.options.cta_text || instance.options.cta_secondary_text">
								<p v-if="instance.options.subtext" :class="'mb-0 fw-bold text-sm'" v-html="nl2br(urlify(encodeHTML(instance.options.subtext)))"></p>
								
								<button type="button" v-if="instance.options.cta_text" :class="getButtonClasses('primary')">
									{{ instance.options.cta_text }}
								</button>
							</div>
						</div>
					</div>
				</div>
				
				<i v-if="getDestination()" class="b5-fa-icon fa-fw fa-chevron-right text-secondary order-3 mx-2 align-self-center"></i>
			</div>
		</b5-link>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
			getStyle: function() {
				var css = '';
				
				return css;
			},
			
			getMediaContainerStyle: function() {
				var css = '';
				
				if (this.hasMainPicture()) {
					css+='background-image:url('+this.getFileVersionUrl(this.instance.processed_options.main_picture.media)+');';
				}
				
				return css;
			},
			
			getMediaContainerClasses: function() {
				var classes = ['media-container col-4 bg-cover'];
				let aspect_ratio = this.instance.options.hasOwnProperty('aspect_ratio') ? this.instance.options.aspect_ratio : 'auto';
				
				if (this.hasMainPicture()) {
					classes.push('with-picture');
				}
				
				if (aspect_ratio != 'auto' && (this.hasMainPicture())) {
					classes.push('aspect-ratio-forced bg-cover ratio ratio-'+aspect_ratio);
				}
				classes.push('order-1');
				
				return classes.join(' ');
			},
			
			hasProcessedData: function() {
				const vm = this;
				return (
					vm.instance.hasOwnProperty('processed_data')
					&& Array.isArray(this.instance.processed_data)
				);
			},
			
			hasDestination: function() {
				return (this.instance.options.hasOwnProperty('cta_web_url') && this.instance.options.cta_web_url !== null);
			},
			
			getMainColStyle: function() {
				var css = '';
				
				return css;
			},
			
			getMainColClasses: function() {
				var classes = ['main-container bg-cover col'];
				let aspect_ratio = this.instance.options.hasOwnProperty('aspect_ratio') ? this.instance.options.aspect_ratio : 'auto';
				
				if (aspect_ratio != 'auto' && !(this.hasMainPicture())) {
					classes.push('aspect-ratio-forced ratio ratio-'+aspect_ratio);
				}
				
				classes.push('order-2');
				
				return classes.join(' ');
			},
			
			getDestination: function() {
				if (this.hasDestination()) {
					return this.instance.options.cta_web_url;
				}
				
				return;
			},
			
			hasMainPicture: function() {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty('main_picture') 
					&& this.instance.processed_options.main_picture !== null 
					&& this.instance.processed_options.main_picture.hasOwnProperty('media')
					&& this.instance.processed_options.main_picture.media !== null
					&& this.getFileVersionUrl(this.instance.processed_options.main_picture.media)
				);
			},
			
			getBodyClasses: function() {
				var classes = ['card-body p-4'];
				
				classes.push('d-flex w-100 h-100 flex-column justify-content-center');
				
				return classes.join(' ');
			},
			
			getButtonClasses: function(btn='primary') {
				const vm = this;
				var classes = [];
				
				if (btn == 'primary') {
					classes.push('btn rounded-app d-block w-100 mt-2 b5-bg-opaque');
					
					classes.push('btn-primary');
					
				}
				
				return classes.join(' ');
			},
			
			getClasses: function() {
				const vm = this;
				var classes = ['banner-content text-decoration-none text-reset row align-items-stretch g-0 b5-bg-body2 rounded-app'];
				
				if (this.getDestination() && this.isExternalUrl(this.getDestination())) {
					classes.push('target-blank');
				}
				
				/* for better styling... */
				if (this.hasMainPicture()) {
					classes.push('with-main-picture');
				}
				else {
					classes.push('without-main-picture');
				}
				
				return classes.join(' ');
			},
			
			getDestinationTarget: function() {
				if (this.getDestination() && this.isExternalUrl(this.getDestination())) {
					return "_blank";
				}
				
				return "_self";
			},
			
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
			
			this.$nextTick(function() {
				vm.loadLazy();
			});
		}
	}
</script>