<template>
	<div class="vw-100 overflow-hidden mw-100">
		<div class="py-5">
					<div v-if="layout == 'list' || !category.cc_thumbnail_720p_url" class="b5-container-lg">
						<h3>{{ category.name }}</h3>
					</div>
					<div v-else>
						<div class="position-relative mb-4">
							<div class="position-absolute bottom-0 category-poster reveal" v-bind:class="alternative_row ? 'end-0' : 'start-0'" style="width:55%;" v-bind:style="category_ix > 0 ? 'height:340px' : 'height:250px'">
								<div class="w-100 h-100">
									<div class="w-100 h-100 bg-cover rounded-app b5-bg-body" v-bind:class="alternative_row ? 'rounded-end-0' : 'rounded-start-0'" :style="'background-image:url('+category.cc_thumbnail_720p_url+')'"></div>
								</div>
							</div>
							
							<div class="b5-container-xl">
								<div class="row align-items-center">
									<div class="col-5 col-md-6 col-lg-4" v-bind:class="alternative_row ? 'order-1' : 'order-2'">
										<div class="category-title reveal" style="--category-title-reveal-delay: 0s">
											<h4 class="fs-3 d-md-none">{{ category.name }}</h4>
											<h4 class="display-5 d-none d-md-block">{{ category.name }}</h4>
										</div>
									</div>
									
									<div class="col-7 col-md-6 col-lg-8" v-bind:class="alternative_row ? 'order-2' : 'order-1'">
										<div style="height:250px"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="b5-container-lg">
					<div class="list-group" v-if="layout == 'list'">
						<router-link v-for="item in getProductsFromCategory(category)" :key="'catalog-item-'+item.slug" class="list-group-item d-flex w-100 align-items-start" :to="{
							name: 'shop.team.product.show',
							params: {
								teamSlug: $route.params.teamSlug,
								productSlug: item.slug,
								countryCode: getCurrentCountryCode(),
							}
						}" v-bind:class="item.is_available ? 'product-available' : 'product-unavailable opacity-50'">
							<div class="ratio ratio-1x1 me-3" style="width:64px">
								<div class="b5-bg-body rounded bg-cover" :style="'background-image:url('+item.cc_thumbnail_720p_url+')'"></div>
							</div>
							<div class="w-100">
								<h6 class="fw-medium mb-0">{{ item.name }}</h6>
								<div v-if="item.parent && item.parent.name">{{ item.parent.name }}</div>
								<p v-if="item.excerpt" class="text-secondary mb-0 text-sm">
									{{ item.excerpt }}
								</p>
								
								<div v-if="!item.is_available" class="mt-1 text-danger text-sm fw-medium text-uppercase">
									Non disponibile
								</div>
								
								<!-- <button type="button" class="btn btn-ghost text-success mt-2 rounded-pill disabled border" disabled v-if="item.added_to_cart">
									<i class="fas fa-fw fa-circle-check"></i>
								</button>
								<button v-else type="button" class="btn btn-ghost text-primary border px-4 btn-sm mt-2 rounded-pill" @click="addToCart(item)">
									Aggiungi al carrello
								</button> -->
							</div>
							
							<div class="ms-2 d-flex align-self-center">
								<span class="text-secondary text-nowrap text-sm"><span v-if="item.variants_count">da</span> {{ formatCurrency(item.price, item.currency, 'auto', true) }}</span>
								<i class="b5-fa-icon fa-fw fa-chevron-right text-secondary ms-2 align-self-center"></i>
							</div>
						</router-link>
					</div>
					<div v-else-if="layout == 'grid'" class="row g-2 g-lg-3 align-items-stretch row-catalog-product" style="--row-catalog-product-reveal-delay: 0s">
						<div class="col-6 col-lg-4 col-xl-3 align-self-stretch" v-for="item in getProductsFromCategory(category)" :key="'catalog-item-'+item.slug">
							<div class="card h-100 rounded-app b5-hover-scale b5-transitionable reveal" v-bind:class="item.is_available ? 'product-available' : 'product-unavailable opacity-50'">
								<router-link class="ratio ratio-1x1 b5-bg-body mb-3 rounded-top-app" :to="{
									name: 'shop.team.product.show',
									params: {
										teamSlug: $route.params.teamSlug,
										productSlug: item.slug,
										countryCode: getCurrentCountryCode(),
									}
								}">
									<div class="ratio ratio-1x1 w-100">
										<div class="b5-bg-body bg-cover rounded-top-app" :style="'background-image:url('+item.cc_thumbnail_720p_url+')'"></div>
									</div>
								</router-link>
								<div class="w-100 card-body h-100 d-flex justify-content-between flex-column">
									<router-link :to="{
										name: 'shop.team.product.show',
										params: {
											teamSlug: $route.params.teamSlug,
											productSlug: item.slug,
											countryCode: getCurrentCountryCode(),
										}
									}" class="text-reset text-decoration-none">
										
										<span v-if="item.variants_count" class="b5-bg-body rounded-pill py-1 px-2 text-xs fw-bold mb-1 d-inline-block">
											{{ item.variants_count }} gusti
										</span>
										<span v-else-if="item.type == 'combo' && item.options?.combo_items" class="b5-bg-body rounded-pill py-1 px-2 text-xs fw-bold mb-1 d-inline-block">
											KIT {{ item.options.combo_items.length }}x
										</span>
										
										<h6 class="fw-medium mb-0">{{ item.name }}</h6>
										<div v-if="item.parent && item.parent.name">{{ item.parent.name }}</div>
										<p v-if="item.excerpt" class="text-secondary mb-0 text-sm">
											{{ item.excerpt }}
										</p>
										<div class="mt-1 text-sm" v-if="item.serving_amount && item.price && item.serving_label">
											<span v-if="item.variants_count">da</span>
											<span class="text-secondary">{{ formatCurrency(item.price, item.currency, 'auto', true) }}</span><span class="text-secondary ms-1 text-xs">&bullet; {{ formatCurrency(item.price/item.price_per_serving, item.currency, 'auto', true) }}/{{ item.serving_label }}</span>
										</div>
										<div v-else class="text-secondary text-sm">
											<span v-if="item.variants_count">da</span>
											{{ formatCurrency(item.price, item.currency, 'auto', true) }}
										</div>
									</router-link>
									
									<div class="mt-auto pt-1" v-if="item.is_available">
										<button type="button" class="btn btn-block w-100 btn-ghost text-success disabled text-sm rounded-pill border" disabled v-if="item.added_to_cart">
											<i class="fas fa-fw fa-circle-check fs-6"></i>
										</button>
										<button v-else type="button" class="btn btn-block w-100 btn-ghost text-primary text-sm rounded-pill border" @click="addToCart(item)">
											<i class="fas fa-fw fa-plus"></i> Aggiungi al carrello
										</button>
									</div>
									<div v-else class="mt-auto pt-1 text-danger text-center text-sm fw-medium text-uppercase">
										Non disponibile
									</div>
								</div>
							</div>
						</div>
					</div>
					
		</div>
		
	</div>
	</div>
</template>
<style>
.category-title.reveal {
	opacity: 0.25;
	transform: translateY(100px);
	transition: 1s all ease;
	transition-delay: var(--category-title-reveal-delay);
}
.category-title.reveal.active {
	opacity: 1;
	transform: translateY(0);
}

.category-poster.reveal.end-0 {
	transform: translateX(100px);
}
.category-poster.reveal.start-0 {
	transform: translateX(-100px);
}
.category-poster.reveal {
	opacity: 0.25;
	filter: grayscale(100%);
	transition: 1s all ease;
	transition-delay: var(--category-poster-reveal-delay);
}
.category-poster.reveal.active {
	filter: grayscale(0);
	transform: translate(0, 0);
	opacity: 1;
}

.row-catalog-product .reveal {
	position: relative;
	transform: translateY(120px); /* è importante che questo e le due cart abbiamo una Y diversa per far si che siano sincronizzate nell'animazione on scroll */
	opacity: 0.15;
	filter: grayscale(100%);
	transition: 1s all ease;
	transition-delay: var(--row-catalog-product-reveal-delay);
}

@media (min-width: 1200px) {
	.row-catalog-product :nth-child(1) .reveal,
	.row-catalog-product :nth-child(5) .reveal,
	.row-catalog-product :nth-child(10n-1) .reveal {
		transform: translate(-150px, 150px) rotate(15deg);
	}
	.row-catalog-product :nth-child(4n) .reveal {
		transform: translate(150px, 150px) rotate(-15deg);
	}
}
.row-catalog-product .reveal.active {
	transform: translate(0, 0) rotate(0deg)!important;
	opacity: 1;
	filter: grayscale(0);
}
</style>
<script>
export default {
	props: [ 'category', 'alternative_row' ],
  data: function() {
	return {
		category_ix: 0,
	  isSubmitting: false,
	  layout: 'grid',
	  
	  variantFlavourRefs: [],
	}
  },
  
  methods: {
	  
	  getCurrentCountryCode: function() {
		  if (this.category.countrycode) { // prendo la countrycode dalla categoria stessa
			  return this.category.countrycode;
		  }
		  return this.getCurrentCountryCode();
	  },
	  
	  setDisplayView: function(type) {
		  this.layout = type;
		  
		  if (type == 'grid') {
			  setTimeout(this.revealScrollAnimation, 750); // giusto il tempo di renderizzare
		  }
	  },
	  
	  getAllProducts: function() {
		  var items = [];
		  
		  this.category.products.forEach(function(prod) {
			  items.push(prod);
		  });
		  
		  return items;
	  },
	  
	  getVariantsOfProduct: function(product_id) {
		  return this.getAllProducts().filter(function(el) {
			  return el.parent_id == product_id;
		  });
	  },
	  
	  getProductsFromCategory: function(category) {
		  if (this.queryname) {
			  return category.products;
		  }
		  return category.products.filter(function(el) {
				return el.parent_id == null;
			});
	  },
	  
	reset: function() {
	  const vm = this;
	  vm.error_not_found = false;
	  vm.initDone = false;
	  vm.data = null;
	  vm.isLoading = false;
	},
	
	addToCart: function(item) {
		const vm = this;
		
		/*
			Dato un prodotto item
			prendo tutti i prodotti che possono essere correlati (es. altri prodotti se è una combo, le varianti degli altri prodotti della combo, altre varianti di gusto del prodotto)
			e popolo il tutto in un array relatedItems
			
			es.
			item: {
				prodotto con tutti i dettagli
			},
			related_items: [{
				prodotto con tutti i dettagli
			}]
		*/
		
		var relatedItems = [];
		this.getVariantsOfProduct(item.id).forEach(function(el) { // prendo eventuali varianti
			if (! relatedItems.filter(function(r) {
				return r.id == el.id;
			}).length) { // evito di inserire prodotti duplicati
				relatedItems.push(el);
			}
		});
		
		if (item && item.type == 'combo' && item.hasOwnProperty('options') && item.options) { // aggiungi un prodotto che contiene una combo di altri prodotti
			var comboItems = item.options.combo_items;
			comboItems.forEach(function(el) {
				var product = vm.getProduct(el);
				if (product) { // se il prodotto esiste
					var variants = vm.getVariantsOfProduct(product.id);
					
					if (variants && variants.length) { // prodotto singolo che ha delle varianti
						product.variants = variants;
						
						relatedItems.push(product);
						
						variants.forEach(function(z) {
							relatedItems.push(z);
						})
					}
					else { // prodotto singolo senza varianti
						relatedItems.push(product);
					}
				}
			});
		}
		
		this.$emit('addToCart', item, relatedItems);
		//this.$parent.universalAddToCart(item, relatedItems);
	},
	
	getProduct: function(item_id) {
		var theProduct = this.getAllProducts().filter(function(el) {
			return el.id == item_id;
		});
		if (theProduct.length) {
			return theProduct[0];
		}
	},
	
	getProductCategory: function(item_id) {
		return this.category;
	},
	
	getProductCategoryIndex: function(item_id, category) {
		var ix = 0;
		var final_ix = null;
		
		category.products.forEach(function(el) {
			if (el.id == item_id) {
				final_ix = ix;
			}
			ix++;
		})
		
		return final_ix;
	},
	
	removeAddedToCartBadge: function(item_id) {
		const theProduct = this.getProduct(item_id);
		if (theProduct) {
			theProduct.added_to_cart=false;
			
			// forzo l'aggiornamento ui
			const category = this.getProductCategory(item_id);
			if (category) {
				const product_ix = this.getProductCategoryIndex(item_id, category);
				
				this.$set(this.category, product_ix, theProduct);
			}
		}
	},
	
	setAddedToCartBadge: function(item_id) {
		const theProduct = this.getProduct(item_id);
		const vm = this;
		if (theProduct) {
			theProduct.added_to_cart=true;
			
			// forzo l'aggiornamento ui
			const category = this.getProductCategory(item_id);
			if (category) {
				const product_ix = this.getProductCategoryIndex(item_id, category);
				
				this.$set(this.category, product_ix, theProduct);
			}
			
			setTimeout(this.removeAddedToCartBadge.bind(null, theProduct.id), 1250);
			
			if (theProduct.parent_id) { // Se il prodotto aggiunto è una variante di un altro prodotto, voglio vedere a UI il badge "aggiunto a carrello" nella card del prodotto principale
				this.setAddedToCartBadge(theProduct.parent_id);
			}
			
		}
	},
	
	showProductAddedToCartBadge: function(items=[]) {
		const vm = this;
		console.log('items aggiunti a carrello');
		console.log(items);
		items.forEach(function(el) {
			vm.setAddedToCartBadge(el.item_id);
		});
	},
	
	
	revealScrollAnimation: function() {
		var reveals = document.querySelectorAll(".reveal:not(.active)");
		
		for (var i = 0; i < reveals.length; i++) {
			var windowHeight = window.innerHeight;
			var elementTop = reveals[i].getBoundingClientRect().top;
			var elementVisible = 100;
			
			if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add("active");
			} else {
				reveals[i].classList.remove("active");
			}
		}
	},
	
  },
  
  mounted: function() {
	const vm = this;
	Vue.prototype.$emitter.on('cart_item_added', this.showProductAddedToCartBadge);
	//Vue.prototype.$emitter.emit('rvmounted');
	
	window.addEventListener('scroll', this.revealScrollAnimation);
  },
  
  beforeDestroy: function() {
	  
	  Vue.prototype.$emitter.off('cart_item_added', this.showProductAddedToCartBadge);
	  Vue.prototype.$globalData.social_proof.bottom_distance = 0;
	  Vue.prototype.$globalData.footer.extraBottomSpaceOnMobile = 0;
	  
	  window.removeEventListener('scroll', this.revealScrollAnimation);
  }
  
}
</script>