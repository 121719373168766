<template>
	<div class="elements--post---video b5-container-lg px-0">
		<video-player class="rounded-app" v-if="data.processed_options.hasOwnProperty('video') && data.processed_options.video && data.processed_options.video.hasOwnProperty('media') && getFileVersionUrl(data.processed_options.video.media)" :video_options="{
		  autoplay: false,
		  muted: false,
		  controls: true,
		  poster: getFileVersionUrl(data.processed_options.video.media),
		  sources: getVideoSources(data.processed_options.video.media),
	  }" :settings="{
		  file_id: data.processed_options.video.media.id,
		  title: null,
		  excerpt: null,
		  page_url: null,
		  start_from: null,
		  aspect_ratio: data.processed_options.video.media.hasOwnProperty('options') && data.processed_options.video.media.options && data.processed_options.video.media.options.hasOwnProperty('aspect_ratio') ? data.processed_options.video.media.options.aspect_ratio : null,
		  orientation: data.processed_options.video.media.hasOwnProperty('options') && data.processed_options.video.media.options && data.processed_options.video.media.options.hasOwnProperty('orientation') ? data.processed_options.video.media.options.orientation : null,
	  }" />
		
		<div class="b5-bg-body py-2 px-3 text-center rounded-bottom-app" v-if="data.options.excerpt" v-html="nl2br(urlify(encodeHTML(data.options.excerpt)))"></div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
			getVideoSources: function(files) {
				  var sources = [];
				  
				  if (this.getFileVersionUrl(files, {
						types: ['mp4_360p_128kbps'],
					})) {
						  sources.push({
							src: this.getFileVersionUrl(files, {
								types: ['mp4_360p_128kbps'],
							}),
							type: 'video/mp4',
							label: '480p',
							  selected: false,
						  });
					  }
				  
				  if (this.getFileVersionUrl(files, {
					  types: ['mp4_480p_128kbps'],
				  })) {
						sources.push({
						  src: this.getFileVersionUrl(files, {
							  types: ['mp4_480p_128kbps'],
						  }),
						  type: 'video/mp4',
						  label: '720p',
						  selected: false,
						});
					}
				  
				  if (this.getFileVersionUrl(files, {
					  types: ['mp4_720p_128kbps'],
				  })) {
					  sources.push({
						src: this.getFileVersionUrl(files, {
							types: ['mp4_720p_128kbps'],
						}),
						type: 'video/mp4',
						label: '1080p',
						selected: true,
					  });
				  }
				  
				  if (! sources.length) {
					  sources.push({
						  src: this.getFileVersionUrl(files, {
							  types: ['original'],
						  }),
						  type: 'video/mp4',
						  label: 'Original',
						  selected: false,
						});
				  }
				  
				  if (this.getFileVersionUrl(files, {
						types: ['mp3_128kbps'],
					})) {
					  sources.push({
						  src: this.getFileVersionUrl(files, {
							  types: ['mp3_128kbps'],
						  }),
						  type: 'audio/mp3',
						  label: 'Audio',
						  selected: false,
					  });
				  }
				  
				  return sources;
			  },
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
		}
	}
</script>