<template>
	<div class="elements--post---go-herbalife-button b5-container-lg" v-if="data.options.url">
		<b5-link :href="data.options.url" class="btn btn-primary rounded-pill btn-lg d-block w-100 bg-gradient shadow-sm">
			{{ data.options.cta_text || 'Pulsante' }}
		</b5-link>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>