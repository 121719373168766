import { render, staticRenderFns } from "./BannersCollection.vue?vue&type=template&id=58c66e39&scoped=true&"
import script from "./BannersCollection.vue?vue&type=script&lang=js&"
export * from "./BannersCollection.vue?vue&type=script&lang=js&"
import style0 from "./BannersCollection.vue?vue&type=style&index=0&id=58c66e39&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c66e39",
  null
  
)

export default component.exports