<template>
	<div>
		<div id="footer-menus" class="b5-bg-body" v-if="$globalData.footer.menus.visible">
			<div class="b5-container-xl py-5">
				<div class="row g-4">
					<div :class="footer_col_classes">
						<img src="/assets/img/hlf/logo-color-independent-ds-it-80x.png" alt="Distributore Indipendente Herbalife" class="mb-3 mw-100" style="height: 40px">
						
						<div class="mb-4">
							<div class="fw-bold">{{ $globalData.coachdata.fullname }}</div>
							<div class="text-secondary text-sm">Distributore Indipendente Herbalife Nutrition</div>
							<div class="text-secondary mt-2">
								{{ $globalData.coachdata.address.line1 }}
								<div v-if="$globalData.coachdata.address.line2">{{ $globalData.coachdata.address.line2 }}</div>
								<div>
									{{ $globalData.coachdata.address.city }} ({{ $globalData.coachdata.address.province }}), {{ $globalData.coachdata.address.postalcode }}
								</div>
								
								<div v-if="$globalData.coachdata.address.country">
									{{ getCountryName($globalData.coachdata.address.country) }}, {{ $globalData.coachdata.address.country }}
								</div>
								<div>{{ $globalData.coachdata.contacts.phonenumber_prefix+' '+$globalData.coachdata.contacts.phonenumber }}</div>
							</div>
						</div>
						
						
						<button v-if="$globalData.websitedata.shop.available_countries.length > 1 && $globalData.websitedata.shop.current_country" type="button" class="btn btn-white d-flex align-items-center text-start w-100 rounded-pill" @click="showBSModal('modal-choose-country')">
							<div class="w-100 fw-medium d-flex align-items-center">
								<img :src="$globalData.websitedata.shop.current_country.icon" :alt="$globalData.websitedata.shop.current_country.name" title="Switch country" class="me-2" height="18px" />
								<div>{{ $globalData.websitedata.shop.current_country.name }}</div>
							</div>
							
							<i class="fas fa-fw fa-chevron-down text-secondary ms-auto"></i>
						</button>
					</div>
					
					<div :class="footer_col_classes" v-for="col in $globalData.websitedata.footer.menus.cols">
						<h5>{{ col.title }}</h5>
						<ul class="list-group list-group-flush">
							<li class="list-group" v-for="item in col.items">
								<b5-link v-if="item.use_vue" :href="item.url" class="list-group-item list-group-item-action bg-none">{{ item.label }}</b5-link>
								<a v-else :href="item.url" class="list-group-item list-group-item-action border-0 bg-none">
									{{ item.label }}
								</a>
							</li>
						</ul>
					</div>
					
					<!-- <div :class="footer_col_classes">
						<h5>Menu</h5>
						<ul class="list-group list-group-flush">
							<router-link v-if="$route.params.teamSlug" :to="{
								name: 'shop.team.catalog.show',
								params: {
									teamSlug: $route.params.teamSlug,
									countryCode: getCurrentCountryCode(),
								}
							}" class="list-group-item list-group-item-action bg-none">Catalogo prodotti</router-link>
							
							<router-link v-if="$route.params.teamSlug" :to="{
								name: 'shop.team.cart.show',
								params: {
									teamSlug: $route.params.teamSlug,
									countryCode: getCurrentCountryCode(),
								}
							}" class="list-group-item list-group-item-action bg-none">Il mio carrello</router-link>
							
							<a href="" v-for="name in ['Contatti']" class="list-group-item list-group-item-action bg-none">{{ name }}</a>
						</ul>
					</div> -->
					
					<!-- <div :class="footer_col_classes">
						<h5>Documenti legali</h5>
						<ul class="list-group list-group-flush">
							<a target="_blank" class="list-group-item list-group-item-action bg-none" :href="'/'+$route.params.teamSlug+'/terms'">Termini e condizioni d'uso</a>
							<a target="_blank" class="list-group-item list-group-item-action bg-none" :href="'/'+$route.params.teamSlug+'/shipping-policy'">Condizioni di vendita</a>
							<a target="_blank" class="list-group-item list-group-item-action bg-none" :href="'/'+$route.params.teamSlug+'/refund-policy'">Informativa sui resi</a>
							<a target="_blank" class="list-group-item list-group-item-action bg-none" :href="'/'+$route.params.teamSlug+'/privacy-policy'">Informativa sulla privacy</a>
						</ul>
					</div> -->
					<div v-if="$globalData.teamdata.newsletter.is_available" :class="footer_col_classes">
						<h5>Unisciti alla newsletter</h5>
						<p>resta informato e ricevi news, benessere, promozioni, etc. esclusive e dedicate alla nostra lista vip</p>
						
						<div v-if="newsletter_subscribed" class="alert alert-success text-center">
							Fantastico! Sei dentro.
						</div>
						<div v-else>
							<div class="position-relative mb-2">
								<input type="text" class="form-control form-control-lg rounded-pill px-4" placeholder="marco@rossi.com" v-model="newsletter_email_address" v-bind:disabled="newsletter_submitting==1" v-bind:class="newsletter_email_address && !isValidEmail(newsletter_email_address) ? 'is-invalid' : ''">
								<button v-if="!newsletter_email_address" type="button" class="btn btn-primary rounded-pill position-absolute top-50 end-0 me-2 translate-middle-y btn-sm">
									<i class="fas fa-fw fa-arrow-right"></i>
								</button>
							</div>
							
							<div v-if="newsletter_email_address" class="alert alert-info text-dark rounded-app mb-2">
								<b><i class="fas fa-fw fa-gift"></i> Ricevi una sorpresa speciale</b>
								<p class="text-sm mb-1"><em>(opzionale)</em> Inserisci la tua data di nascita per ricevere una sorpresa unica il giorno del tuo compleanno:</p>
								<input type="date" class="form-control" v-bind:disabled="newsletter_submitting==1" v-model="newsletter_birthdate" placeholder="YYYY-MM-DD">
							</div>
							
							<button v-if="newsletter_submitting==1" class="btn btn-primary rounded-pill px-4" type="button" disabled>
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								<span class="visually-hidden">Loading...</span>
							</button>
							<button v-else-if="newsletter_email_address" type="button" class="btn btn-primary rounded-pill px-4" v-bind:disabled="!(newsletter_email_address && this.isValidEmail(newsletter_email_address))" @click="subscribeNewsletter()">
								Unisciti alla newsletter <i class="fas fa-fw fa-arrow-right"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			</div>
			
			<div class="b5-container-xl py-3 text-sm">
				<div class="w-100 d-flex justify-content-between">
					<div>
						&copy; {{ toUserTz().format('YYYY') }} <b>{{ $globalData.coachdata.fullname }}</b>
					</div>
					<div>
						Powered by <a href="https://build5.app" target="_blank" rel="nofollow" class="text-reset text-decoration-none text-secondary fw-medium">Build5</a>
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		computed: {
			footer_col_classes: function() {
				var colscount = 1;
				var classes = ['col-12'];
				
				if (Vue.prototype.$globalData.teamdata.newsletter.is_available) {
					colscount++;
				}
				
				colscount += Vue.prototype.$globalData.websitedata.footer.menus.cols.length;
				
				if (colscount == 2) {
					classes.push('col-lg-6');
				}
				else if (colscount == 3) {
					classes.push('col-lg-4');
				}
				else if (colscount == 4) {
					classes.push('col-lg-3');
				}
				else {
					classes.push('col-lg-3');
				}
				
				
				return classes.join(' ');
			},
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
		}
	}
</script>