<template>
<div>
	<!-- <div class="position-absolute top-0 start-0 end-0">
		<div class="progress rounded-0 bg-transparent" style="height:5px" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
			  <div class="progress-bar" style="width: 25%"></div>
			</div>
		
		<div class="text-end">
			<i class="fas fa-volume"></i>
			<i class="fas fa-expand"></i>
		</div>
	</div> -->
	
	<video-player :video_options="{
		  autoplay: true,
		  muted: false,
		  controls: false,
		  poster: obj.msg.poster,
		  sources: [{
			  type: 'video/mp4',
			  src: obj.msg.src
		  }],
	  }" :settings="{
		  file_id: null,
		  title: null,
		  excerpt: null,
		  page_url: null,
		  start_from: null,
		  aspect_ratio: null,
		  orientation: 'vertical',
		  show_unmute_button: true,
		  show_replay_button: true,
		  show_progress_bar: true,
	  }" />
	
	<!-- <video playsinline class="bg-black w-100" :poster="obj.msg.poster">
		<source :src="obj.msg.src" type="video/mp4">
	</video> -->
	
	<div>
		<button v-if="is_loading" class="btn btn-evidence d-block w-100 rounded-pill disabled position-absolute bottom-0 start-0 end-0 w-100" type="button" disabled>
			<span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
			<span class="visually-hidden" role="status">Loading...</span>
		</button>
		<div v-else-if="obj.actions?.length">
			<!-- <i class="fas fa-fw fa-play fs-1 text-white position-absolute top-50 start-50 translate-middle"></i> -->
			
			<div class="px-3 pt-2 pb-1 btn-group-vertical position-absolute bottom-0 start-0 end-0 w-100">
				<div v-for="item in obj.actions" class="w-100 mb-1">
					<button type="button" class="btn rounded-pill d-block w-100 b5-bg-opaque btn-opaque-light text-black btn-opaque-light-lg" @click="liveChatActionClicked(item)">
						{{ item.label }} <i class="b5-fa-icon fa-fw fa-external-link" v-if="isExternalUrl(item.username)"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	props: ['data'],
	data() {
		
		var el = this.data;
		el.msg = el.messages[0];
		
		return {
			obj: el,
			is_loading: false,
		};
	},
	methods: {
		playVideo: function() {
			
		},
		pauseVideo: function() {
			
		},
		muteVideo: function() {
			
		},
		unmuteVideo: function() {
			
		},
		
		liveChatActionClicked: function(item) {
			this.$emit('actionclicked', item);
		},
	}
};
</script>