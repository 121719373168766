import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

// Errors
import Error404 from './pages/error/404.vue';

Vue.use(VueRouter);
Vue.use(VueMeta, {
	refreshOnceOnNavigation: true
});

function lazyLoadRoute(view) {
  return() => import(`./pages/${view}.vue`);
}

const router = new VueRouter({
	mode: 'history',
	linkExactActiveClass: 'active',
	base: process.env.BASE_URL,
	//base: '/',
	routes: [
		{
			path: '/',
			name: 'home',
			component: lazyLoadRoute('page/Show'),
		},
		
		// Pages
		{
			path: '/tp/:teamSlug?/:pageSlug',
			name: 'page.team.show',
			component: lazyLoadRoute('team/page/Show'),
		},
		{
			path: '/:pageSlug',
			name: 'page.show',
			component: lazyLoadRoute('page/Show'),
		},
		
		// Forms
		{
			path: '/tf/:teamSlug/:formSlug',
			name: 'form.team.show',
			component: lazyLoadRoute('form/Show'),
		},
		{
			path: '/form/:formSlug',
			name: 'form.show',
			component: lazyLoadRoute('form/Show'),
		},
		
		// Booking
		{
			path: '/booking/:bookingSlug',
			name: 'booking.show',
			component: lazyLoadRoute('booking/Show'),
		},
		
		// Team events
		{
			path: '/tevent/:teamSlug',
			name: 'team.event.index',
			component: lazyLoadRoute('team/event/Index'),
			meta: { transition: 'slide-left' },
		},
		{
			path: '/tevent/:teamSlug/:eventSlug',
			name: 'team.event.show',
			component: lazyLoadRoute('team/event/Show'),
			meta: { transition: 'slide-left' },
		},
		{
			path: '/tevent/:teamSlug/mytickets/:ticketKey',
			name: 'team.event.ticket.manage',
			component: lazyLoadRoute('team/event/ticket/Manage'),
			meta: { transition: 'slide-left' },
		},
		
		// Team shop
		{
			path: '/tshop/:teamSlug?/:countryCode/catalog',
			name: 'shop.team.catalog.show',
			component: lazyLoadRoute('shop/catalog/Show'),
			meta: { transition: 'slide-left' },
		},
		{
			path: '/tshop/:teamSlug/:countryCode/product/:productSlug',
			name: 'shop.team.product.show',
			component: lazyLoadRoute('shop/product/Show'),
			meta: { transition: 'slide-left' },
		},
		{
			path: '/tshop/:teamSlug/:countryCode/cart',
			name: 'shop.team.cart.show',
			component: lazyLoadRoute('shop/cart/Show'),
			meta: { transition: 'slide-left' },
		},
		{
			path: '/tshop/:teamSlug/:countryCode/checkout',
			name: 'shop.team.checkout.show',
			component: lazyLoadRoute('shop/checkout/Show'),
			meta: { transition: 'slide-left' },
		},
		
		{ path: "*", component: Error404 },
		
		{ 
		  path: '/:catchAll(.*)', 
		  component: Error404
		}
	],
	
	scrollBehavior (to, from, savedPosition) {
	  // return desired position
	  if (to.hash) {
		return {
		  selector: to.hash
		  // , offset: { x: 0, y: 10 }
		};
	  }
	  
	  if (savedPosition) {
		return savedPosition;
	  } else {
		return { x: 0, y: 0 };
	  }
	}
});

router.beforeEach((to, from, next) => { // Serve per fixare il redirect che fa Facebook Login (sia logged che da guest) aggiungeo #_=_ alla url
  if (to.path == '/_=_') {
	return next(false);
  }
  next();
});
	
router.afterEach((to, from, failure) => {
	const toDepth = to.path.split('/').length
	const fromDepth = from.path.split('/').length
	to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left'
});

export default router;