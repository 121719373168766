<template>
	<div class="b5-container-xl py-2 py-lg-3">
		<div class="d-flex w-100 align-items-center w-100">
			<button type="button" class="btn btn-ghost rounded-pill me-1 me-lg-2 text-secondary" @click="$globalData.fsnavigation.visible=1">
				<i class="fas fa-fw fa-bars"></i>
			</button>
			<router-link v-if="$route.params.teamSlug" :to="{
				name: 'page.show',
				params: {
					pageSlug: 'home',
				}
			}" class="cursor-pointer text-reset text-decoration-none">
				<img v-if="$globalData.websitedata.metas?.logo_url" :src="$globalData.websitedata.metas.logo_url" alt="Logo" title="" height="30px" />
				<div v-else class="d-flex align-items-center">
					<div class="b5-bg-body4 rounded-circle bg-cover me-2" :style="'width:48px;height:48px;background-image: url('+$globalData.coachdata.avatar_url+');'"></div>
					<div class="d-lg-none">
						<div class="fs-6 fw-bold text-black">{{ $globalData.coachdata.firstname }}</div>
					</div>
					<div class="d-none d-lg-block lh-sm">
						<div class="fs-4 fw-bold text-black">{{ $globalData.coachdata.fullname }}</div>
						<div class="text-sm text-secondary">Distributore Indipendente Herbalife</div>
					</div>
				</div>
				<!-- <img src="https://d2lalp3jtr7683.cloudfront.net/2erkyy/y8bmnd.png" alt="" height="38px" /> -->
			</router-link>
			
			<div class="d-flex align-items-center ms-auto">
				<button v-if="$globalData.websitedata.shop.available_countries.length > 1 && $globalData.websitedata.shop.current_country" type="button" class="btn btn-ghost rounded-pill me-1 d-none d-lg-inline-block" @click="showBSModal('modal-choose-country')">
					<img :src="$globalData.websitedata.shop.current_country.icon" :alt="$globalData.websitedata.shop.current_country.name" title="Switch country" height="18px" />
				</button>
				
				<div v-if="$globalData.header.cart.visible">
					<button type="button" v-if="$parent.isCartLoading" class="btn btn-ghost rounded-pill mx-1 px-3 disabled" disabled>
						<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
						<span class="visually-hidden">Loading...</span>
					</button>
					<router-link v-else-if="$route.params.teamSlug" :to="{
						name: 'shop.team.cart.show',
						params: {
							teamSlug: $route.params.teamSlug,
							countryCode: getCurrentCountryCode(),
						}
					}" class="btn btn-ghost rounded-pill mx-1 px-4 border text-nowrap" v-bind:class="$globalData.cart.total_items ? 'text-primary border-primary' : ''">
						{{ $globalData.cart.total_items }} <i class="fas fa-fw fa-bag-shopping"></i>
					</router-link>
				</div>
			</div>
		</div>
		</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
		}
	}
</script>