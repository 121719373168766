<template>
	<div class="elements--post---picture b5-container-lg">
		<img v-if="data.processed_options.hasOwnProperty('picture') && data.processed_options.picture && data.processed_options.picture.hasOwnProperty('media') && getFileVersionUrl(data.processed_options.picture.media)" 
			:src="getFileVersionUrl(data.processed_options.picture.media)" 
			:srcset="getFileVersionUrl(data.processed_options.picture.media, {
				types: [ 'image_1920', 'image_1080', 'original' ],
			})+' 2x'"
			alt="" class="img-fluid w-100" v-bind:class="data.options.excerpt ? 'rounded-top-app' : 'rounded-app'" />
		<div class="b5-bg-body py-2 px-3 text-center rounded-bottom-app" v-if="data.options.excerpt" v-html="nl2br(urlify(encodeHTML(data.options.excerpt)))"></div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			formattedZoomId: function() {
				return this.data.options.zoom_id.match(/.{1,3}/g).join(' ');
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
		}
	}
</script>