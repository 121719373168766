<template>
	<div class="elements--post---double-column-text b5-container-lg px-0 mx-0">
		<div class="d-flex justify-content-between align-items-start">
			<div class="text-secondary">{{ data.options.title }}</div>
			<div class="text-end pre-formatted" v-html="nl2br(urlify(encodeHTML(data.options.content)))"></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>