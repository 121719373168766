<template>
	<section :class="getContainerClasses()">
		<div :class="getSectionClasses()" v-bind:data-src="getFileVersionUrl(this.instance.processed_options?.background_picture?.media)">
			<div :class="getOverlay()+' position-relative'">
				<div :class="getOverlayClasses()">
					<dynamic-element-render 
						v-for="(item, index) in instance.childrens" :key="'col-'+makeid(12)+'-'+item.id"
						group="website-page" :instance="item" />
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		instance: null
	},
	
	methods: {
		
		hasBackgroundPicture: function() {
			return false;
		},
		
		getContainerClasses: function() {
			var classes = [];
			
			if (! this.instance.options.hasOwnProperty('container_size')) {
				classes.push('b5-container-lg');
			}
			else {
				if (this.instance.options.container_size == 'small') {
					classes.push('b5-container-sm');	
				}
				else if (this.instance.options.container_size == 'default') {
					classes.push('b5-container-lg');	
				}
				else if (this.instance.options.container_size == 'large') {
					classes.push('b5-container-xl');	
				}
				else if (this.instance.options.container_size == 'xl') {
					classes.push('b5-container-xxl');	
				}
				else if (this.instance.options.container_size == 'full-width') {
					classes.push('w-100');	
				}
			}
			
			return classes.join(' ');
		},
		
		getSectionClasses: function() {
			var classes = [
				'b5-bg-body2 d-block p-0'
			];
			
			classes.push('bg-gradient-'+this.getBackgroundGradient());
			
			if (this.instance.options.border_radius == 'default') {
				classes.push('rounded');
			}
			else if (this.instance.options.border_radius == 'small') {
				classes.push('rounded-sm');
			}
			else if (this.instance.options.border_radius == 'large') {
				classes.push('rounded-app');
			}
			
			if (this.hasBackgroundPicture()) {
				classes.push('lazy bg-cover');
			}
			
			return classes.join(' ');
		},
		
		getBackgroundGradient: function() {
			  return this.instance.options.background_gradient;
		  },
		
		getOverlay: function() {
			const vm = this;
			var str = '';
			
			
			if (this.instance.options.hasOwnProperty('full_height') && this.instance.options.full_height) {
				str = 'min-vh-90 d-flex align-items-center justify-content-center w-100';
			}
			
			if (this.instance.options.border_radius == 'default') {
				str += ' rounded';
			}
			else if (this.instance.options.border_radius == 'small') {
				str += ' rounded-sm';
			}
			else if (this.instance.options.border_radius == 'large') {
				str += ' rounded-app';
			}
			
			if (! vm.instance.options.hasOwnProperty('background_overlay')) {
				return str;
			}
			
			if (vm.instance.options.background_overlay == 'light-sm') {
				return str+' overlay overlay-light overlay-sm';
			}
			else if (vm.instance.options.background_overlay == 'light-md') {
				return str+' overlay overlay-light overlay-md';
			}
			else if (vm.instance.options.background_overlay == 'light-xl') {
				return str+' overlay overlay-light overlay-xl';
			}
			else if (vm.instance.options.background_overlay == 'dark-sm') {
				return str+' overlay overlay-dark overlay-sm';
			}
			else if (vm.instance.options.background_overlay == 'dark-md') {
				return str+' overlay overlay-dark overlay-md';
			}
			else if (vm.instance.options.background_overlay == 'dark-xl') {
				return str+' overlay overlay-dark overlay-xl';
			}
			
			return;
		},
		
		getOverlayClasses: function() {
			var classes = [];
			
			return classes.join(' ');
		},
	},
}
</script>