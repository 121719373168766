<template>
	<div :class="getSectionClasses()"></div>
</template>

<script>
export default {
	props: {
		instance: null
	},
	
	methods: {
		getSectionClasses: function() {
			var classes = [];
			
			if (this.instance.options.size_mobile == 'small') {
				classes.push('py-1');	
			}
			else if (this.instance.options.size_mobile == 'medium') {
				classes.push('py-3');	
			}
			else if (this.instance.options.size_mobile == 'large') {
				classes.push('py-4');	
			}
			else if (this.instance.options.size_mobile == 'extra-large') {
				classes.push('py-5');	
			}
			
			if (this.instance.options.size_desktop == 'small') {
				classes.push('py-lg-1');	
			}
			else if (this.instance.options.size_desktop == 'medium') {
				classes.push('py-lg-3');	
			}
			else if (this.instance.options.size_desktop == 'large') {
				classes.push('py-lg-4');	
			}
			else if (this.instance.options.size_desktop == 'extra-large') {
				classes.push('py-lg-5');	
			}
			
			return classes.join(' ');
		},
	},
}
</script>