var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasOption('referenceable_id') && _vm.instance.processed_options.referenceable_id.hasOwnProperty('data') && _vm.instance.processed_options.referenceable_id.data.posts.length)?_c('div',{staticClass:"elements--post-category--heading"},[_c('div',{staticClass:"container-xl pt-5 mb-3"},[(_vm.instance.options.title)?_c('h1',{class:(_vm.instance.options.excerpt) ? 'mb-0' : ''},[_vm._v("\n\t\t\t"+_vm._s(_vm.instance.options.title)+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.instance.options.excerpt)?_c('p',[_vm._v("\n\t\t\t"+_vm._s(_vm.instance.options.excerpt)+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.hasOption('referenceable_id') && _vm.instance.processed_options.referenceable_id.hasOwnProperty('data') && _vm.instance.processed_options.referenceable_id.data.posts.length)?_c('div',{staticClass:"row flex-row flex-nowrap justify-content-start overflow-auto"},_vm._l((_vm.instance.processed_options.referenceable_id.data.posts),function(item,index){return _c('div',{staticClass:"col-10 col-md-9 col-lg-5 col-xl-5"},[_c('router-link',{staticClass:"text-decoration-none text-reset card mb-3",attrs:{"to":{
					name: 'post.show',
					params: {
						postCategorySlug: item.category.slug,
						postSlug: item.slug
					}
				}}},[(item.cc_thumbnail_720p_url)?_c('img',{staticClass:"lazy img-fluid card-img-top",attrs:{"data-src":item.cc_thumbnail_720p_url,"alt":""}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-body"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.name)+"\n\t\t\t\t\t")])])],1)}),0):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }