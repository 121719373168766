<template>
	<div class="elements--post---plain-text b5-container-lg" v-if="hasText()">
		<div v-if="data.options.text_format == 'html'" v-html="data.options.text"></div>
		<div v-else v-html="nl2br(urlify(encodeHTML(data.options.text)))"></div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			hasText: function() {
				return (this.data && this.data.hasOwnProperty('options') && this.data.options && this.data.options.hasOwnProperty('text') && this.data.options.text);
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>