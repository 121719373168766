var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.instance.processed_data && _vm.instance.processed_data.length)?_c('div',{class:{
	'b5-bg-gradient-body-transparent-bottom': (_vm.getStyle() == 'infinity'),
	'b5-bg-body2': (_vm.getStyle() != 'rounded'),
	'elements--post-category--post-collection-carousel': true,
}},[_c('div',{class:{
		'b5-container-xl': (_vm.getStyle() != 'infinity'),
		'px-0': (_vm.getStyle() != 'rounded')
	}},[_c('div',{staticClass:"carousel slide max-vh-50 carousel-scheme-auto",attrs:{"id":_vm.carousel_id,"data-bs-ride":"carousel"}},[(_vm.instance.processed_data.length > 1)?_c('div',{staticClass:"carousel-indicators"},_vm._l((_vm.instance.processed_data),function(item,index){return _c('button',{class:index == 0 ? 'active' : '',attrs:{"aria-current":index,"type":"button","data-bs-target":'#' + _vm.carousel_id,"data-bs-slide-to":index,"aria-label":'Slide ' + index}})}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"carousel-inner"},_vm._l((_vm.instance.processed_data),function(item,index){return _c('router-link',{key:index,staticClass:"text-decoration-none carousel-item b5-bg-body2",class:index == 0 ? 'active' : '',attrs:{"to":{
					name: 'post.show',
					params: {
						postCategorySlug: item.category.slug,
						postSlug: item.slug
					}
				}}},[(_vm.getThumbnailOfItem(item))?_c('div',{staticClass:"img-flui lazy d-block w-100 bg-cover max-vh-50",class:_vm.getStyle() == 'rounded' ? 'rounded-app' : '',attrs:{"data-src":_vm.getThumbnailOfItem(item)}},[_c('img',{staticClass:"lazy img-fluid d-block w-100 max-vh-50",staticStyle:{"visibility":"hidden"},attrs:{"data-src":_vm.getThumbnailOfItem(item, {
							types: [ 'image_1920', 'image_1080', 'image_720', 'original' ],
						}),"alt":""}})]):_vm._e(),_vm._v(" "),(_vm.getOption('show_text', true))?_c('div',{staticClass:"carousel-caption start-0 end-0 bottom-0 pb-3 pb-lg-4 pt-5",class:_vm.getStyle() == 'infinity' ? 'b5-bg-gradient-body-transparent-bottom' : 'b5-bg-gradient-dark-opaque-bottom text-white'},[(_vm.moment(item.publish_at) > _vm.moment())?_c('span',{staticClass:"badge bg-primary mb-2"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.toUserTz(item.publish_at).format('llll'))+"\n\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(item.name)?_c('h5',{class:{
							'mb-0': (item.excerpt)
						}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.name)+"\n\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(item.excerpt)?_c('p',{class:{
						}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.excerpt)+"\n\t\t\t\t\t\t")]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.getOption('show_text', true) && _vm.getStyle() == 'infinity')?_c('div',{staticClass:"carousel-caption start-0 end-0 bottom-0 py-5 b5-bg-gradient-body-transparent-bottom"}):_vm._e()])}),1),_vm._v(" "),(_vm.instance.processed_data.length > 1)?_c('button',{staticClass:"carousel-control-prev",attrs:{"type":"button","data-bs-target":'#' + _vm.carousel_id,"data-bs-slide":"prev"}},[_c('span',{staticClass:"carousel-control-prev-icon",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"visually-hidden"},[_vm._v("Previous")])]):_vm._e(),_vm._v(" "),(_vm.instance.processed_data.length > 1)?_c('button',{staticClass:"carousel-control-next",attrs:{"type":"button","data-bs-target":'#' + _vm.carousel_id,"data-bs-slide":"next"}},[_c('span',{staticClass:"carousel-control-next-icon",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"visually-hidden"},[_vm._v("Next")])]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }