<template>
	<div class="elements--page--checklist b5-container-lg">
		<ul class="list-unstyled elements" v-if="data.hasOwnProperty('childrens') && Array.isArray(data.childrens)">
			<li v-for="(item, index) in data.childrens" :key="item.id" class="mb-3 item">
				<dynamic-element-render group="page" :instance="item"></dynamic-element-render>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>