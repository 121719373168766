<template>
	<div :class="config.container_classes" :id="video_id" :style="'background: url('+options.poster+') no-repeat center center;background-size:cover;'">
		<error-boundary>
			<div class="position-absolute top-0 start-0 end-0" style="z-index:999">
				<div v-if="config.show_progress_bar" class="progress rounded-0 mb-1 bg-none" role="progressbar" style="height:4px" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar" :style="'width:'+((currentTime*100)/totalDuration)+'%'"></div>
				</div>
				
				<div class="pt-1 px-1">
					<span v-if="config.show_premiere_badge && !show_error_message" class="badge badge-live-streaming text-uppercase">
						<i class="fas fa-fw fa-signal-stream"></i> Première
					</span>
					
					<button v-if="config.show_unmute_button" @click="toggleMute()" type="button" class="btn b5-bg-opaque btn-opaque-light text-black rounded-pill btn-sm">
						<i class="fas fa-fw" v-bind:class="this.options.muted ? 'fa-volume-slash' : 'fa-volume'"></i>
					</button>
					<!-- <button v-if="config.show_replay_button" @click="replay()" type="button" class="btn b5-bg-opaque btn-opaque-light text-black rounded-pill btn-sm">
						<i class="fas fa-fw fa-rotate-left"></i>
					</button> -->
				</div>
			</div>
			
			
			<button v-if="config.show_replay_button && ended" @click="replay()" type="button" class="btn position-absolute top-50 start-50 translate-middle rounded-pill btn-lg b5-bg-opaque btn-opaque-light text-black btn-opaque-light-lg shadow" style="z-index:999">
				<i class="fas fa-fw fa-rotate-left"></i>
			</button>
			
			<video v-if="! show_error_message" ref="videoPlayer" :id="config.player_id" class="video-js w-100 h-100 b5-video-player position-relative" oncontextmenu="return false;" v-bind:class="{
				'vjs-premiere': config.show_premiere_badge,
				'overlay-dark overlay-dark-xl b5-bg-opaque': config.show_waveform,
			}" v-bind:style="(config.height) ? 'height:'+config.height : null">
				<track v-for="track in options.tracks" :kind="track.kind" :src="track.src" :srclang="track.srclang" :label="track.label">
			</video>
		</error-boundary>
		<div v-if="show_error_message" class="media-fails">
			<div class="ratio ratio-16x9 position-relative overflow-hidden">
			<div class="old-tv-noise">
			  <div class="main-wrapper">
				<div class="noise-wrapper">
				  <div class="noise"></div>
				</div>
				<p class="loader">
					Si è verificato un errore durante il caricamento di questo video. Riprova più tardi.
				</p>
			  </div>
			</div>
		</div>
		</div>
		
	</div>
</template>
<style scoped>
	.old-tv-noise {
		background-color: #2c2c2c;
		  background: radial-gradient(ellipse at center, #444756 0%,#1d1b26 100%);
		  color: #fff;
	}
	.old-tv-noise .main-wrapper {
		position: relative;
		padding: 0;
		display: flex;
		justify-content:center;
		align-items:center;
		min-height:100%;
	}
	.old-tv-noise .loader {
		position: relative;
		  text-align: center;
		  margin: 0;
		  padding: 0;
		  z-index: 1;
	}
	.old-tv-noie .noise-wrapper {
		position: absolute;
		  left: 0;
		  top: 0;
		  width: 100%;
		  height: 100%;
		  opacity: 0;
		  overflow: hidden;
		  z-index: 10;
		  
		  &:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
		  }
	}
	
	.old-tv-noise .noise {
		  position: absolute;
		  top: -500px;
		  right: -500px;
		  bottom: -500px;
		  left: -500px;
		  background: transparent url(https://www.dropbox.com/s/h7ab1c82ctzy83n/noise.png?raw=1) 0 0;
		  background-size: 320px 320px;
			opacity: .35;
			animation: noise 1s steps(8,end) infinite both;
		}
		
	@keyframes noise {
		  0% {
			transform: translateX(0px,0px); }
		  10% {
			transform: translate(-100px, 100px);
		  }
		  20% {
			transform: translate(150px, -100px);
		  }
		  30% {
			transform: translate(-100px,100px);
		  }
		  40% {
			transform: translate(100px, -150px);
		  }
		  50% {
			transform: translate(-100px, 200px);
		  }
		  60% {
			transform: translate(-200px, -100px);
		  }
		  70% {
			transform: translateY(50px, 100px);
		  }
		  80% {
			transform: translate(100px, -150px);
		  }
		  90% {
			transform: translate(0px, 200px);
		  }
		  100% {
			transform: translate(-100px, 100px);
		  }
		}
		
		
</style>
<script>
import videojs from 'video.js';
//import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';

export default {
	name: "VideoPlayer",
	props: {
		video_options: {},
		settings: {},
	},
	
	data() {
		const defaultConfig = {
			file_id: null,
			track_watching: false,
			restart_position: false,
			aspect_ratio: '16x9',
			show_waveform: false,
			height: null,
			show_premiere_badge: false,
			
			show_unmute_button: false,
			show_replay_button: false,
			show_progress_bar: false,
			
			container_classes: 'w-100 position-relative',
		};
		
		const defaultOptions = {
			autoplay: false,
			controls: false,
			//controlBar: true,
			controlsList: 'nodownload',
			muted: false,
			loop: false,
			preload: 'metadata',
			playsinline: true,
			playbackRates: [0.5, 1, 1.5, 2],
			debugEnabled: false,
			responsive: true,
			fluid: false,
			bigPlayButton: true,
			player_id: null,
			
			tracks: [],
			html5: {
				nativeTextTracks: false
			},
			
			//preventGoingForward: false,
			
			controlBar: {
				fullscreenToggle: true,
				pictureInPictureToggle: true,
				//QualitySelector: true,
			},
			
			plugins: {
				/*
				wavesurfer: {
					// configure videojs-wavesurfer
					backend: 'MediaElement',
					displayMilliseconds: true,
					debug: true,
					waveColor: '#a2a7b0',
					progressColor: '#2163e8',
					cursorColor: '#ffffff',
					hideScrollbar: true,
					normalize: true,
					pixelRatio: 1,
					splitChannels: false,
					// put waveform in separate container
					//container: '#waveform'
				}
				*/
			}
		};
		
		return {
			config: {...defaultConfig, ...this.settings},
			options: {...defaultOptions, ...this.video_options},
			player: null,
			video_id: 'vjs-video-player-'+this.makeid(12),
			
			// Watched time
			updateVideoInterval: null,
			previousTime: 0,
			currentTime: 0,
			totalDuration: 0,
			seekStart: null,
			ended: 0,
			first_time_muted: false,
			
			isSubmitting: false,
			show_error_message: false,
			
			//supposedCurrentTime: 0,
		};
	},
	
	methods: {
		
		toggleMute: function() {
			if (this.first_time_muted) { // la prima volta che il video parte in muted e clicco per attivare il suono, riavvio il video da capo
				console.log('prima volta che parte in muted');
				this.options.muted = 0;
				this.volume = 100;
				this.player.muted(0);
				this.replay();
				this.first_time_muted = false; // lo spengo così non si ripete più
			}
			else {
				this.options.muted = !this.options.muted;
				this.volume = (this.options.muted) ? 0 : 100;
				this.player.muted(this.options.muted);
			}
		},
		
		replay: function() {
			this.player.currentTime(0);
			// this.player.pause();
			// this.player.trigger('loadedmetadata');
			this.player.play();
		},
		
		addMarkers: function(cuePointsAra, videoDuration) {
			var iMax = cuePointsAra.length,
			  i,
			  playheadWell = document.getElementsByClassName(
				"vjs-progress-control vjs-control"
			  )[0];
			// Loop over each cue point, dynamically create a div for each
			// then place in div progress bar
			for (i = 0; i < iMax; i++) {
			  var elem = $(`<div></div>`);
			  elem.addClass('vjs-marker position-absolute');
			  elem.attr('id', 'cp'+i);
			  elem.attr('title', "Vai a "+cuePointsAra[i].text);
			  elem.attr('data-bs-toggle', 'tooltip');
			  elem.css('left', cuePointsAra[i] / videoDuration * 100 + "%");
			  elem.appendTo(playheadWell);
			}
		  },
		  
		  saveVideoWatchedTime: function() {
			  const vm = this;
			  //console.log('ecco '+Vue.prototype.$currentUser.id);
			  if (! vm.isSubmitting && vm.config.file_id && vm.config.track_watching) {
				  vm.isSubmitting = true;
				  
				  var params = {
						watched_time: vm.currentTime,
					};
				
					if (vm.total_duration) {
						params.total_duration = vm.totalDuration;
					}
				  
				  axios.put('/api/v6/file/player/'+vm.config.file_id, params)
				  .then(res => {
					  if (res.data.status == 'success') {
						  
					  }
				  })
				  .finally(() => {
					  vm.isSubmitting = false;
				  });
			  }
		  },
	},
	
	mounted() {
		const vm = this;
		
		if (this.config.aspect_ratio) {
			this.options.aspectRatio = this.config.aspect_ratio.replace('x', ':');
		}
		
		this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
			//console.log('onPlayerReady', this);
			var player = this;
			
			vm.first_time_muted = this.muted() ? 1 : 0;
			
			var plEl = document.getElementById(vm.video_id);
			if (plEl) {
				//plEl.removeClass('vjs-fluid');
			}
			
			vm.$emit('vjs_ready', player);
			
			if (player && player.controlBar) {
				player.controlBar.removeChild('QualitySelector');
			}
			
			if (player && player.controlBar && vm.options.controlBar?.QualitySelector) {
				player.controlBar.addChild('QualitySelector');
			}
			
			this.on('error', function(element, error) {
				vm.show_error_message = true;
				vm.player.dispose();
				vm.$emit('error');
			});
			
			this.on('loadedmetadata', function() {
				if (vm.config.start_from) {
					this.currentTime(vm.config.start_from);
				}
				
				vm.$emit('loadedmetadata');
			});
			
			this.on('waveReady', function(event) {
				//console.warn('waveready');
				this.wavesurfer = player.wavesurfer().surfer;
				//this.addWavesurferEvents();
				//this.wavesurferReady();
			});
			
			// Watched time
			this.on('timeupdate', function() { // ogni secondo
				
				/*
				if (! this.seeking) {
					vm.supposedCurrentTime = this.currentTime;
				}
				*/
				
				vm.previousTime = vm.currentTime;
				vm.currentTime = this.currentTime();
				
				vm.$emit('timeupdate', vm.currentTime);
			});
			this.on('seeking', function() {
				//console.warn('seeking');
				if (vm.seekStart === null) {
					vm.seekStart = vm.previousTime;
				}
				
				/*
				if (vm.options.preventGoingForward) {
					console.log('Seek start: '+vm.seekStart);
					console.log('Current time: '+this.currentTime());
					//this.currentTime(2);
					
					if (vm.seekStart < this.currentTime()) {
						this.currentTime(vm.seekStart);
					}
				}
				*/
				
				/*
				var delta = this.currentTime - vm.supposedCurrentTime;
				if (Math.abs(delta) > 0.01) {
					console.log("Seeking is disabled");
					this.currentTime = vm.supposedCurrentTime;
				}
				
				if (vm.options.preventGoingForward) {
					if (this.currentTime() > vm.previousTime){
						this.currentTime(vm.previousTime);
					}
				}
				*/
				
				vm.$emit('seeking');
			});
			this.on('seeked', function() {
				//console.warn('seeked');
				//console.log('seeked from', vm.seekStart, 'to', vm.currentTime, '; delta:', vm.currentTime - vm.previousTime);
				vm.seekStart = null;
				
				/*
				if (vm.options.preventGoingForward) {
					if (this.currentTime() > vm.seekStart){
						this.currentTime(vm.seekStart);
					}
				}
				*/
				
				vm.$emit('seeked');
			});
			
			this.on('play', function() {
				  vm.$emit('played');
				  vm.ended = 0;
				vm.totalDuration = this.duration();
			  
			  vm.updateVideoInterval = setInterval(function() {
				if (vm.config.file_id) {
				  vm.saveVideoWatchedTime(vm.config.file_id, vm.currentTime);
				}
			  }, 30 * 1000);
			});
			
			this.on('pause', function() {
			  //console.log('Paused');
			  vm.$emit('paused');
			  
			  if (vm.config.file_id) {
				clearInterval(vm.updateVideoInterval);
				vm.saveVideoWatchedTime(vm.config.file_id, vm.currentTime);
			  }
			});
			
			this.on('ended', function() {
				vm.ended = 1;
				//vm.supposedCurrentTime = 0;
				
				vm.$emit('fullyWatched');
			});
			
			this.on('volumechange', function() {
				
			});
			
		});
		
		/*
		if (vm.options.sources.filter(function(el) {
			return el.type == 'audio/mp3';
		}).length) { // Se c'è almeno un file audio tra le sources
			var button = videojs.getComponent('Button');
			  var closeButton = videojs.extend(button, {
				constructor: function() {
				  button.apply(this, arguments);
				  this.controlText("Audio only");
				  this.addClass('vjs-icon-audio');
				},
				handleClick: function() {
					vm.config.show_waveform = vm.config.show_waveform ? false : true;
					if (vm.config.show_waveform) {
						vm.player.src({
							src: vm.options.sources.filter(function(el) {
								  return el.type == 'audio/mp3';
							  })[0].src, type: 'audio/mp3'
						});
						this.controls=true;
						this.muted=false;
						this.volume = 100;
						this.inactivityTimeout=0;
						vm.player.inactivityTimeout=0;
						vm.player.muted(false);
					}
					else {
						this.inactivityTimeout=3;
						vm.player.inactivityTimeout=3;
						var src = vm.options.sources.filter(function(el) {
							  return el.type != 'audio/mp3';
						  });
						  
						  if (src && src.length) {
							vm.player.src({
								src: src[0].src, type: src[0].type
							});
						}
					}
				}
			  });
			  videojs.registerComponent('closeButton', closeButton);
			  this.player.getChild('controlBar').addChild('closeButton', {});
		}
		*/
		
	},
	
	beforeDestroy() {
		if (this.updateVideoInterval) {
			clearInterval(this.updateVideoInterval);
		}
		if (this.player) {
			this.player.dispose()
		}
	}
}
</script>