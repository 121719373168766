<template>
<div>
	<div v-if="type == 'select'">
		<div v-for="(product, product_ix) in variants">
			<div v-if="product.variants.length" class="mb-3 pb-3" v-bind:class="variants.length > 1 ? 'border-bottom' : ''">
				<div class="d-flex align-items-center mb-1">
					<div class="me-2" v-if="product.cc_thumbnail_720p_url && variants.length > 1">
						<div class="bg-cover rounded" :style="'width:32px;height:32px;background-image:url('+product.cc_thumbnail_720p_url+')'"></div>
					</div>
					<div class="w-100 lh-sm">
						<div class="fw-bold" v-if="variants.length > 1">{{ product.name }}</div>
						<div class="text-secondary text-sm">Seleziona un gusto:</div>
					</div>
				</div>
				<select class="form-select" @change="(val) => setItemVariant(product, val)">
					<option value="" selected>-- Seleziona</option>
					<option v-for="variant in product.variants" :value="variant.id" v-bind:disabled="!variant.is_available">
						{{ variant.name }} <span v-if="!variant.is_available">Non disponibile</span>
					</option>
				</select>
			</div>
		</div>
	</div>
	<div v-else-if="type == 'richrow'">
		
		<div v-for="(product, product_ix) in variants">
			
			<div v-if="product.variants.length" class="mb-3 pb-3" v-bind:class="variants.length > 1 ? 'border-bottom' : ''">
				<div class="d-flex align-items-center mb-1">
					<div class="me-2" v-if="product.cc_thumbnail_720p_url && variants.length > 1">
						<div class="bg-cover rounded" :style="'width:32px;height:32px;background-image:url('+product.cc_thumbnail_720p_url+')'"></div>
					</div>
					<div class="w-100 lh-sm">
						<div class="fw-bold" v-if="variants.length > 1">{{ product.name }}</div>
						<div class="text-secondary text-sm">Seleziona un gusto:</div>
					</div>
					
					<router-link v-if="showdetailsbutton==1" :to="{
						name: 'shop.team.product.show',
						params: {
							teamSlug: $route.params.teamSlug,
							productSlug: product.slug,
						}
					}" class="btn btn-ghost text-primary rounded-pill btn-sm px-3 ms-2 fw-medium text-nowrap" @click.native="hideBSModal('modal-choose-variants')">
						vedi <i class="fas fa-fw fa-arrow-right"></i>
					</router-link>
				</div>
				<!-- scegli un gusto -->
				
				<div class="position-relative">
					<div class="row g-3 on-mobile-layout-scroll flex-row flex-nowrap justify-content-start overflow-auto pb-2 on-desktop-layout-scroll smooth-scroll" :id="uuid+'-'+product_ix+'-scrollable-area'">
						<div v-for="variant in product.variants" :key="'choose-product-variant-'+variant.id" class="card p-0 rounded-app me-2  position-relative" :style="'width:'+cardWidth+'%'" @click="setItemVariant(product, variant.id)"
						v-bind:class="{
							'border-primary border-2' : isVariantSelected(product.id, variant.id),
							'opacity-50 disabled' : !variant.is_available,
							'cursor-pointer' : variant.is_available,
						}">
							<div class="ratio ratio-1x1">
								<div class="bg-cover b5-bg-body2 rounded-top-app card-img" :style="'background-image:url('+(variant.options?.variant_flavour_thumbnail || variant.cc_thumbnail_720p_url)+')'"></div>
							</div>
							
							<div class="card-body p-2 text-center fw-medium text-sm lh-sm" style="white-space:normal">
								<div class="fw-medium">
									{{ variant.name }}
								</div>
								
								<div class="mt-1" v-if="variant.serving_amount && variant.serving_label && variant.price">
									<div class="text-sm text-secondary">{{ formatCurrency(variant.price/variant.serving_amount, variant.currency, 'auto', true) }}/{{ variant.serving_label }}</div>
									<div class="mt-1">{{ formatCurrency(variant.price, variant.currency, 'auto', true) }}</div>
								</div>
								<div v-else class="text-secondary mt-1">{{ formatCurrency(variant.price, variant.currency, 'auto', true) }}</div>
								
								<div v-if="!variant.is_available" class="fw-medium text-sm text-uppercase text-danger mt-2 w-100 text-center">Non disponibile</div>
							</div>
							
							<i v-if="isVariantSelected(product.id, variant.id)" class="position-absolute top-0 end-0 mt-2 me-2 fas fa-fw fa-circle-check text-primary fs-4"></i>
						</div>
					</div>
					
					<button type="button" v-if="canScroll(product.id, 'right')" class="btn btn-ghost bg-white text-dark rounded-pill shadow position-absolute top-50 end-0 me-2 translate-middle-y border" @click="scrollRow(product_ix, 'right')">
						<i class="fas fa-fw fa-chevron-right"></i>
					</button>
					
					<button type="button" v-if="canScroll(product.id, 'left')" class="btn btn-ghost bg-white text-dark rounded-pill shadow position-absolute top-50 start-0 ms-2 translate-middle-y border" @click="scrollRow(product_ix, 'left')">
						<i class="fas fa-fw fa-chevron-left"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	props: [
		'variants',
		'type',
		'numCardsVisibleInViewport', // numero di cards visibili contemporaneamente
		'showdetailsbutton', // mostra il pulsante "vai alla scheda prodotto"
	],
	
  data: function() {
	  var card_width = parseInt(100 / this.numCardsVisibleInViewport);
	return {
		uuid: 'product-pick-variant-flavour-multiple-'+this.makeid(12),
		cardWidth: card_width, // dimensione in pixel della larghezza della card
		selected: [],
	}
  },
  
  methods: {
	  
	  canScroll: function(pid, direction) {
		  var prod = this.getSelectedFromPid(pid);
		  
		  if (prod) {
			  if (direction == 'right') {
				  return prod.max_scrollnum > prod.scrollnum;
			  }
			  else if (direction == 'left') {
				  return prod.scrollnum;
			  }
		  }
	  },
	  
	  scrollRow: function(product_ix, direction){
		  var product = this.variants[product_ix];
		  var cur = this.getSelectedFromPid(product.id);
		  
		  if (direction == 'right') {
			  var area = document.getElementById(this.uuid+'-'+product_ix+'-scrollable-area');
				  cur.scrollnum++;
		  }
		  else if (direction == 'left') {
			  var area = document.getElementById(this.uuid+'-'+product_ix+'-scrollable-area');
				cur.scrollnum--;
		  }
		  
			if (area) {
				var scrollWidth = area.offsetWidth;
				area.scrollTo(this.getCardsNumPerViewPort()*this.numCardsVisibleInViewport*cur.scrollnum, 0);
			}
	  },
	  
	  getSelectedFromPid: function(pid) {
		  var cur = this.selected.filter(function(el) {
			  return el.id == pid;
		  });
		  
		  if (cur && cur.length) {
			  return cur[0];
		  }
	  },
	  
	setItemVariant: function(product, variant_id) {
		const vid = (variant_id && variant_id.target) ? variant_id.target.value : variant_id;
		
		if (vid) {
			var variant = product.variants.filter(function(el) {
				return el.id == vid;
			});
			
			if (variant && variant.length) { // la variante esiste
				if (variant[0] && variant[0].is_available) { // se il prodotto è disponibile all'acquisto
					var cur = this.getSelectedFromPid(product.id);
					if (cur) {
						cur.value = vid;
					}
					this.$emit('selected', product, vid);
				}
			}
		}
	},
	
	isVariantSelected: function(pid, vid) {
		var cur = this.getSelectedFromPid(pid);
		if (cur) {
			return cur.value == vid;
		}
		return false;
	},
	
	getCardsNumPerViewPort: function() {
		var product = this.variants[0];
		var area = document.getElementById(this.uuid+'-0-scrollable-area');
		
		if (area) {
			return parseInt(area.offsetWidth / this.numCardsVisibleInViewport);
		}
		
		return 0;
	}
	
  },
  
  mounted: function() {
	  const vm = this;
	  this.selected = [];
	  this.variants.forEach(function(el) {
		  vm.selected.push({
			  id: el.id,
			  value: null,
			  scrollnum: 0, // posizione attuale della scroll orizzontale,
			  max_scrollnum: (el.variants.length) ? parseInt(el.variants.length / vm.numCardsVisibleInViewport) : 0, // posizione massima della scroll (in termini di quanti click massimi fare)
		  });
	  });
  },
  
}
</script>