<template>
	<router-link :to="{ name:'wellness.index' }" class="text-decoration-none text-reset color-inherit">
		<widget-productivity-healthwater :settings="{
			design: 'card',
		}" :class="getSectionClasses()"/>
	</router-link>
</template>

<script>
export default {
	props: {
		instance: null
	},
	
	methods: {
		getSectionClasses: function() {
			var classes = ['b5-container-xl'];
			
			return classes.join(' ');
		},
	},
}
</script>