<template>
	<b5-link :href="getDestination()" class="text-decoration-none carousel-item b5-bg-body2">
	
	<div v-if="instance.processed_options.main_picture.cc_thumbnail_720p_url" class="img-fluid lazy d-block w-100 bg-cover max-vh-50" :data-src="instance.processed_options.main_picture.cc_thumbnail_720p_url" v-bind:class="getStyle() == 'rounded' ? 'rounded-app' : ''">
		<img :data-src="instance.processed_options.main_picture.cc_thumbnail_720p_url" class="lazy img-fluid d-block w-100 max-vh-50" alt="" style="visibility:hidden" />
	</div>
	
	<div class="carousel-caption start-0 end-0 bottom-0 pb-3 pb-lg-4 pt-5" v-if="getOption('show_text', true)" v-bind:class="getStyle() == 'infinity' ? 'b5-bg-gradient-body-transparent-bottom' : 'b5-bg-gradient-dark-opaque-bottom text-white'">
		<span v-if="instance.options.badge" class="badge bg-primary mb-2">
			{{ instance.options.badge }}
		</span>
		
		<h5 v-if="instance.options.title" v-bind:class="{
			'mb-0': (instance.options.excerpt)
		}">
			{{ instance.options.title }}
		</h5>
		<p v-if="instance.options.excerpt" v-bind:class="{
		}">
			{{ instance.options.excerpt }}
		</p>
	</div>
	
	<div v-if="!getOption('show_text', true) && getStyle() == 'infinity'" class="carousel-caption start-0 end-0 bottom-0 py-5 b5-bg-gradient-body-transparent-bottom"></div>
	
	</b5-link>
</template>

<script>
	export default {
		props: {
			instance: null,
			carousel: null,
		},
		
		data: function() {
			return {
				data: {},
			};
		},
		
		methods: {
			
			getOption: function(name, fallback=null) {
				return (
					this.carousel && 
					this.carousel.options.hasOwnProperty(name)
				) ? this.carousel.options[name] : fallback;
			},
			
			getStyle: function() {
				const default_style = 'rounded';
				
				return this.getOption('style', default_style);
			},
			
			
			
			
			hasProcessedData: function() {
				const vm = this;
				return (
					vm.instance.hasOwnProperty('processed_data')
					&& Array.isArray(this.instance.processed_data)
				);
			},
			
			hasDestination: function() {
				return (this.instance.options.hasOwnProperty('cta_web_url') && this.instance.options.cta_web_url !== null);
			},
			
			getMainColClasses: function() {
				var classes = ['col-12 main-col'];
				
				if (this.hasMainPicture() || this.hasMainVideo()) {
					classes.push('col-lg-7');
				}
				
				if (this.getMediaPosition()) {
					classes.push('order-2');
				}
				else {
					classes.push('order-1');
				}
				
				return classes.join(' ');
			},
			
			getDestination: function() {
				if (this.hasDestination()) {
					return this.instance.options.cta_web_url;
				}
				
				return;
			},
			
			getMediaPosition: function() {
				const vm = this;
				const defaultPosition = 'start';
				
				if (! vm.instance.options.hasOwnProperty('main_media_position')) {
					return 'start';
				}
				
				if (vm.instance.options.main_media_position == 'end') {
					return 'end';
				}
				
				return defaultPosition;
			},
			
			hasMainVideo: function() {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty('video') 
					&& this.instance.processed_options.video !== null 
					&& this.instance.processed_options.video.hasOwnProperty('media')
					&& this.instance.processed_options.video.media !== null
					&& this.getFileVersionUrl(this.instance.processed_options.video.media)
				);
			},
			
			hasMainPicture: function() {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty('main_picture') 
					&& this.instance.processed_options.main_picture !== null 
					&& this.instance.processed_options.main_picture.hasOwnProperty('media')
					&& this.instance.processed_options.main_picture.media !== null
					&& this.getFileVersionUrl(this.instance.processed_options.main_picture.media)
				);
			},
			
			hasBackgroundPicture: function() {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty('background_picture') 
					&& this.instance.processed_options.background_picture !== null 
					&& this.instance.processed_options.background_picture.hasOwnProperty('media')
					&& this.getFileVersionUrl(this.instance.processed_options.background_picture.media)
				);
			},
			
			getBodyClasses: function() {
				var classes = ['card-body p-4'];
				
				if (this.getOverlay()) {
					classes.push(this.getOverlay());
				}
				
				if (this.getBackgroundImageStyle() == 'full-image') {
					classes.push('position-absolute top-0 end-0 start-0 bottom-0 d-flex w-100 h-100 flex-column');
				}
				else {
					classes.push('d-flex w-100 h-100 flex-column justify-content-center');
				}
				
				return classes.join(' ');
			},
			
			getBackgroundImageStyle: function() {
				const vm = this;
				const defaultStyle = 'cover';
				
				if (! vm.instance.options.hasOwnProperty('background_picture_style') || ! this.hasBackgroundPicture()) {
					return defaultStyle;
				}
				
				if (vm.instance.options.background_picture_style == 'full-image') {
					return 'full-image';
				}
				
				return defaultStyle;
			},
			
			getTextColor: function() {
				const vm = this;
				
				if (! vm.instance.options.hasOwnProperty('text_color')) {
					return '';
				}
				
				if (vm.instance.options.text_color == 'dark') {
					return 'text-dark';
				}
				else if (vm.instance.options.text_color == 'light') {
					return 'text-light';
				}
				
				return '';
			},
			
			getButtonClasses: function(btn='primary') {
				const vm = this;
				var classes = [];
				
				if (btn == 'primary') {
					classes.push('btn rounded-app d-block w-100 mt-2 b5-bg-opaque');
					
					if (vm.instance.options.hasOwnProperty('text_color')) {
						if (vm.instance.options.text_color == 'dark') {
							classes.push('btn-opaque-dark-sm');
						}
						else if (vm.instance.options.text_color == 'light') {
							classes.push('btn-opaque-light');
						}
						else {
							if (this.hasBackgroundPicture()) {
								classes.push('btn-opaque');
							}
							else {
								classes.push('btn-primary');
							}
						}
					}
					else {
						if (this.hasBackgroundPicture()) {
							classes.push('btn-opaque');
						}
						else {
							classes.push('btn-primary');
						}
					}
					
				}
				else if (btn == 'secondary') {
					classes.push('btn rounded-app d-block w-100 mt-2');
					
					if (vm.instance.options.hasOwnProperty('text_color')) {
						if (vm.instance.options.text_color == 'dark') {
							classes.push('btn-outline-dark');
						}
						else if (vm.instance.options.text_color == 'light') {
							classes.push('btn-outline-light');
						}
						else {
							if (this.hasBackgroundPicture()) {
								classes.push('btn-outline');
							}
							else {
								classes.push('btn-evidence');
							}
						}
					}
					else {
						if (this.hasBackgroundPicture()) {
							classes.push('btn-outline');
						}
						else {
							classes.push('btn-evidence');
						}
					}
				}
				
				return classes.join(' ');
			},
			
			getTextAlignment: function() {
				const vm = this;
				
				if (! vm.instance.options.hasOwnProperty('text_alignment')) {
					return '';
				}
				
				if (vm.instance.options.text_alignment == 'center') {
					return 'text-center';
				}
				
				return '';
			},
			
			getClasses: function() {
				const vm = this;
				var classes = ['text-decoration-none text-reset row align-items-stretch g-0 b5-bg-body2 rounded-app'];
				
				if (this.getDestination() && this.isExternalUrl(this.getDestination())) {
					classes.push('target-blank');
				}
				
				/* for better styling... */
				if (this.hasBackgroundPicture()) {
					classes.push('with-bg-picture');
				}
				else {
					classes.push('without-bg-picture');
				}
				
				if (this.hasMainPicture()) {
					classes.push('with-main-picture');
				}
				else {
					classes.push('without-main-picture');
				}
				
				return classes.join(' ');
			},
			
			getDestinationTarget: function() {
				if (this.getDestination() && this.isExternalUrl(this.getDestination())) {
					return "_blank";
				}
				
				return "_self";
			},
			
			getOverlay: function() {
				const vm = this;
				
				if (! vm.instance.options.hasOwnProperty('background_overlay')) {
					return '';
				}
				
				if (vm.instance.options.background_overlay == 'light-sm') {
					return 'overlay overlay-light overlay-sm';
				}
				else if (vm.instance.options.background_overlay == 'light-md') {
					return 'overlay overlay-light overlay-md';
				}
				else if (vm.instance.options.background_overlay == 'light-xl') {
					return 'overlay overlay-light overlay-xl';
				}
				else if (vm.instance.options.background_overlay == 'dark-sm') {
					return 'overlay overlay-dark overlay-sm';
				}
				else if (vm.instance.options.background_overlay == 'dark-md') {
					return 'overlay overlay-dark overlay-md';
				}
				else if (vm.instance.options.background_overlay == 'dark-xl') {
					return 'overlay overlay-dark overlay-xl';
				}
				
				return;
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
			
			this.$nextTick(function() {
				vm.loadLazy();
			});
		}
	}
</script>