<template>
	<div v-if="instance.childrens && instance.childrens.length" v-bind:class="{
		'b5-bg-gradient-body-transparent-bottom': (getStyle() == 'infinity'),
		'b5-bg-body2': (getStyle() != 'rounded'),
		'elements--post-category--post-collection-carousel': true,
	}">
		<div v-bind:class="{
			'b5-container-xl': (getStyle() != 'infinity'),
			'px-0': (getStyle() != 'rounded')
		}">
			<div :id="carousel_id" class="carousel slide max-vh-50 carousel-scheme-auto" data-bs-ride="carousel">
				<div class="carousel-indicators">
					<button v-for="(item, index) in instance.childrens" :key="item.id" v-bind:class="index == 0 ? 'active' : ''" v-bind:aria-current="index" type="button" :data-bs-target="'#' + carousel_id" :data-bs-slide-to="index" :aria-label="'Slide ' + index"></button>
				</div>
				<div class="carousel-inner">
					
					<elements-page-carousel-slide v-for="(item, index) in instance.childrens" v-bind:class="index == 0 ? 'active' : ''" :key="item.id" :instance="item" :carousel="data"/>
					
				</div>
				<button class="carousel-control-prev" type="button" :data-bs-target="'#' + carousel_id" data-bs-slide="prev">
					<span class="carousel-control-prev-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Previous</span>
				</button>
				<button class="carousel-control-next" type="button" :data-bs-target="'#' + carousel_id" data-bs-slide="next">
					<span class="carousel-control-next-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Next</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				carousel_id: 'carousel-'+this.makeid(6),
				data: {}
			};
		},
		
		methods: {
			getThumbnailOfItem: function(item) {
				if (item.hasOwnProperty('thumbnail') && item.thumbnail) {
					return this.getFileVersionUrl(item.thumbnail, {
						types: [ 'image_1920', 'image_1080', 'image_720', 'original' ],
					});
				}
				else if (item.hasOwnProperty('video') && item.video) {
					return this.getFileVersionUrl(item.video, {
						types: [ 'image_1920', 'image_1080', 'image_720', 'original' ],
					});
				}
			},
			
			moment: function(date=null) {
				if (date) {
					return (date == 'as_object') ? moment : moment(date);
				}
	
				return moment();
			},
			
			getOption: function(name, fallback=null) {
				return (
					this.data.options.hasOwnProperty(name)
				) ? this.data.options[name] : fallback;
			},
			
			getStyle: function() {
				const default_style = 'rounded';
				
				return this.getOption('style', default_style);
			},
			
			hasProcessedData: function() {
				const vm = this;
				return (
					vm.instance.hasOwnProperty('processed_data')
					&& Array.isArray(this.instance.processed_data)
				);
			},
			
			hasCtaText: function() {
				return (
					this.instance.options.hasOwnProperty('cta_text')
					&& this.instance.options.cta_text !== null
					&& this.instance.options.cta_text.length
				);
			},
			
			hasCtaAction() {
				return (
					this.instance.options.hasOwnProperty('cta_web_url')
					&& this.instance.options.cta_web_url !== null
				);
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
			
			this.$nextTick(function() {
				vm.loadLazy();
			});
		}
	}
</script>