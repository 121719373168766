<template>
	<div :class="'elements--post-category--banners-collection '+getContainerClasses()" v-show="instance.hasOwnProperty('childrens') && Array.isArray(instance.childrens)">
		<div class="mb-2 heading">
			<div class="d-flex justify-content-between align-items-top">
				<h4 v-if="instance.options.title" class="mb-0 fw-bold text-opposite">
					{{ instance.options.title }}
				</h4>
			
				<b5-link v-if="hasCtaAction() && hasCtaText()" :href="instance.options.cta_web_url" class="btn btn-ghost text-primary text-nowrap text-decoration-none p-0">
					{{ instance.options.cta_text }}
					
					<i class="b5-fa-icon fa-chevron-right"></i>
				</b5-link>
			</div>
			
			<p v-if="instance.options.excerpt" class="mb-0 text-secondary">
				{{ instance.options.excerpt }}
			</p>
		</div>
		
		
		<div :class="getRowClasses()" v-if="instance.hasOwnProperty('childrens') && Array.isArray(instance.childrens)">
			<div :class="getColumnClasses()" v-for="(item, index) in instance.childrens" :key="item.id">
				<dynamic-element-render group="post.category" :instance="item"></dynamic-element-render>
			</div>
		</div>
	</div>
</template>

<style scoped>
.elements--post-category--banners-collection .b5-container-xl {
	padding-left: 0!important;
	padding-right: 0!important;
}
</style>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
			
			
			getContainerClasses: function() {
				var classes = [];
				
				if (! this.instance.options.hasOwnProperty('container_size')) {
					classes.push('b5-container-lg');
				}
				else {
					if (this.instance.options.container_size == 'small') {
						classes.push('b5-container-sm');	
					}
					else if (this.instance.options.container_size == 'default') {
						classes.push('b5-container-lg');	
					}
					else if (this.instance.options.container_size == 'large') {
						classes.push('b5-container-xl');	
					}
					else if (this.instance.options.container_size == 'xl') {
						classes.push('b5-container-xxl');	
					}
					else if (this.instance.options.container_size == 'full-width') {
						classes.push('w-100');	
					}
				}
				
				return classes.join(' ');
			},
			
			getRowClasses: function() {
				var classes = ['row position-relative g-2'];
				const design = this.getDesign();
				const size_mobile = this.getSize('mobile');
				const size_desktop = this.getSize('desktop');
				const layout_mobile = this.getLayout('mobile');
				const layout_desktop = this.getLayout('desktop');
				
				if (layout_mobile == 'scroll') {
					classes.push('on-mobile-layout-scroll flex-row flex-nowrap justify-content-start overflow-auto pb-3');
				}
				else if (layout_mobile == 'full_list') {
					classes.push('on-mobile-layout-full-list');
				}
				
				if (layout_desktop == 'full_list') {
					classes.push('on-desktop-layout-full-list');
				}
				else if (layout_desktop == 'scroll') {
					classes.push('on-desktop-layout-scroll flex-lg-row flex-lg-nowrap justify-content-lg-start overflow-auto pb-lg-3');
				}
				
				
				
				// if (layout == 'scroll') {
				// 	classes.push('magnetic-scroll-on-mobile');
				// 	
				// 	if (design == 'link' || design == 'pills' || design == 'pills-simple') {
				// 		classes.push('flex-row g-2 flex-nowrap justify-content-start overflow-auto');
				// 	}
				// 	else {
				// 		classes.push('flex-row g-2 flex-nowrap justify-content-start overflow-auto');
				// 	}
				// }
				// else if (layout == 'full_list') {
				// 	classes.push('align-items-stretch g-2');
				// }
				
				return classes.join(' ');
			},
			
			getColumnClasses: function() {
				var classes = ['magnetic-column position-relative'];
				const design = this.getDesign();
				const size_mobile = this.getSize('mobile');
				const size_desktop = this.getSize('desktop');
				const layout_mobile = this.getLayout('mobile');
				const layout_desktop = this.getLayout('desktop');
				
				if (layout_mobile == 'scroll') {
					if (size_mobile == 'large') {
						classes.push('b5-col-2');
					}
					else if (size_mobile == 'default') {
						classes.push('b5-col-2 b5-col-md-3');
					}
					else if (size_mobile == 'small') {
						classes.push('b5-col-3 b5-col-md-4-alt');
					}
				}
				else if (layout_mobile == 'full_list') {
					if (size_mobile == 'large') {
						classes.push('col-12');
					}
					else if (size_mobile == 'default') {
						classes.push('col-12');
					}
					else if (size_mobile == 'small') {
						classes.push('col-6 col-md-6');
					}
				}
				
				if (layout_desktop == 'scroll') {
					if (size_mobile == 'large') {
						classes.push('b5-col-lg-2 b5-col-xl-3');
					}
					else if (size_mobile == 'default') {
						classes.push('b5-col-lg-3 b5-col-xl-4-alt');
					}
					else if (size_mobile == 'small') {
						classes.push('b5-col-lg-4-alt b5-col-xl-5');
					}
				}
				else if (layout_desktop == 'full_list') {
					if (size_desktop == 'default') {
						classes.push('col-lg-6');
					}
					else if (size_desktop == 'small') {
						classes.push('col-lg-4 col-xl-3');
					}
				}
				
				
				return classes.join(' ');
			},
			
			getDesign: function() {
				if (this.instance.options.hasOwnProperty('design') && this.instance.options.design !== null) {
					return this.instance.options.design;
				}
				
				return 'pills_simple';
			},
			
			getSize: function(device='mobile') {
				if (this.instance.options.hasOwnProperty('size_'+device) && this.instance.options['size_'+device] !== null) {
					return this.instance.options['size_'+device];
				}
				
				return 'default';
			},
			
			getLayout: function(device="mobile") {
				if (this.instance.options.hasOwnProperty('layout_'+device) && this.instance.options['layout_'+device] !== null) {
					return this.instance.options['layout_'+device];
				}
				
				return 'scroll';
			},
			
			hasProcessedData: function() {
				const vm = this;
				return (
					vm.instance.hasOwnProperty('processed_data')
					&& Array.isArray(this.instance.processed_data)
				);
			},
			
			hasCtaText: function() {
				return (
					this.instance.options.hasOwnProperty('cta_text')
					&& this.instance.options.cta_text !== null
					&& this.instance.options.cta_text.length
				);
			},
			
			hasCtaAction() {
				return (
					this.instance.options.hasOwnProperty('cta_web_url')
					&& this.instance.options.cta_web_url !== null
				);
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
			this.$nextTick(function() {
				vm.loadLazy();
			});
		}
	}
</script>