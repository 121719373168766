<template>
	<div class="b5-container-lg py-2 py-lg-3 pt-5 pt-lg-5">
		<div class="d-flex w-100 justify-content-center align-items-center flex-column">
			<div class="b5-bg-body4 rounded-circle bg-cover mb-2" :style="'width:84px;height:84px;background-image: url('+$globalData.coachdata.avatar_url+');'"></div>
			<div class="fs-5">Ciao 👋 sono</div>
			<div class="d-lg-none">
				<div class="fs-1 fw-bold text-black">{{ $globalData.coachdata.firstname }}</div>
			</div>
			<div class="d-none d-lg-block lh-sm">
				<div class="fs-2 fw-bold text-black">{{ $globalData.coachdata.fullname }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
		}
	}
</script>