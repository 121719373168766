<template>
	<div class="elements--post-serie--countdown">
		<section class="countdown-timer-widget-card shadow-sm rounded d-block">
				<img v-if="hasMainPicture()" v-bind:data-src="(hasMainPicture()) ? instance.processed_options.main_picture.media.url : null" alt="" class="lazy img-fluid w-100 rounded-top" />
						
		<section class="w-100 lazy mb-3 bg-cover d-block" v-bind:data-src="(hasBackgroundPicture()) ? instance.processed_options.background_picture.media.url : null">
			<div class="d-flex flex-column justify-content-center align-items-center w-100" v-bind:class="(hasBackgroundPicture()) ? 'overlay overlay-dark text-white' : 'null'">
			<div class="container-xl pt-5 mb-3">
				<h4 class="mb-0" v-if="instance.options.title">
					{{ instance.options.title }}
				</h4>
				
				<p class="mb-0" v-if="instance.options.excerpt">
					{{ instance.options.excerpt }}
				</p>
				
				<div class="countdown-area" v-if="countdown_started">
					<countdown :to="getCountdownDateTime()" :settings="{
						color_scheme: getColorScheme(),
					}"/>
				</div>
				<div class="on-expired-area" style="display:none">
					<alert :settings="{
						type: 'warning text-center'
					}">Il conto alla rovescia è terminato</alert>
				</div>
				
				<div v-if="hasCta() && isExternalUrl(instance.options.cta_web_url)">
					<a :href="instance.options.cta_web_url" target="_blank" class="target-blank btn btn-secondary d-block w-100">
						{{ instance.processed_options.cta_text.value }}
					</a>
				</div>
				<div v-else-if="hasCta()">
					<router-link :to="processLocalUrl(instance.options.cta_web_url)" class="btn btn-secondary d-block w-100">
						{{ instance.processed_options.cta_text.value }}
					</router-link>
				</div>
			</div>
			</div>
		</section>
		</section>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		data: function() {
			return {
				countdown_started: false,
			};
		},
		
		methods: {
			getColorScheme: function() {
				if (this.instance.options.color_scheme == 'dark') {
					return 'bg-dark text-white';
				}
				else {
					return 'bg-white text-dark';
				}
			},
			
			isExternalUrl: function(url) {
				return false;
			},
			
			hasMainPicture: function() {
				var instance = this.instance;
				return (
					instance.processed_options.main_picture 
					&& instance.processed_options.main_picture.media 
					&& instance.processed_options.main_picture.media.url
				);
			},
			
			hasBackgroundPicture: function() {
				var instance = this.instance;
				return (
					instance.processed_options.background_picture 
					&& instance.processed_options.background_picture.media 
					&& instance.processed_options.background_picture.media.url
				);
			},
			
			getCountdownDateTime: function() {
				return this.instance.processed_options.to_date.value +' '+this.instance.processed_options.to_time.value +':00';
			},
			
			hasCta: function() {
				return (this.instance.options.cta_text !== null && this.instance.options.cta_web_url !== null);
			},
			
			processLocalUrl: function(url) {
				return this.removeVueDomain(url);
			},
			
			moment: function(date=null) {
				if (date) {
					return (date == 'as_object') ? moment : moment(date);
				}
	
				return moment();
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
			this.countdown_started = true;
			
			this.$nextTick(function() {
				vm.loadLazy();
			});
		},
	}
</script>