var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elements--post---video b5-container-lg px-0"},[(_vm.data.processed_options.hasOwnProperty('video') && _vm.data.processed_options.video && _vm.data.processed_options.video.hasOwnProperty('media') && _vm.getFileVersionUrl(_vm.data.processed_options.video.media))?_c('video-player',{staticClass:"rounded-app",attrs:{"video_options":{
	  autoplay: false,
	  muted: false,
	  controls: true,
	  poster: _vm.getFileVersionUrl(_vm.data.processed_options.video.media),
	  sources: _vm.getVideoSources(_vm.data.processed_options.video.media),
  },"settings":{
	  file_id: _vm.data.processed_options.video.media.id,
	  title: null,
	  excerpt: null,
	  page_url: null,
	  start_from: null,
	  aspect_ratio: _vm.data.processed_options.video.media.hasOwnProperty('options') && _vm.data.processed_options.video.media.options && _vm.data.processed_options.video.media.options.hasOwnProperty('aspect_ratio') ? _vm.data.processed_options.video.media.options.aspect_ratio : null,
	  orientation: _vm.data.processed_options.video.media.hasOwnProperty('options') && _vm.data.processed_options.video.media.options && _vm.data.processed_options.video.media.options.hasOwnProperty('orientation') ? _vm.data.processed_options.video.media.options.orientation : null,
  }}}):_vm._e(),_vm._v(" "),(_vm.data.options.excerpt)?_c('div',{staticClass:"b5-bg-body py-2 px-3 text-center rounded-bottom-app",domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.urlify(_vm.encodeHTML(_vm.data.options.excerpt))))}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }