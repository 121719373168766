<template>
	<a v-if="hasDestination() && (isExternalUrl(getDestination()) || isCustomProtocol(getDestination()))" :href="getDestination()" :target="getTarget()" @click="clicked('external')">
		<slot></slot>
	</a>
	<router-link v-else-if="hasDestination() && !isExternalUrl(getDestination())" :to="buildRouterLink()" @click="clicked('internal')">
		<slot></slot>
	</router-link>
	<span v-else-if="!hasDestination()" @click="clicked('none')">
		<slot></slot>
	</span>
</template>

<script>
export default {
props: {
	href: null,
},

methods: {
	
	clicked: function(type) {
		this.$emit('clicked', type);
	},
	
	hasDestination: function() {
		return (this.href !== null && this.href !== '' && this.href);
	},
	
	buildRouterLink: function() {
		try {
			/*
				Questo permette di aggiungere automaticamente all'url eventuali parametri presenti in ?Querystring
			*/
			var url = new URL(this.href);
			
			var search = url.search.substring(1);
			var query = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
			
			return {
				path: url.pathname,
				query: query
			};
			
			return url.pathname;
		} catch (_) {
			return this.href;
		}
	},
	
	getDestination: function() {
		if (this.hasDestination()) {
			
			if (! this.isExternalUrl(this.href) && ! this.isCustomProtocol(this.href)) {
				try {
					var url = new URL(this.href);
					return url.pathname;
				} catch (_) {
					return this.href;
				}
			}
			
			return this.href;
		}
		
		return;
	},
	
	getTarget: function() {
		if (this.getDestination() && this.isExternalUrl(this.getDestination())) {
			return "_blank";
		}
		
		return "_self";
	},
	
	isCustomProtocol: function(destination) {
		if (typeof this.href === 'object') {
			return false;
		}
		return (
			destination.startsWith('tel:')
			|| destination.startsWith('sms:')
			|| destination.startsWith('facetime:')
			|| destination.startsWith('mailto:')
		);
	},
},

created: function() {
	
},

mounted() {
	
}
}
</script>