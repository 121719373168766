<template>
	<button type="button" class="btn btn-primary rounded-pill w-100 d-block" @click="addToCart(instance.processed_data)">
		Aggiungi al carrello (non va ancora)
	</button>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			addToCart: function(item) {
				// if (! this.canAddToCart()) {
				// 	return;
				// }
				
				const vm = this;
				var relatedItems = [];
				
				if (item && item.hasOwnProperty('variants') && Array.isArray(item.variants)) {
					item.variants.forEach(function(el) { // aggiungo eventuali varianti
						relatedItems.push(el);
					});
				}
				
				if (item && item.type == 'combo' && item.hasOwnProperty('combo_items') && Array.isArray(item.combo_items)) { // aggiungi un prodotto che contiene una combo di altri prodotti
					//var comboItems = item.combo_items;
					
					item.combo_items.forEach(function(el) {
						// var product = item.combo_items.filter(function(z) {
						// 	return z.id == el;
						// });
						// if (product) { // se il prodotto esiste
							//product = product[0];
							//var variants = (product && product.hasOwnProperty('variants')) ? product.variants : [];
							
							if (el.variants && el.variants.length) { // prodotto singolo che ha delle varianti
								//product.variants = variants;
								
								relatedItems.push(el);
								
								el.variants.forEach(function(z) {
									relatedItems.push(z);
								})
							}
							else { // prodotto singolo senza varianti
								relatedItems.push(el);
							}
						//}
					});
				}
				
				this.$parent.universalAddToCart(item, relatedItems);
				//this.added_to_cart=true;
				this.fixSocialProofSize();
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			
		}
	}
</script>