<template>
	<div class="elements--post-category--banner b5-container-xl position-relative">
		
		<b5-link :href="getDestination()" class="text-decoration-none text-reset b5-transitionable b5-hover-scale stretched-link d-block banner-container">
			<div :class="getClasses()" :style="getStyle()">
				<div :class="getMediaContainerClasses()" :style="getMediaContainerStyle()">
					<img v-if="hasMainPicture()" :src="getFileVersionUrl(instance.processed_options.main_picture.media)" class="img-fluid banner-picture h-100z" alt="" />
					<!-- <div class="img-bg lazy bg-cover position-absolute top-0 start-0 end-0 bottom-0 w-100 h-100" :data-src="getFileVersionUrl(instance.processed_options.main_picture.media)"></div> -->
					
					<video-player v-if="hasMainVideo()" class="vjsplayer" :video_options="{
					  autoplay: true,
					  controls: false,
					  controlBar: false,
					  track_watching: false,
					  muted: true,
					  loop: true,
					  poster: hasMainPicture() ? getFileVersionUrl(instance.processed_options.main_picture.media) : getFileVersionUrl(instance.processed_options.video.media, {
						types: ['image_720'],
					}),
					  sources: [
						{
							src: getFileVersionUrl(instance.processed_options.video.media, {
								types: ['mp4_360p_128kbps', 'original'],
							}),
							type: 'video/mp4'
						},
						{
							src: getFileVersionUrl(instance.processed_options.video.media, {
								types: ['mp4_480p_128kbps', 'original'],
							}),
							type: 'video/mp4'
						},
						{
							src: getFileVersionUrl(instance.processed_options.video.media, {
								types: ['mp4_720p_128kbps', 'original'],
							}),
							type: 'video/mp4'
						}
					  ]
				  }" :settings="{
					  file_id: instance.processed_options.video.media.id,
				  }" />
				</div>
				
				<div :class="getMainColClasses()" :style="getMainColStyle()">
					<div class="h-100">
						<img v-if="hasBackgroundPicture() && getBackgroundImageStyle() == 'full-image'" :src="getFileVersionUrl(instance.processed_options.background_picture.media)" class="img-fluid w-100 img-base" alt="" />
						
						<div :class="getBodyClasses()">
							<p v-if="instance.options.slogan" :class="'mb-0 fw-medium ' + getTextColor() + ' ' + getTextAlignment()" v-html="nl2br(urlify(encodeHTML(instance.options.slogan)))"></p>
							
							<h3 v-if="instance.options.title" :class="'fw-bold mb-0 ' + getTextColor() + ' ' + getTextAlignment()" v-html="nl2br(urlify(encodeHTML(instance.options.title)))"></h3>
							
							<p v-if="instance.options.excerpt" :class="'mb-0 ' + getTextColor() + ' ' + getTextAlignment()" v-html="nl2br(urlify(encodeHTML(instance.options.excerpt)))"></p>
							
							<div class="mt-auto pt-3" v-if="instance.options.subtext || instance.options.cta_text || instance.options.cta_secondary_text">
								<p v-if="instance.options.subtext" :class="'mb-0 fw-bold text-sm ' + getTextColor() + ' ' + getTextAlignment()" v-html="nl2br(urlify(encodeHTML(instance.options.subtext)))"></p>
								
								<button type="button" v-if="instance.options.cta_text" :class="getButtonClasses('primary')">
									{{ instance.options.cta_text }}
								</button>
								
								<button type="button" v-if="instance.options.cta_secondary_text" :class="getButtonClasses('secondary')">
									{{ instance.options.cta_secondary_text }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b5-link>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
			getStyle: function() {
				var css = '';
				
				if (this.hasBgColor()) {
					css+='background-color:'+this.hasBgColor()+';';
				}
				
				return css;
			},
			
			getMediaContainerStyle: function() {
				var css = '';
				
				if (this.hasMainPicture()) {
					css+='background-image:url('+this.getFileVersionUrl(this.instance.processed_options.main_picture.media)+');';
				}
				
				return css;
			},
			
			getMediaContainerClasses: function() {
				var classes = ['media-container'];
				let aspect_ratio = this.instance.options.hasOwnProperty('aspect_ratio') ? this.instance.options.aspect_ratio : 'auto';
				
				if (this.hasMainVideo()) {
					classes.push('with-video');
				}
				else if (this.hasMainPicture()) {
					classes.push('with-picture');
				}
				
				if (aspect_ratio != 'auto' && (this.hasMainVideo() || this.hasMainPicture())) {
					classes.push('aspect-ratio-forced bg-cover ratio ratio-'+aspect_ratio);
				}
				
				if (this.getMediaPosition() == 'start') {
					classes.push('order-1');
				}
				else {
					classes.push('order-2');
				}
				
				return classes.join(' ');
			},
			
			hasProcessedData: function() {
				const vm = this;
				return (
					vm.instance.hasOwnProperty('processed_data')
					&& Array.isArray(this.instance.processed_data)
				);
			},
			
			hasDestination: function() {
				return (this.instance.options.hasOwnProperty('cta_web_url') && this.instance.options.cta_web_url !== null);
			},
			
			getMainColStyle: function() {
				var css = '';
				
				if (this.hasBackgroundPicture()) {
					css+='background-image:url('+this.getFileVersionUrl(this.instance.processed_options.background_picture.media)+');';
				}
				
				return css;
			},
			
			getMainColClasses: function() {
				var classes = ['main-container bg-cover'];
				let aspect_ratio = this.instance.options.hasOwnProperty('aspect_ratio') ? this.instance.options.aspect_ratio : 'auto';
				
				if (aspect_ratio != 'auto' && !(this.hasMainVideo() || this.hasMainPicture())) {
					classes.push('aspect-ratio-forced ratio ratio-'+aspect_ratio);
				}
				
				if (this.getMediaPosition()) {
					classes.push('order-2');
				}
				else {
					classes.push('order-1');
				}
				
				return classes.join(' ');
			},
			
			getDestination: function() {
				if (this.hasDestination()) {
					return this.instance.options.cta_web_url;
				}
				
				return;
			},
			
			getMediaPosition: function() {
				const vm = this;
				const defaultPosition = 'start';
				
				if (! vm.instance.options.hasOwnProperty('main_media_position')) {
					return 'start';
				}
				
				if (vm.instance.options.main_media_position == 'end') {
					return 'end';
				}
				
				return defaultPosition;
			},
			
			hasMainVideo: function() {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty('video') 
					&& this.instance.processed_options.video !== null 
					&& this.instance.processed_options.video.hasOwnProperty('media')
					&& this.instance.processed_options.video.media !== null
					&& this.getFileVersionUrl(this.instance.processed_options.video.media)
				);
			},
			
			hasBgColor: function() {
				return this.instance?.options?.backgroundcolor;
			},
			
			hasMainPicture: function() {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty('main_picture') 
					&& this.instance.processed_options.main_picture !== null 
					&& this.instance.processed_options.main_picture.hasOwnProperty('media')
					&& this.instance.processed_options.main_picture.media !== null
					&& this.getFileVersionUrl(this.instance.processed_options.main_picture.media)
				);
			},
			
			hasBackgroundPicture: function() {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty('background_picture') 
					&& this.instance.processed_options.background_picture !== null 
					&& this.instance.processed_options.background_picture.hasOwnProperty('media')
					&& this.getFileVersionUrl(this.instance.processed_options.background_picture.media)
				);
			},
			
			getBodyClasses: function() {
				var classes = ['card-body p-4'];
				
				if (this.getOverlay()) {
					classes.push(this.getOverlay());
				}
				
				if (this.getBackgroundImageStyle() == 'full-image') {
					classes.push('position-absolute top-0 end-0 start-0 bottom-0 d-flex w-100 h-100 flex-column');
				}
				else {
					classes.push('d-flex w-100 h-100 flex-column justify-content-center');
				}
				
				return classes.join(' ');
			},
			
			getBackgroundImageStyle: function() {
				const vm = this;
				const defaultStyle = 'cover';
				
				if (! vm.instance.options.hasOwnProperty('background_picture_style') || ! this.hasBackgroundPicture()) {
					return defaultStyle;
				}
				
				if (vm.instance.options.background_picture_style == 'full-image') {
					return 'full-image';
				}
				
				return defaultStyle;
			},
			
			getTextColor: function() {
				const vm = this;
				
				if (! vm.instance.options.hasOwnProperty('text_color')) {
					return '';
				}
				
				if (vm.instance.options.text_color == 'dark') {
					return 'text-dark';
				}
				else if (vm.instance.options.text_color == 'light') {
					return 'text-light';
				}
				
				return '';
			},
			
			getButtonClasses: function(btn='primary') {
				const vm = this;
				var classes = [];
				
				if (btn == 'primary') {
					classes.push('btn rounded-app d-block w-100 mt-2 b5-bg-opaque');
					
					if (vm.instance.options.hasOwnProperty('text_color')) {
						if (vm.instance.options.text_color == 'dark') {
							classes.push('btn-opaque-dark-sm');
						}
						else if (vm.instance.options.text_color == 'light') {
							classes.push('btn-opaque-light');
						}
						else {
							if (this.hasBackgroundPicture()) {
								classes.push('btn-opaque');
							}
							else {
								classes.push('btn-primary');
							}
						}
					}
					else {
						if (this.hasBackgroundPicture()) {
							classes.push('btn-opaque');
						}
						else {
							classes.push('btn-primary');
						}
					}
					
				}
				else if (btn == 'secondary') {
					classes.push('btn rounded-app d-block w-100 mt-2');
					
					if (vm.instance.options.hasOwnProperty('text_color')) {
						if (vm.instance.options.text_color == 'dark') {
							classes.push('btn-outline-dark');
						}
						else if (vm.instance.options.text_color == 'light') {
							classes.push('btn-outline-light');
						}
						else {
							if (this.hasBackgroundPicture()) {
								classes.push('btn-outline');
							}
							else {
								classes.push('btn-evidence');
							}
						}
					}
					else {
						if (this.hasBackgroundPicture()) {
							classes.push('btn-outline');
						}
						else {
							classes.push('btn-evidence');
						}
					}
				}
				
				return classes.join(' ');
			},
			
			getTextAlignment: function() {
				const vm = this;
				
				if (! vm.instance.options.hasOwnProperty('text_alignment')) {
					return '';
				}
				
				if (vm.instance.options.text_alignment == 'center') {
					return 'text-center';
				}
				
				return '';
			},
			
			getClasses: function() {
				const vm = this;
				var classes = ['banner-content text-decoration-none text-reset row align-items-stretch g-0 b5-bg-body2'];
				
				if (this.instance.options.show_mobile_mode) {
					classes.push('force-display-mobile');
				}
				
				if (this.getDestination() && this.isExternalUrl(this.getDestination())) {
					classes.push('target-blank');
				}
				
				/* for better styling... */
				if (this.hasBackgroundPicture()) {
					classes.push('with-bg-picture');
				}
				else {
					classes.push('without-bg-picture');
				}
				
				if (this.hasMainPicture()) {
					classes.push('with-main-picture');
				}
				else {
					classes.push('without-main-picture');
				}
				
				return classes.join(' ');
			},
			
			getDestinationTarget: function() {
				if (this.getDestination() && this.isExternalUrl(this.getDestination())) {
					return "_blank";
				}
				
				return "_self";
			},
			
			getOverlay: function() {
				const vm = this;
				
				if (! vm.instance.options.hasOwnProperty('background_overlay')) {
					return '';
				}
				
				if (vm.instance.options.background_overlay == 'light-sm') {
					return 'overlay overlay-light overlay-sm';
				}
				else if (vm.instance.options.background_overlay == 'light-md') {
					return 'overlay overlay-light overlay-md';
				}
				else if (vm.instance.options.background_overlay == 'light-xl') {
					return 'overlay overlay-light overlay-xl';
				}
				else if (vm.instance.options.background_overlay == 'dark-sm') {
					return 'overlay overlay-dark overlay-sm';
				}
				else if (vm.instance.options.background_overlay == 'dark-md') {
					return 'overlay overlay-dark overlay-md';
				}
				else if (vm.instance.options.background_overlay == 'dark-xl') {
					return 'overlay overlay-dark overlay-xl';
				}
				
				return;
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
		}
	}
</script>