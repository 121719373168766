require('./bootstrap');

window.Vue = require('vue').default;
import * as b5AppConfig from './config.js';
window.bundle_version = b5AppConfig.b5_bundle_version;

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}
Vue.mixin(require('./trans'));

import router from './router';

const components = require.context('./components/', true, /\.vue$/i)
components.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], components(key).default)) // Sync load

const elementsWebsitePage = require.context('./elements/WebsitePage/', true, /\.vue$/i)
elementsWebsitePage.keys().map(key => Vue.component('elementsWebsitePage'+key.split('/').pop().split('.')[0], elementsWebsitePage(key).default))

const elementsEvent = require.context('./elements/Event/', true, /\.vue$/i)
elementsEvent.keys().map(key => Vue.component('elementsEvent'+key.split('/').pop().split('.')[0], elementsEvent(key).default))

const layoutsHeaders = require.context('./layouts/headers/', true, /\.vue$/i)
layoutsHeaders.keys().map(key => Vue.component('layoutsHeaders'+key.split('/').pop().split('.')[0], layoutsHeaders(key).default))

const layoutsFooters = require.context('./layouts/footers/', true, /\.vue$/i)
layoutsFooters.keys().map(key => Vue.component('layoutsFooters'+key.split('/').pop().split('.')[0], layoutsFooters(key).default))

import mitt from 'mitt';
const emitter = mitt();
Vue.prototype.$emitter = emitter;
Vue.prototype.$globalData = Vue.observable({
  
  hlfpopup: {
    visible: 0,
  },
  
  header: {
    cname: null,
    visible: 1,
    cart: {
      visible: 1,
    },
  },
  
  fsnavigation: {
    visible: 0,
  },
  
  social_proof: {
    enabled: 0, // indica se il website ha la feature attiva
    visible: 1, // indica se nella pagina attuale è visibile o meno
    current_ix: null,
    items: [],
    bottom_distance: 0,
    loop: 1,
    card_visible: 0,
  },
  
  livechat: {
    loading: 0,
    enabled: 0,
    visible: 1,
    expanded: 0,
    bottom_distance: 0,
    unread_count: 0,
    type: 'text',
    driver: null,
    // phonenumber: null,
    // phonenumber_prefix:null,
    username:null,
    messages:[],
    actions: [],
  },
  
  cart: {
    cart_key: null,
    items: [],
    total_items: 0,
    bottom_bar_visible: 1,
  },
  
  checkout: {
    estimated_shipping_costs: 0,
    shipping_costs: 0,
    subtotal: 0,
    total: 0,
  },
  
  footer: {
    cname: null,
    visible: 1,
    menus: {
      visible: 1,
    },
    extraBottomSpaceOnMobile: 0,
  },
  
  
  teamdata: {
    id: null,
    pid: null,
    name: null,
    logo_url: null,
    appicon_url: null,
    app_applestore_id: null,
    app_applestore_url: null,
    app_android_id: null,
    app_android_url: null,
    webapp_url: null,
    website_url: null,
    
    newsletter: {
      is_available: 0,
    },
  },
  
  coachdata: {
    id: null,
    pid: null,
    avatar_url: null,
    firstname: null,
    fullname: null,
    lastname: null,
    
    // bio: null,
    // mystory: null,
    // social: [],
    address: {
      line1: null,
      line2: null,
      city: null,
      province: null,
      country: null,
      countryname: null,
      postalcode: null,
    },
    contacts: {
      phonenumber: null,
      phonenumber_prefix: null,
    },
  },
  
  websitedata: {
    domain: null,
    fulldomain: null,
    homepage_url: null,
    metas:null,
    shop: {
      current_country: {
        code: null,
        icon: null,
        name: null,
        currency: null,
        language: null,
        countrycode: null,
        billing_countries_restriction: [],
        shipping_countries_restriction: [],
      },
      shareablecart: {
        enabled: 0,
      },
      available_countries: [],
    },
    primarynav: {
      items: [],
    },
    footernav: {
      items: [],
    },
    
    apikeys: {
      googlemaps: null,
      paypalclientid: null,
      stripeclientid: null,
    },
  },
});

import App from './layouts/App.vue';
const app = new Vue({
   router,
   el: '#app',
   
   render: h => h(App, {
	  props: {
		 
	  }
   }),
   
   data: {
	   
   },
   
   methods: {
	   
   },
   
   created() {
// Vue.prototype.$globalData.websitedata = {...Vue.prototype.$globalData.websitedata, ...JSON.parse(atob(window.websitedata))};
// Vue.prototype.$globalData.coachdata = {...Vue.prototype.$globalData.coachdata, ...JSON.parse(atob(window.coachdata))};
// 
// var livechatsettings = JSON.parse(atob(window.livechatsettings));
// Vue.prototype.$globalData.livechat.phonenumber = livechatsettings.phonenumber;
// Vue.prototype.$globalData.livechat.phonenumber_prefix = livechatsettings.phonenumber_prefix;
// Vue.prototype.$globalData.livechat.driver = livechatsettings.driver;
// Vue.prototype.$globalData.livechat.enabled = livechatsettings.driver && livechatsettings.phonenumber && livechatsettings.phonenumber_prefix && livechatsettings.enabled == 1;
   },
   
   destroyed() {
	   
   }
   
});