<template>
	<div class="b5-container-sm">
		<h6 class="text-sm mb-1">{{ __('widgets.daily_quote') }}</h6>
		
		<div v-if="!is_loading" class="spoilersecret" @click="spoilerShown=!spoilerShown" v-bind:class="spoilerShown ? 'show' : ''">
			<div class="preview">
				<span class="letter fs-3" v-for="i in data.message.length" :style="'--i:'+( Math.floor(Math.random() * 5) )">
					{{ data.message[i-1] }}
				</span>
			</div>
			<div class="final">
				<p class="mb-1 fs-3" style="z-index:2" v-bind:class="{
					'fs-5': data.message.length < 180,
					'fs-6': data.message.length >= 180,
				}">{{ data.message }}</p>
				<span class="fw-light text-secondary" style="z-index:2" v-if="data.author_name">{{ data.author_name }}</span>
			</div>
		</div>
		<div v-else>
			<div class="d-flex justify-content-center">
				<div class="spinner-border" role="status">
					<span class="visually-hidden">
						{{ __('gui.buttons.loading') }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		instance: null
	},
	
	data: function() {
		return {
			spoilerShown: false,
			data: null,
			is_loading: false,
		};
	},
	
	methods: {
		
		
		getData: function() {
			const vm = this;
			vm.is_loading = true;
			
			axios.get('/api/v6/widget/dailyquote')
			.then(res => {
				vm.data = null;
				if (res.data && res.data.data && res.data.data.data) {
					vm.data = res.data.data.data;
				}
			})
			.finally(() => {
				vm.is_loading = false;
			});
		},
		
		getSectionClasses: function() {
			var classes = [];
			
			if (this.instance.options.size_mobile == 'small') {
				classes.push('py-1');	
			}
			else if (this.instance.options.size_mobile == 'medium') {
				classes.push('py-3');	
			}
			else if (this.instance.options.size_mobile == 'large') {
				classes.push('py-4');	
			}
			else if (this.instance.options.size_mobile == 'extra-large') {
				classes.push('py-5');	
			}
			
			if (this.instance.options.size_desktop == 'small') {
				classes.push('py-lg-1');	
			}
			else if (this.instance.options.size_desktop == 'medium') {
				classes.push('py-lg-3');	
			}
			else if (this.instance.options.size_desktop == 'large') {
				classes.push('py-lg-4');	
			}
			else if (this.instance.options.size_desktop == 'extra-large') {
				classes.push('py-lg-5');	
			}
			
			return classes.join(' ');
		},
	},
	
	mounted() {
		const vm = this;
		vm.getData();
	}
}
</script>