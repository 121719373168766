<template>
	<div class="d-flex justify-content-between align-items-top">
		<div>
			<label v-if="config.label" :for="config.input_id" class="d-block fw-bold">
				{{ config.label }}
				<sup v-if="config.required" class="font-monospace text-warning">*</sup>
			</label>
			<span class="text-secondary" v-if="config.excerpt">{{ config.excerpt }}</span>
		</div>
		
		<div class="ms-2">
			<label class="switch d-block" v-bind:class="(config.disabled) ? 'disabled opacity-50' : ''">
				<input type="checkbox" :id="config.input_id" :name="config.input_name" class="success" v-model="inputVal" v-bind:required="(config.required)" v-bind:disabled="(config.disabled)">
				<span class="slider round"></span>
			</label>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		settings: null,
		value: null
	},
	
	computed: {
		inputVal: {
			get() {
				return (this.value == true || this.value == 1 || this.value == "1");
			},
			set(val) {
				val = Boolean(val);
				this.$emit('input', val);
			}
		}
	},
	
	data: function() {
		const defaultConfig = {
			label: null,
			excerpt: null,
			required: false,
			disabled: false,
			input_id: 'toggle-input-'+this.makeid(12),
			input_name: 'toggle-input-'+this.makeid(12),
		};
		
		return {
			config: {...defaultConfig, ...this.settings},
		};
	},
	
	methods: {
		
	},
	
	created() {
		
	},
	
	mounted() {
		
	}
}
</script>