<template>
	<div class="elements--page--testimonials-collection b5-container-xl position-relative" v-if="instance?.referenceable?.testimonials">
		<div v-if="instance.options.filter_by_tag && allAvailableTags()" class="mb-2 pb-1 d-flex align-items-top w-100 overflow-auto">
			<span @click="toggleTagFilter(null)" v-bind:class="filterByTag==null ? 'btn-primary active' : 'b5-bg-body2 text-opposite'" class="btn rounded-pill px-3 btn-sm text-nowrap me-1 me-lg-2 mb-1 border shadow-sm">Tutti</span>
			<span v-for="tag in allAvailableTags()" @click="toggleTagFilter(tag.id)" v-bind:class="filterByTag==tag.id ? 'btn-primary active' : 'b5-bg-body2 text-opposite'" class="btn rounded-pill px-3 btn-sm text-nowrap me-1 me-lg-2 mb-1 border shadow-sm">{{ tag.name }}</span>
		</div>
		
			<div :class="getRowClasses()">
				
				<div :class="getColumnClasses()" v-for="(item, item_ix) in testimonials" :key="'testimonial-'+item_ix+'-'+item.id">
					
					<div v-if="instance.options.items_design == 'modal'">
						<div class="ratio ratio-1x1 cursor-pointer" data-bs-toggle="modal" :data-bs-target="'#modal-'+uuid+'-testimonial-'+item_ix">
							<div class="b5-bg-body2 bg-cover lazy b5-transitionable b5-hover-scale animate__animated animate__fadeIn" :style="'background-image:url('+item.cc_thumbnail_720p_url+')'" @click="showDetails=false"></div>
						</div>
						
						<div class="modal" :id="'modal-'+uuid+'-testimonial-'+item_ix" tabindex="-1" :aria-labelledby="'modal-'+uuid+'-testimonial-'+item.id+'-label'" aria-hidden="true">
							  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
								<div class="modal-content bg-black">
									<div class="overlay overlay-dark overlay-sm">
										<div class="modal-header bg-none pt-3 pb-2 border-0 justify-content-end">
											<div class="safe-area-up-ios d-lg-none"></div>
											  <button type="button" class="btn-close btn-close-white mt-2" data-bs-dismiss="modal" aria-label="Close"></button>
										</div>
									</div>
								  <div class="modal-body p-0">
									  <div class="w-100 h-100 d-flex flex-column position-relative">
										  
										  <div class="w-100 h-100 d-flex flex-column position-absolute top-0 bottom-0 start-0 end-0 pz-Media text-center overflow-hidden" style="z-index:2">
											  <img :src="getTestimonialThumb(item)" class="img-fluid m-auto" alt="" />
										  </div>

										  
										  <div class="position-relative w-100 controls py-5 h-100" v-if="!showDetails">
													<button v-if="testimonials[item_ix-1] !== undefined" type="button" class="btn btn-ghost fs-1 text-white opacity-75 position-absolute top-50 left-0 ms-2 translate-middle-y overlay overlay-dark overlay-sm" @click="goPrev(item_ix)" style="z-index:3">
														  <i class="fas fa-chevron-left fa-fw"></i>
													  </button>
												  <button v-if="testimonials[item_ix+1] !== undefined" type="button" class="btn btn-ghost fs-1 text-white opacity-75 position-absolute top-50 end-0 me-2 translate-middle-y overlay overlay-dark overlay-sm" @click="goNext(item_ix)" style="z-index:3">
														<i class="fas fa-chevron-right fa-fw"></i>
													</button>
										  </div>
										  
										  <div class="text-white overlay overlay-dark overlay-lg mt-auto cursor-pointer" @click="showDetails=!showDetails" v-bind:class="showDetails ? 'h-100s' : ''" style="z-index:4">
											  	<div class="text-center mt-2 opacity-75 fs-2 sticky-top animate__animated animate__infinite animate__shakeY" style="--animate-duration: 8s" v-if="showDetails">
													  <i class="fas fa-fw fa-chevron-down"></i>
												  </div>
												  <div class="text-center mt-2 opacity-75 fs-2 sticky-top animate__animated animate__infinite animate__shakeY" style="--animate-duration: 8s" v-else>
														<i class="fas fa-fw fa-chevron-up"></i>
													</div>
												 <div class="text-black bg-white rounded-top-xxl p-3 p-lg-4 b5-container-lg">
													 
													 <div class="d-flex align-items-start justify-content-between w-100 mb-1">
														 <h4 class="fw-bold fs-3 mb-0">{{ item.name }}</h4>
														 
														 <div v-if="canShare()" class="text-end">
															   <button type="button" class="btn rounded-pill btn-evidence px-3 px-lg-4" @click="shareData({
																   title: (item.name+' '+item.excerpt).trim(),
																   text: item.description,
																   url: getTestimonialThumb(item),
															   })">
																   <i class="b5-fa-icon fa-arrow-up-from-square fa-fw"></i>
															   </button>
														   </div>
													 </div>
													 
													<div class="fw-medium lead" v-if="item.excerpt">{{ item.excerpt }}</div>
													<div v-if="showDetails" class="animate__animated animate__slideInUp">
														<p v-if="item.description" class="mb-0">{{ item.description }}</p>
														<div v-if="instance.options.filter_by_tag && item.tags && item.tags.length" class="mt-3">
															<span v-for="tag in item.tags" @click="toggleTagFilter(tag.id)" class="btn btn-sm btn-link text-decoration-none">#{{ tag.name }}</span>
														</div>
														
														<section v-if="item.related_posts && item.related_posts.length" class="mt-4">
															<div class="fs-5 fw-bold">Articoli correlati</div>
															<div class="row magnetic-scroll-on-mobile flex-row g-2 flex-nowrap justify-content-start overflow-auto">
																<div v-for="post in item.related_posts" class="magnetic-column mb-2 b5-col-3 b5-col-sm-4 b5-col-md-4 b5-col-lg-6 b5-col-xl-6-alt">
																	<post-card :post="post" :settings="{
																		show_excerpt: false,
																		style: 'boxed',
																		classes: 'border',
																	}" />
																</div>
															</div>
														</section>
													</div>
													
													
													   <div class="safe-area-down-ios"></div>
												 </div>
										  </div>
										  
									  </div>
								  </div>
								</div>
							  </div>
							</div>
					</div>
					
					<!-- <div v-else class="testimonial-container">
						<div class="testimonial-media-area">
							<img :src="item.cc_thumbnail_720p_url" class="testimonial-picture img-fluid"/>
						</div>
						<div class="testimonial-content-area">
							<div class="testimonial-message">
								This app is great. It does exactly what I need it do and when I did run into a settings problem, the friendly chaps at Elfsight sorted it out for me nice and quickly 😊
							</div>
							<div class="testimonial-name">{{ item.name }}</div>
							<div class="testimonial-subtitle">company name e professione</div>
						</div>
					</div> -->
					
				</div>
			</div>
			
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		watch: {
			testimonials: function(newVal) {
				document.querySelectorAll(".pz-Media img:not(.pz-Image)").forEach(element => {
				  pinchZoom(element);
				});
				
				this.$nextTick(function() {
					loadLazy();
					document.querySelectorAll(".pz-Media img:not(.pz-Image)").forEach(element => {
					  pinchZoom(element);
					});
				});
			}
		},
		
		computed: {
			testimonials() {
				if (this.filterByTag) {
					const vm = this;
					var rows = [];
					
					this.instance.processed_data.forEach(function(el) {
						if (el.tags.filter(function(t) {
							return t.id == vm.filterByTag;
						}).length) {
							rows.push(el);
						}
					});
					
					
					
					return rows;
				}
				
				return this.instance.processed_data;
			},
		},
		
		data: function() {
			return {
				uuid: 'element-page-testimonials-collection-'+this.makeid(12),
				data: {},
				showDetails: false,
				filterByTag: null,
			};
		},
		
		methods: {
			
			getTestimonialThumb: function(item) {
				return this.getFileVersionUrl(item.file) || item.cc_thumbnail_720p_url;
			},
			
			toggleTagFilter: function(tag_id) {
				this.hideAllBSModals();
				
				if (this.filterByTag == tag_id) {
					this.filterByTag = null;
				}
				else {
					this.filterByTag=tag_id;
				}
				
				this.loadLazy();
				document.querySelectorAll(".pz-Media img:not(.pz-Image)").forEach(element => {
				  pinchZoom(element);
				});
				fixTabbarSpacer();
				fixWappSize();
			},
			
			// testimonials: function() {
			// 	if (this.filterByTag) {
			// 		const vm = this;
			// 		var rows = [];
			// 		
			// 		this.instance.processed_data.forEach(function(el) {
			// 			if (el.tags.filter(function(t) {
			// 				return t.id == vm.filterByTag;
			// 			}).length) {
			// 				rows.push(el);
			// 			}
			// 		});
			// 		
			// 		return rows;
			// 	}
			// 	
			// 	document.querySelectorAll(".pz-Media img:not(.pz-Image)").forEach(element => {
			// 	  pinchZoom(element);
			// 	});
			// 	
			// 	return this.instance.processed_data;
			// },
			
			allAvailableTags: function() {
				var tags = [];
				
				this.data.processed_data.forEach(function(el) {
					el.tags.forEach(function(t) {
						if (!tags.filter(function(rt) {
							return rt.id == t.id;
						}).length) {
							tags.push(t);
						}
					});
				});
				
				return tags;
			},
			
			goNext: function(item_ix) {
				this.showDetails = false;
				this.hideBSModal('modal-'+this.uuid+'-testimonial-'+item_ix);
				this.showBSModal('modal-'+this.uuid+'-testimonial-' + (item_ix+1));
			},
			
			goPrev: function(item_ix) {
				this.showDetails = false;
				this.hideBSModal('modal-'+this.uuid+'-testimonial-'+item_ix);
				this.showBSModal('modal-'+this.uuid+'-testimonial-' + (item_ix-1));
			},
			
			hasOption: function(name) {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty(name)
					&& this.instance.processed_options[name] !== null
				);
			},
			
			getRowClasses: function() {
				var classes = [];
				
				classes.push('testimonials-container-'+this.instance.options.container_design);
				
				if (this.instance.options.container_design == 'grid') {
					if (this.instance.options.items_design == 'modal') {
						classes.push('row g-1')
					}
					else {
						classes.push('row g-3');
					}
				}
				
				return classes.join(' ');
			},
			
			getColumnClasses: function() {
				var classes = [];
				classes.push('testimonials-item-'+this.instance.options.items_design);
				
				if (this.instance.options.container_design == 'grid') {
					if (this.instance.options.items_design == 'modal') {
						classes.push('col-4 col-lg-3 col-xl-2');
					}
					else {
						classes.push('col-12 col-lg-4 col-xl-3');
					}
				}
				
				return classes.join(' ');
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				loadLazy();
				document.querySelectorAll(".pz-Media img:not(.pz-Image)").forEach(element => {
				  pinchZoom(element);
				});
			});
		}
	}
</script>