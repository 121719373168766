<template>
	<div class="elements--post-category--heading" v-if="hasOption('referenceable_id') && instance.processed_options.referenceable_id.hasOwnProperty('data') && instance.processed_options.referenceable_id.data.posts.length">
		<div class="container-xl pt-5 mb-3">
			<h1 v-bind:class="(instance.options.excerpt) ? 'mb-0' : ''" v-if="instance.options.title">
				{{ instance.options.title }}
			</h1>
			
			<p v-if="instance.options.excerpt">
				{{ instance.options.excerpt }}
			</p>
			
			<div class="row flex-row flex-nowrap justify-content-start overflow-auto" v-if="hasOption('referenceable_id') && instance.processed_options.referenceable_id.hasOwnProperty('data') && instance.processed_options.referenceable_id.data.posts.length">
				<div class="col-10 col-md-9 col-lg-5 col-xl-5" v-for="(item, index) in instance.processed_options.referenceable_id.data.posts">
					<router-link :to="{
						name: 'post.show',
						params: {
							postCategorySlug: item.category.slug,
							postSlug: item.slug
						}
					}" class="text-decoration-none text-reset card mb-3">
						<img v-if="item.cc_thumbnail_720p_url" :data-src="item.cc_thumbnail_720p_url" class="lazy img-fluid card-img-top" alt="" />
						
						<div class="card-body">
							{{ item.name }}
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			hasOption: function(name) {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty(name)
					&& this.instance.processed_options[name] !== null
				);
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				loadLazy();
			});
		}
	}
</script>