<template>
	<div class="d-flex align-items-top w-100">
		<i v-if="getParentOption('icon') && getParentOption('icon_type') == 'font-awesome'" :class="data.options.icon" class="fs-4 me-3 b5-fa-icon" :style="'color:'+getParentOption('icon_color')"></i>
		<span v-else-if="getParentOption('icon') && getParentOption('icon_type') == 'emoji'" class="fs-4 me-3" :style="'color:'+getParentOption('icon_color')">{{ getParentOption('icon') }}</span>
		
		<div>
			<h5 class="mb-0 text-opposite" v-if="data.options.title">
				{{ data.options.title }}
			</h5>
			<p class="mb-0" v-if="data.options.content" v-html="nl2br(urlify(encodeHTML(data.options.content)))"></p>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null,
		},
		
		data: function() {
			return {
				data: {},
			};
		},
		
		methods: {
			hasParent: function() {
				return (this.instance && this.instance.hasOwnProperty('parent_instance') && this.instance.parent_instance && this.instance.parent_instance.hasOwnProperty('options') && this.instance.parent_instance.options);
			},
			
			getParentOption: function(name, fallback=null) {
				return (this.hasParent() && this.instance.parent_instance.options.hasOwnProperty(name)) ? this.instance.parent_instance.options[name] : fallback;
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>