<template>
	
	<div>
		<div style="background:#132B41">
			<div class="b5-container-xl py-3">
				<div class="row g-4 py-5">
					<div class="col-12 col-lg-4">
						<img src="https://d2lalp3jtr7683.cloudfront.net/norrdr/2z7lfi.png" alt="" title="" class="mb-3 mw-100" style="height:35px">
						
						<div class="">
							<b>BizDay.it - Eventi Culturali Garda</b>
							<p class="text-secondary">
								Siamo un gruppo di Distributori Herbalife che, oltre a stimolare l’approccio ad uno stile di vita sano ed attivo, organizza degli eventi periodici con lo scopo di informare sull’opportunità di trasformare il benessere fisico anche in un’opportunità di benessere economico.
							</p>
							<p class="text-secondary">
								Organizziamo eventi che creino una maggior consapevolezza sull’opportunità di abbracciare uno stile di vita sano ed attivo.
							</p>
						</div>
					</div>
					
					<div :class="footer_col_classes" v-for="col in $globalData.websitedata.footer.menus.cols">
						<h5>{{ col.title }}</h5>
						<ul class="list-group list-group-flush">
							<li class="list-group" v-for="item in col.items">
								<b5-link v-if="item.use_vue" :href="item.url" class="list-group-item list-group-item-action bg-none">{{ item.label }}</b5-link>
								<a v-else :href="item.url" class="list-group-item list-group-item-action border-0 bg-none">
									{{ item.label }}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div style="background:#071A2B">
			<div class="b5-container-xl py-3 text-sm">
				<div class="d-flex w-100 justify-content-between flex-column flex-lg-row text-white opacity-50">
					<div>&copy; {{ toUserTz().format('YYYY') }} ECG - Eventi Culturali Garda</div>
					<div>
						Powered by <a href="https://build5.app" target="_blank" rel="nofollow" class="text-reset text-decoration-none text-secondary fw-medium">Build5</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		computed: {
			footer_col_classes: function() {
				var colscount = 1;
				var classes = ['col-12'];
				
				if (Vue.prototype.$globalData.teamdata.newsletter.is_available) {
					colscount++;
				}
				
				colscount += Vue.prototype.$globalData.websitedata.footer.menus.cols.length;
				
				if (colscount == 2) {
					classes.push('col-lg-6');
				}
				else if (colscount == 3) {
					classes.push('col-lg-4');
				}
				else if (colscount == 4) {
					classes.push('col-lg-3');
				}
				else {
					classes.push('col-lg-3');
				}
				
				
				return classes.join(' ');
			},
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
		}
	}
</script>