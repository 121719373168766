<template>
	<div class="bg-danger text-white p-3 text-center">BASIC HEADER</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
		}
	}
</script>