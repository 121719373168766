<template>
	<div class="b5-container-sm mt-5">
		<div class="text-center bg-white rounded-app shadow-lg mb-4 p-4">
		
			<img src="https://cdn3d.iconscout.com/3d/premium/thumb/error-404-5732790-4812666.png" alt="" class="img-fluid w-100" style="max-width:250px" />
			
			404
		</div>
		
	</div>
</template>

<script>
export default {
	mounted() {
		
		Vue.prototype.$emitter.emit('rvmounted');
	}
}
</script>