<template>
	<component v-if="componentName" :is="componentName" :instance="instance"/>
</template>
<script>
export default {
	props: {
		group: null,
		instance: null,
		settings: null,
	},
	
	data: function() {
		const defaultConfig = {
			
		};
		
		return {
			config: {...defaultConfig, ...this.settings},
			componentName: null,
		};
	},
	
	methods: {
		getElementType: function(instance) {
			if (instance && instance !== null && instance.hasOwnProperty('element')) {
				if (instance.element && instance.element !== null && instance.element.hasOwnProperty('type')) {
					return instance.element.type;
				}
			}
			
			return;
		},
		
		getElementName: function(instance) {
			if (instance && instance !== null && instance.hasOwnProperty('element')) {
				if (instance.element && instance.element !== null && instance.element.hasOwnProperty('name')) {
					return instance.element.name;
				}
			}
			
			return;
		},
	},
	
	created() {
		var cName = [];
		
		cName.push('elements');
		if (this.group == 'website-page') {
			cName.push(this.group);
		}
		else {
			cName.push('event');
		}
		
		var cType = this.getElementType(this.instance);
		if (cType == 'banners-collection.banner') {
			cType = 'banner';
		}
		cName.push(cType.replace('.', '-'));
		
		this.componentName = cName.join('-');
		
		
		
		/*
		if (this.group == 'page') {
			if (this.getElementType(this.instance) == 'esempio-riga') {
				this.componentName = 'elements-page-riga';
			}
			else if (this.getElementType(this.instance) == 'esempio-riga.esempio-colonna') {
				this.componentName = 'elements-page-riga-colonna';
			}
			else if (this.getElementType(this.instance) == 'esempio-colonna.testo') {
				this.componentName = 'elements-page-riga-colonna-testo';
			}
		}
		*/
	},
	
	mounted() {
		
	}
}
</script>