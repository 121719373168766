<template>
	<shop-catalog-section :category="instance.processed_data" :alternative_row="0"/>
</template>

<script>
export default {
	props: {
		instance: null
	},
	
	methods: {
		
	},
}
</script>