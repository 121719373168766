<template>
	<div class="list-group list-group-flush">
		<div class="list-group-item" v-for="item in items" v-bind:class="config.readonly ? '' : 'list-group-item-action'">
			<div class="d-flex w-100 align-items-start">
				<button v-if="!config.readonly" type="button" class="btn btn-ghost me-2 btn-sm text-secondary mt-2 rounded-pill" v-bind:disabled="$parent.isCartLoading" @click="removeItem(item)">
					<i class="fas fa-fw fa-xmark"></i>
				</button>
				
				<div class="ratio ratio-1x1" style="width:60px" @click="itemClicked(item)" v-bind:class="config.clickable ? 'cursor-pointer' : ''">
					<div class="b5-bg-body rounded bg-cover" :style="'background-image:url('+(item.product.cc_thumbnail_720p_url || getParentProduct(item)?.cc_thumbnail_720p_url)+')'"></div>
				</div>
				<div class="d-flex w-100 justify-content-between ms-3">
					<div>
						<div class="text-black fw-medium" v-bind:class="config.clickable ? 'cursor-pointer' : ''" @click="itemClicked(item)">{{ getParentProduct(item)?.name || item.product.name }}</div>
						
						<div v-if="config.readonly" class="text-secondary lh-sm">
							<div class="lh-sm text-sm">{{ item.product.name }}</div>
							<div class="text-sm">Quantità: {{ item.quantity }} @ {{ formatCurrency(item.product.price || getParentProduct(item)?.price, item.product.currency, 'auto', true) }}</div>
							<div class="text-sm">{{ formatCurrency((item.product.price || getParentProduct(item)?.price)*item.quantity, item.product.currency, 'auto', true) }}</div>
						</div>
						<div v-else>
							<small v-if="getParentProduct(item)?.name">{{ item.product.name }}</small>
							
							<!-- filtro per gusto dal carrello: funziona ma non lo uso perchè a BE ho bloccato l'aggiunta a carrello di prodotti per cui non ho già scelto una variante -->
							<div v-if="item.product.variants.length">
								<!-- scegli un gusto -->
								<select class="form-select mb-3" @change="(val) => setItemVariant(item, val)" v-bind:class="config.show_required_variants_error ? 'border border-3 border-danger' : ''">
									<option value="" selected>-- Seleziona</option>
									<option v-for="variant in item.product.variants" :value="variant.id">
										{{ variant.name }}
									</option>
								</select>
								<div class="text-danger text-sm" v-if="config.show_required_variants_error">Seleziona un gusto per continuare</div>
							</div>
							<!-- fine -->
							
							<div>
								<span class="text-sm" v-if="(item.product.price || getParentProduct(item)?.price)">
									{{ formatCurrency((item.product.price || getParentProduct(item)?.price)*item.quantity, item.product.currency, 'auto', true) }}
								</span>
								<span class="text-sm" v-else>
									GRATIS
								</span>
								<span class="text-secondary ms-2 text-sm">{{ formatCurrency(item.product.price || getParentProduct(item)?.price, item.product.currency, 'auto', true) }} per unit</span>
							</div>
						</div>
					</div>
					
					<div v-if="!config.readonly" class="d-flex align-items-center">
						<button type="button" class="btn btn-ghost btn-sm border rounded-start rounded-end-0" @click="updateItemQuantity(item, 'decrease')" v-bind:disabled="item.quantity <= 1">
							<i class="fas fa-fw fa-minus"></i>
						</button>
						<div class="b5-bg-body2 text-center px-3 py-1 border-top border-bottom">
							{{ item.quantity }}
						</div>
						<button type="button" class="btn btn-ghost btn-sm border rounded-start-0 rounded-end" @click="updateItemQuantity(item, 'increase')">
							<i class="fas fa-fw fa-plus"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		settings: null,
		items: [],
	},
	
	data: function() {
		const defaultConfig = {
			readonly: false,
			clickable: false,
			show_required_variants_error: false,
		};
		
		return {
			config: {...defaultConfig, ...this.settings},
			// items: [
			// 	{
			// 		quantity: 1,
			// 		pid: 123,
			// 		vid: 3423,
			// 		
			// 		product: {
			// 			id: 123,
			// 			name: 'Aloe gusto mango',
			// 			sku: 'werwer',
			// 			slug: 'ert45',
			// 			hlf_sku: 'wer4',
			// 			customize_quanity: 0,
			// 			max_quantity: 1,
			// 			cc_thumbnail_720p_url: 'https://www.hlifeshop.eu/wp-content/uploads/2018/10/aloe_herbalife.png',
			// 			
			// 			variants: [
			// 				{
			// 					id: 3423,
			// 					name: 'Gusto Mango',
			// 					price: 30,
			// 					currency: 'eur',
			// 					slug: 'ert45',
			// 				},
			// 				{
			// 					id: 2341,
			// 					name: 'Gusto Max',
			// 					price: 35,
			// 					currency: 'eur',
			// 					slug: 'ert45',
			// 				},
			// 			],
			// 		},
			// 	}
			// ]
		};
	},
	
	methods: {
		
		setItemVariant: function(item, val) {
			var item_id = val.target.value || 0;
			
			if (item_id) {
				this.$emit('item_added', {
					id: item_id,
					quantity: item.quantity,
				});
				this.$emit('item_removed', item);
			}
		},
		
		getParentProduct: function(item) {
			if (item && item.hasOwnProperty('product') && item.product && item.product.hasOwnProperty('parent')) {
				return item.product.parent;
			}
		},
		
		updateItemQuantity: function(item, action) {
			if (action == 'increase') {
				item.quantity++;
			}
			else if (action == 'decrease') {
				item.quantity = (item.quantity <= 1) ? item.quantity+1 : item.quantity-1;
			}
			
			if (! item.quantity) {
				this.$removeItem(item);
			}
			else {
				this.$emit('update_quantity', item);
			}
		},
		
		removeItem: function(item) {
			this.$emit('item_removed', item);
		},
		
		itemClicked: function(item) {
			const vm = this;
			if (!this.config.clickable) {
				return;
			}
			else {
				this.$router.push({
					name: 'shop.team.product.show',
					params: {
						teamSlug: vm.$route.params.teamSlug,
						productSlug: vm.getParentProduct(item)?.slug || item.product.slug,
					}
				});
			}
		}
	},
	
	created() {
		
	},
	
	mounted() {
		
	}
}
</script>