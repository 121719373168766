<template>
	<div class="elements--post---markdown-text b5-container-lg px-0 mx-0">
		<div v-html="compiledMarkdown()"></div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			compiledMarkdown: function() {
				if (this.data && this.data.hasOwnProperty('processed_options') && this.data.processed_options && this.data.processed_options.hasOwnProperty('text') && this.data.processed_options.text && this.data.processed_options.text.hasOwnProperty('value') && this.data.processed_options.text.value) {
					if (this.data.processed_options.text_format.value == 'html') { // puro html diretto
						return this.data.processed_options.text.value;
					}
					return this.data.processed_options.text.value;
				}
				return '';
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>