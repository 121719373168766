<template>
	<div class="b5-container-xl py-2 py-lg-3">
		<div class="d-flex w-100 align-items-center w-100">
			<button type="button" class="btn btn-ghost rounded-pill me-1 me-lg-2 text-secondary" @click="$globalData.fsnavigation.visible=1">
				<i class="fas fa-fw fa-bars"></i>
			</button>
			<router-link :to="{
				name: 'page.show',
				params: {
					pageSlug: 'home',
				}
			}" class="cursor-pointer text-reset text-decoration-none">
				<img v-if="$globalData.websitedata.metas?.logo_url" :src="$globalData.websitedata.metas.logo_url" alt="Logo" title="" height="30px" />
			</router-link>
			
			<div class="d-flex align-items-center ms-auto">
				<button v-if="$globalData.websitedata.shop.available_countries.length > 1 && $globalData.websitedata.shop.current_country" type="button" class="btn btn-ghost rounded-pill me-1 d-none d-lg-inline-block" @click="showBSModal('modal-choose-country')">
					<img :src="$globalData.websitedata.shop.current_country.icon" :alt="$globalData.websitedata.shop.current_country.name" title="Switch country" height="18px" />
				</button>
				
				<div v-if="$globalData.header.cart.visible">
					<button type="button" v-if="$parent.isCartLoading" class="btn btn-ghost rounded-pill mx-1 px-3 disabled" disabled>
						<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
						<span class="visually-hidden">Loading...</span>
					</button>
					<router-link v-else-if="$route.params.teamSlug" :to="{
						name: 'shop.team.cart.show',
						params: {
							teamSlug: $route.params.teamSlug,
							countryCode: getCurrentCountryCode(),
						}
					}" class="btn btn-ghost rounded-pill mx-1 px-4 border text-nowrap" v-bind:class="$globalData.cart.total_items ? 'text-primary border-primary' : ''">
						{{ $globalData.cart.total_items }} <i class="fas fa-fw fa-bag-shopping"></i>
					</router-link>
				</div>
			</div>
		</div>
		
		<div v-html="css"></div>
	</div>
</template>
<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: null,
				css: `<style>
				#header {
					position:fixed;
					top:0;
					left:0;
					right:0;
					width:100%;
					z-index:19;
				}
				#header + div {
					padding-top: 55px
				}
				
				@media (min-width: 1200px) {
					#header + div {
						padding-top:71px
					}
				}
				</style>`,
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
		}
	}
</script>