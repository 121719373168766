var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video-player',{attrs:{"video_options":{
		  autoplay: true,
		  muted: false,
		  controls: false,
		  poster: _vm.obj.msg.poster,
		  sources: [{
			  type: 'video/mp4',
			  src: _vm.obj.msg.src
		  }],
	  },"settings":{
		  file_id: null,
		  title: null,
		  excerpt: null,
		  page_url: null,
		  start_from: null,
		  aspect_ratio: null,
		  orientation: 'vertical',
		  show_unmute_button: true,
		  show_replay_button: true,
		  show_progress_bar: true,
	  }}}),_vm._v(" "),_c('div',[(_vm.is_loading)?_c('button',{staticClass:"btn btn-evidence d-block w-100 rounded-pill disabled position-absolute bottom-0 start-0 end-0 w-100",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"visually-hidden",attrs:{"role":"status"}},[_vm._v("Loading...")])]):(_vm.obj.actions?.length)?_c('div',[_c('div',{staticClass:"px-3 pt-2 pb-1 btn-group-vertical position-absolute bottom-0 start-0 end-0 w-100"},_vm._l((_vm.obj.actions),function(item){return _c('div',{staticClass:"w-100 mb-1"},[_c('button',{staticClass:"btn rounded-pill d-block w-100 b5-bg-opaque btn-opaque-light text-black btn-opaque-light-lg",attrs:{"type":"button"},on:{"click":function($event){return _vm.liveChatActionClicked(item)}}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.label)+" "),(_vm.isExternalUrl(item.username))?_c('i',{staticClass:"b5-fa-icon fa-fw fa-external-link"}):_vm._e()])])}),0)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }