<template>
	<div class="elements--post-category--alert b5-container-xl">
		<div :class="'alert alert-'+instance.options.type+' text-'+instance.options.text_alignment+' '+instance.options.classes">
			{{ instance.options.title }}
			<p v-if="instance.options.excerpt">{{ instance.options.excerpt }}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
		}
	}
</script>