<template>
	<div class="elements--post-category--tales-collection-smart" v-show="hasProcessedData() && instance.processed_data.length">
		<div class="b5-container-xl">
			<div class="heading mb-2">
				<div class="d-flex justify-content-between align-items-top">
					<h4 v-if="instance.options.title" class="mb-0 fw-bold text-opposite">
						{{ instance.options.title }}
					</h4>
				
					<b5-link v-if="hasCtaAction() && hasCtaText()" :href="instance.options.cta_web_url" class="btn btn-ghost text-primary text-nowrap text-decoration-none p-0">
						{{ instance.options.cta_text }}
						
						<i class="b5-fa-icon fa-chevron-right"></i>
					</b5-link>
				</div>
				
				<p v-if="instance.options.excerpt" class="mb-0 text-secondary">
					{{ instance.options.excerpt }}
				</p>
			</div>
			
			<div v-if="hasProcessedData() && instance.processed_data.length" :class="getRowClasses()">
				<div v-for="(item, index) in instance.processed_data" :class="getColumnClasses()">
					<tale-card :item="item" :settings="{
						style: instance.options.card_style,
						show_title: instance.options.show_title,
					}"/>
				</div>
			</div>
			<div v-else :class="getRowClasses()">
				<div v-for="item in 10" :class="getColumnClasses()">
					<div class="placeholder-wave">
						<div class="ratio ratio-9x16 mb-1">
							<div class="placeholder rounded-app"></div>
						</div>
					</div>
					
					<div class="placeholder-glow">
						<div class="col-8 placeholder placeholder-lg mb-0"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
			getRowClasses: function() {
				var classes = ['row'];
				const design = this.getDesign();
				const size = this.getSize();
				const layout = this.getLayout();
				
				if (layout == 'scroll') {
					classes.push('magnetic-scroll-on-mobile');
					
					if (design == 'link' || design == 'pills' || design == 'pills-simple') {
						classes.push('flex-row g-2 flex-nowrap justify-content-start');
					}
					else {
						classes.push('flex-row g-2 flex-nowrap justify-content-start');
					}
					
					if (this.hasProcessedData() && this.instance.processed_data.length) {
						classes.push('overflow-auto');
					}
					else {
						classes.push('overflow-hidden');
					}
				}
				else if (layout == 'full_list') {
					classes.push('align-items-stretch g-2');
				}
				
				return classes.join(' ');
			},
			
			getColumnClasses: function() {
				var classes = ['magnetic-column'];
				const design = this.getDesign();
				const size = this.getSize();
				const layout = this.getLayout();
				
				if (layout == 'scroll') {
					classes.push('mb-2');
					
					if (size == 'large') {
						classes.push('b5-col-2 b5-col-lg-2 b5-col-xl-3');
					}
					else if (size == 'default') {
						classes.push('b5-col-2 b5-col-sm-3 b5-col-md-3 b5-col-lg-3 b5-col-xl-4-alt');
					}
					else if (size == 'small') {
						classes.push('b5-col-3 b5-col-sm-4 b5-col-md-4-alt b5-col-lg-4-alt b5-col-xl-5');
					}
					else if (size == 'mini') {
						classes.push('b5-col-tale-mini');
					}
					else if (size == 'extra_small') {
						classes.push('b5-col-tale-extra-small');
					}
				}
				else if (layout == 'full_list') {
					if (size == 'large') {
						classes.push('mb-2');
						classes.push('col-12');
					}
					else if (size == 'default') {
						classes.push('mb-2');
						classes.push('col-6');
					}
					else if (size == 'small') {
						classes.push('mb-2');
						classes.push('col-6 b5-col-sm-6 col-md-6 col-lg-4 col-xl-3');
					}
					else if (size == '2cols') {
						classes.push('col-12 col-md-6');
					}
				}
				
				return classes.join(' ');
			},
			
			getDesign: function() {
				if (this.instance.options.hasOwnProperty('design') && this.instance.options.design !== null) {
					return this.instance.options.design;
				}
				
				return 'pills_simple';
			},
			
			getSize: function() {
				if (this.instance.options.hasOwnProperty('size') && this.instance.options.size !== null) {
					return this.instance.options.size;
				}
				
				return 'default';
			},
			
			getLayout: function() {
				if (this.instance.options.hasOwnProperty('layout') && this.instance.options.layout !== null) {
					return this.instance.options.layout;
				}
				
				return 'scroll';
			},
			
			
			
			
			hasProcessedData: function() {
				const vm = this;
				return (
					vm.instance.hasOwnProperty('processed_data')
					&& Array.isArray(this.instance.processed_data)
				);
			},
			
			hasCtaText: function() {
				return (
					this.instance.options.hasOwnProperty('cta_text')
					&& this.instance.options.cta_text !== null
					&& this.instance.options.cta_text.length
				);
			},
			
			hasCtaAction() {
				return (
					this.instance.options.hasOwnProperty('cta_web_url')
					&& this.instance.options.cta_web_url !== null
				);
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
			this.$nextTick(function() {
				vm.loadLazy();
			});
		}
	}
</script>