<template>
	<div class="elements--post-category--heading b5-container-lg">
		<div :class="getTitleClasses()">
			{{ instance.options.text }}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			getTitleClasses: function() {
				var classes = [];
				
				if (this.instance.options.hasOwnProperty('font_family')) {
					classes.push('font-'+this.instance.options.font_family);
				}
				
				if (this.instance.options.hasOwnProperty('size')) {
					if (this.instance.options.size == 'lead') {
						classes.push('lead lh-1 fw-medium');
					}
					else if (this.instance.options.size == 'h1') {
						classes.push('fs-1');
					}
					else if (this.instance.options.size == 'h2') {
						classes.push('fs-2');
					}
					else if (this.instance.options.size == 'h3') {
						classes.push('fs-3');
					}
					else if (this.instance.options.size == 'h4') {
						classes.push('fs-4');
					}
					else if (this.instance.options.size == 'h5') {
						classes.push('fs-5');
					}
					else if (this.instance.options.size == 'h6') {
						classes.push('fs-6');
					}
					else {
						classes.push('fw-bold '+this.instance.options.size);
					}
				}
				
				return classes.join(' ');
			}
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>