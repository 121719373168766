<template>
	<div class="elements--post-category--alert b5-container-xl">
		<alert :settings="{
			dismissible: instance.options.dismissible,
			type: instance.options.type,
			classes: 'text-'+instance.options.text_alignment+' '+instance.options.classes,
			title: instance.options.title,
		}" v-html="instance.options.excerpt"></alert>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
		}
	}
</script>