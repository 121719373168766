<template>
	<div class="elements--page--hero-section">
		<div class="bg-cover lazy" v-bind:data-src="(hasBackgroundPicture()) ? instance.processed_options.background_picture.media.url : null">
			<div class="py-5 w-100 d-flex align-items-center" v-bind:class="getClasses()">
				<div class="b5-container-lg py-lg-5 px-lg-4" v-bind:class="(hasOption('text_alignment') && instance.options.text_alignment == 'center') ? 'justify-content-center text-center' : 'justify-content-start text-start'">
					
					<div class="mx-auto d-block d-md-inline-block mb-3" v-if="1==2">
						<div v-if="hasCta() && isExternalUrl(instance.options.cta_web_url)">
							<a :href="instance.options.cta_web_url" target="_blank" class="target-blank btn btn-lg d-block w-100 px-5 rounded-pill" v-bind:class="(this.instance.options.color_scheme == 'light') ? 'btn-light' : 'btn-dark'">
								{{ instance.processed_options.cta_text.value }} <i class="fas fa-chevron-right"></i>
							</a>
						</div>
						<div v-else-if="hasCta()">
							<router-link :to="processLocalUrl(instance.options.cta_web_url)" class="btn btn-lg d-block w-100 px-5 rounded-pill" v-bind:class="(this.instance.options.color_scheme == 'light') ? 'btn-light' : 'btn-dark'">
								{{ instance.processed_options.cta_text.value }} <i class="fas fa-chevron-right"></i>
							</router-link>
						</div>
					</div>
					
					<div v-if="instance.hasOwnProperty('childrens') && instance.childrens !== null && instance.childrens.length" v-for="(item, index) in instance.childrens">
						<dynamic-element-render group="page" :instance="item" class="mb-3"></dynamic-element-render>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				modal_id: 'modal-quick-video-'+this.makeid(12),
				data: {
					
				}
			};
		},
		
		methods: {
			getClasses: function() {
				var classes = [];
				classes.push(this.getOverlay());
				
				if (this.instance.options.full_height) {
					classes.push('min-vh-90');
				}
				
				return classes.concat(' ');
			},
			
			getOverlay: function() {
				if (this.instance.options.overlay == 'dark') {
					return 'overlay-dark overlay-md';
				}
				else if (this.instance.options.overlay == 'light') {
					return 'overlay-light overlay-sm';
				}
				return;
			},
			
			makeid: function(length) {
				var result           = '';
				var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				var charactersLength = characters.length;
				for ( var i = 0; i < length; i++ ) {
				  result += characters.charAt(Math.floor(Math.random() * 
			 charactersLength));
			   }
			   return result;
			},
			
			getColorScheme: function() {
				if (this.instance.options.color_scheme == 'light') {
					return 'text-white';
				}
				else if (this.instance.options.color_scheme == 'dark') {
					return 'text-dark';
				}
			},
			
			hasTopPicture: function() {
				var instance = this.instance;
				return (
					instance.processed_options.top_picture 
					&& instance.processed_options.top_picture.media 
					&& instance.processed_options.top_picture.media.url
				);
			},
			
			hasBottomPicture: function() {
				var instance = this.instance;
				return (
					instance.processed_options.bottom_picture 
					&& instance.processed_options.bottom_picture.media 
					&& instance.processed_options.bottom_picture.media.url
				);
			},
			
			hasBackgroundPicture: function() {
				var instance = this.instance;
				return (
					instance.processed_options.background_picture 
					&& instance.processed_options.background_picture.media 
					&& instance.processed_options.background_picture.media.url
				);
			},
			
			hasOption: function(name) {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty(name)
					&& this.instance.processed_options[name] !== null
				);
			},
			
			hasCta: function() {
				return (this.instance.options.cta_text !== null && this.instance.options.cta_web_url !== null);
			},
			
			processLocalUrl: function(url) {
				return removeVueDomain(url);
			},
			
			isExternalUrl: function(url) {
				return false;
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
			
			this.$nextTick(function() {				
				loadLazy();
			});
		}
	}
</script>