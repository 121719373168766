module.exports = {
	methods: {
		
		smoothScrollToEl: function(id) {
			document.getElementById(id)?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
		},
		
		getHost: function() {
			return window.location.host;
		},
		
		processPageMetas: function(data) {
			if (data.hasOwnProperty('header')) {
				Vue.prototype.$globalData.header.visible = data.header.visible;
				Vue.prototype.$globalData.header.cname = data.header.cname;
				
				if (data.header.hasOwnProperty('cart')) {
					Vue.prototype.$globalData.header.cart.visible = data.header.cart.visible;
				}
			}
			
			if (data.hasOwnProperty('footer')) {
				Vue.prototype.$globalData.footer.visible = data.footer.visible;
				Vue.prototype.$globalData.footer.cname = data.footer.cname;
			}
			
			if (data.hasOwnProperty('livechat')) {
				this.processLiveChat(data.livechat);
			}
			
			if (data.hasOwnProperty('social_proof')) {
				this.processLiveChat(data.social_proof);
			}
			
			if (data.hasOwnProperty('hlfpopup')) {
				Vue.prototype.$globalData.hlfpopup = data.hlfpopup;
			}
		},
		
		processLiveChat: function(data) {
			var unread_count = (data.messages != Vue.prototype.$globalData.livechat.messages && !Vue.prototype.$globalData.livechat.visible) ? data.messages.length : 0; // solo se i nuovi messaggi sono diversi dai precedenti e solo se la chat non è già aperta (es. passo da una chat all'altra)
			Vue.prototype.$globalData.livechat.type = data.type;
			Vue.prototype.$globalData.livechat.username = data.username;
			Vue.prototype.$globalData.livechat.driver = data.driver;
			Vue.prototype.$globalData.livechat.enabled = data.driver && data.username && data.enabled == 1;
			Vue.prototype.$globalData.livechat.messages = null;
			Vue.prototype.$globalData.livechat.messages = [];
			Vue.prototype.$globalData.livechat.messages = data.messages;
			Vue.prototype.$globalData.livechat.actions = data.actions;
			Vue.prototype.$globalData.livechat.unread_count = unread_count;
			if (data.messages && data.messages.length) {
				Vue.prototype.$globalData.livechat.visible = 1;
			}
		},
		
		processSocialProof: function(data) {
			Vue.prototype.$globalData.social_proof.current_ix = 0;
			Vue.prototype.$globalData.social_proof.enabled = data.enabled;
			Vue.prototype.$globalData.social_proof.visible = data.items.length ? 1 : 0;
			Vue.prototype.$globalData.social_proof.items = data.items;
			Vue.prototype.$globalData.social_proof.loop = data.looped;
			Vue.prototype.$emitter.emit('socialproof_loaded');
		},
		
		getCurrentCountryCode: function() {
			if (this.$route.params.countryCode) {
				return this.$route.params.countryCode;
			}
			else if (window.countrycode) {
				return window.countrycode;
			}
			return Vue.prototype.$globalData.websitedata.shop.current_country.code;
		  },
		
		getCurrentLocationHref: function() {
			return window.location.href;
		},
		
		includeFBPixelScript: function() {
			if (Vue.prototype.$globalData.websitedata.fbpixel.id) {
				!function(f,b,e,v,n,t,s)
				{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.id='fbpixelscript';
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', Vue.prototype.$globalData.websitedata.fbpixel.id);
			}
		},
		
		fbPixelEvent: function(event_name='PageView', data=null) {
			if (Vue.prototype.$globalData.websitedata.fbpixel.id) {
				if (document.getElementById('fbpixelscript') == null) {
					this.includeFBPixelScript();
				}
				
				if ([ 'PageView', 'Contact', 'Schedule' ].includes(event_name)) {
					fbq('track', event_name);
				}
				else {
					fbq('trackCustom', event_name, data);
				}
			}
		},
		
		isValidEmail: function(email) {
			return (email != null && email !== '' && email.toLowerCase()
			.match(
			  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			));
		},
		
		getFileVersionUrl: function(item, settings={}) {
			const defaultConfig = {
				// types: ['mp4_720p_128kbps', 'original'],
				// types: ['mp3_128kbps', 'original'],
				types: [ 'image_720', 'image_1080', 'image_1920', 'original' ],
			};
			
			if (
				item !== null && item 
				&& item.hasOwnProperty('versions') 
				&& item.versions !== null 
				&& Array.isArray(item.versions) 
				&& item.versions.length
			) {
				var versions = item.versions;
				const config = {...defaultConfig, ...settings};
				var url = null;
				
				config.types.forEach(function(render_quality, jIn) {
					if (! url) {
						versions.forEach(function(item, index) {
							if (item.type == render_quality) {
								url = item.url;
								return item.url;
							}
						});
					}
				});
				
				return url;
			}
		},
		
		getCountryName: function(code) {
			  const regionNames = new Intl.DisplayNames(
				[Vue.prototype.$globalData.websitedata.shop?.current_country?.language || 'en'], {type: 'region'}
			  );
			  
			  if (code && regionNames && regionNames.of(code)) {
				  return regionNames.of(code);
			  }
			  
			  return code;
		  },
		
		getLangName: function(code) {
			  const langs = new Intl.DisplayNames(
				[Vue.prototype.$globalData.websitedata.shop.current_country.language || 'en'], {type: 'language'}
			  );
			  
			  if (code && langs.of(code)) {
				  return langs.of(code);
			  }
			  
			  return code;
		  },
		
		canShare: function(files=[]) {
			if (navigator && navigator.canShare) {
				if (files && Array.isArray(files) && files.length) {
					if (navigator.canShare({ files: files })) {
						return true;
					}
					else {
						console.log('Your system doesn\'t support sharing files.');
					}
				}
				else {
					return true;
				}
			}
			return false;
		},
		
		shareData: function(data) {
			if (data.hasOwnProperty('files') && data.files) {
				if (this.canShare(data.files)) {
					navigator.share(data)
					  .then(() => console.log('Share was successful.'))
					  .catch((error) => console.log('Sharing failed', error));
				}
			}
			else {
				try {
					navigator.share(data)
				} catch(err) {
					console.log('Error sharing: '+err);
				}
			}
		},
		
		showBSModal: function(modal_id) {
			var myModalEl = document.getElementById(modal_id);
			if (myModalEl !== null) {
				bootstrap.Modal.getOrCreateInstance(myModalEl).show();
			}
		},
		
		hideBSModal: function(modal_id) {
			var myModalEl = document.getElementById(modal_id);
			if (myModalEl !== null) {
				bootstrap.Modal.getOrCreateInstance(myModalEl).hide();
			}
		},
		
		hideAllBSModals: function(except=null) {
			const modals = document.querySelectorAll('.modal');
			const vm = this;
			
			modals.forEach(function(el) {
				if (el && el.id && el.id != except) {
					vm.hideBSModal(el.id);
				}
			});
		},
		
		getChildrenInstances: function(instances=[], parent_id) { // usato in manage edit per gestire i componenti
			const vm = this;
			var rows = [];
			
			if (instances && Array.isArray(instances)) {
				instances.filter(function(el) {
					return el.parent_id == parent_id;
				}).forEach(function(el) {
					el.childrens = vm.getChildrenInstances(el.id);
					rows.push(el);
				});
			}
			
			return rows;
		},
		
		fixPreventAutofill: function(ref_name) { // https://stackoverflow.com/questions/2530/how-do-you-disable-browser-autocomplete-on-web-form-field-input-tags
			var e = this.$refs[ref_name];
			
			console.log(e);
			
			if (e && e.hasAttribute('readonly')) {
				e.removeAttribute('readonly');
				// fix for mobile safari to show virtual keyboard
				e.blur();
				e.focus();
			}
		},
		
		htmlToElement: function(html) {
			  var template = document.createElement('template');
			  html = html.trim(); // Never return a text node of whitespace as the result
			  template.innerHTML = html;
			  return template.content.firstChild;
		},
		
		createToastAndShow: function(config) {
			  let defaultConfig = {
				message: "",
				icon: null,
				title: null,
				color: "default",
				classes: null,
				cta_text: 'Read more',
				cta_url: null,
				
				type: 'banner',
				preset: 'default',
				size: 'default',
				vibrate: null,
				
				delay: 2750,
				animation: true,
			  };
			  var options = { ...defaultConfig, ...config };
			  var toastId = 'toast-'+this.makeid(12);
			  
			  var darkCloseButton = `<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>`;
			  var whiteCloseButton = `<button type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>`;
			  
			  var closeButton = darkCloseButton;
			  
			  var html_classes = [];
			  
			  html_classes.push("toast-"+options.type+' toast-'+options.preset+' toast-size-'+options.size);
			  if (options.color != "default") {
				html_classes.push('text-white '+options.color);
				closeButton = whiteCloseButton;
			  }
			  
			  if (options.classes) {
				html_classes.push(options.classes);
			  }
			  
			  var title = '';
			  if (options.title !== undefined && options.title) {
				title = `<strong class="me-auto text-dark">`+options.title+`</strong>`;
			  }
			  
			  var icon = '';
			  if (options.icon !== undefined && options.icon) {
				icon = `<img src="`+options.icon+`" class="rounded me-2" width="24" height="24" alt="">`;
			  }
			  
			  var cta = '';
			  if (options.cta_text && options.cta_url) {
				cta = '<a href="'+options.cta_url+'" class="btn btn-sm btn-evidence d-block w-100">'+options.cta_text+'</a>';
			  }
			  
			  if (options.message == null) {
				options.message = '';
			  }
			  
			  var msg = '';
			  if (options.message || cta) {
				msg = `<div class="toast-body">`;
				
				if (options.message && options.message != null && options.message.trim().length) {
				  msg += '<div class="toast-message">'+options.message+'</div>';
				}
				
				if (cta) {
				  msg += cta;
				}
				
				msg += `</div>`;
			  }
			  
			  if (options.type == 'notification' || options.type == 'mention') {
					html_classes.push('b5-bg-opaque-lg overlay overlay-light overlay-xxl');	
				}
			  
			  var toast = `<div id="toast-`+toastId+`" class="toast `+html_classes.join(' ')+` border-0" role="alert" aria-live="assertive" aria-atomic="true"  data-autohide="true"><div class="toast-content"><div class="d-flex align-items-start">`+msg+` <div class="toast-close-area">`+closeButton+`</div></div></div></div>`;
			  
			  if (icon || title) {
				// forcing no background color...
				html_classes.push(options.classes);
				closeButton = darkCloseButton;
				toast = `<div id="toast-`+toastId+`" class="toast `+html_classes.join(' ')+`" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-header">`+icon+` `+title+` `+closeButton+`</div><div class="toast-body">`+options.message+`</div></div>`;
			  }
			  
			  
			  var toastElement = this.htmlToElement(toast);
			  var toastConainerElement = document.getElementById("toasts-area");
			  toastConainerElement.appendChild(toastElement);     
			  
			  if (options.type == 'pill') {
					toastElement.style.display = "flex";
				  setTimeout(function() {
					  toastElement.style.display = "none";
					  toastElement.remove();
				  }, options.delay);
			  }
			  else {
				  var toast = new bootstrap.Toast(toastElement, {
						delay: options.delay,
						animation: options.animation,
					}); 
					toast.show();
			  }
			  
			  if (options.vibrate == 'success') {
				  this.sendJsonValueToIOS({
					  action: 'vibrate_success', // vibrate_success || vibrate_warning || vibrate_error
				  });
			  }
			  else if (options.vibrate == 'warning') {
				  this.sendJsonValueToIOS({
					  action: 'vibrate_warning', // vibrate_success || vibrate_warning || vibrate_error
				  });
			  }
			  else if (options.vibrate == 'error') {
				  this.sendJsonValueToIOS({
					  action: 'vibrate_error', // vibrate_success || vibrate_warning || vibrate_error
				  });
			  }
			  
			  return toastId;
			},
		
		
		hrefSendVia: function(to, via, msg=null) {
			
			if (via == 'sms') {
				return 'sms:'+to+'&body='+msg;
			}
			else if (via == 'whatsapp') {
				return 'https://api.whatsapp.com/send/?phone='+to+'&text='+msg;
				return 'https:///wa.me/'+to+'?text='+msg;
			}
			else if (via == 'telegram') {
				to = to.replace('@', ''); // rimuovo @ dall'username
				return 'https://telegram.me/'+to+'?parse_mode=markdown&text='+msg;
			}
			else if (via == 'email') {
				return 'mailto:'+to+'?body='+msg;
			}
			else if (via == 'phone') {
				return 'tel:'+to;
			}
		},
		
		
		getCookie: function(name) {
			var dc = document.cookie;
			var prefix = name + "=";
			var begin = dc.indexOf("; " + prefix);
			if (begin == -1) {
				begin = dc.indexOf(prefix);
				if (begin != 0) return null;
			}
			else
			{
				begin += 2;
				var end = document.cookie.indexOf(";", begin);
				if (end == -1) {
				end = dc.length;
				}
			}
			// because unescape has been deprecated, replaced with decodeURI
			//return unescape(dc.substring(begin + prefix.length, end));
			return decodeURI(dc.substring(begin + prefix.length, end));
		},
		
		setCookie: function(name,value,duration=1*24*60*60*1000) {
			var expires = "";
			if (duration) {
				var date = new Date();
				date.setTime(date.getTime() + (duration));
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = name + "=" + (value || "")  + expires + "; path=/";
		},
		
		deleteCookie: function(name, path, domain) {
			if (this.getCookie(name)) {
				document.cookie = name + "=" +
				  ((path) ? ";path="+path:"")+
				  ((domain)?";domain="+domain:"") +
				  ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
			}
		},
		
		/* Elements instances manager v1.0 */
		createElementInstanceForElement: function(element, instance={}) {
			var options = {};
			var processed_options = {};
			
			if (instance && instance.hasOwnProperty('processed_options') && instance.procesed_options) { // Se la instance ha già delle processed_options le prendo come base di partenza
				processed_options = intance.processed_options;
			}
			
			if (element.hasOwnProperty('fields_groups') && element.fields_groups) {
				console.log('entraaa');
				element.fields_groups.forEach(function(gr) {
					if (gr && gr.hasOwnProperty('fields') && gr.fields) {
						gr.fields.forEach(function(opt) {
							//console.log('>>> sto elaborando il field ' + opt.name);
							//console.log(opt);
							
							opt.value = (opt.hasOwnProperty('default')) ? opt.default : null;
							
							//console.log('default value: ' + opt.value);
							
							if (instance && instance.hasOwnProperty('processed_options') && instance.processed_options.hasOwnProperty(opt.name)) {
								opt.value = instance.processed_options[opt.name].value;
								
								opt.media = instance.processed_options[opt.name].media;
								
								//console.log('aveva già un processed_option: ' + opt.value);
							}
							
							options[opt.name] = opt.value;
							processed_options[opt.name] = opt;
							
							//console.log('final option: ' + options[opt.name]);
							//console.log('final processed_option: ' + JSON.stringify(processed_options[opt.name]));
							//console.log('------------');
						});
					}
				});
			}
			/*
			else {
				// Supporto ai vecchi fields
				element.fields.forEach(function(opt) {
					//console.log('>>> sto elaborando il field ' + opt.name);
					//console.log(opt);
					
					opt.value = (opt.hasOwnProperty('default')) ? opt.default : null;
					
					//console.log('default value: ' + opt.value);
					
					if (instance && instance.hasOwnProperty('processed_options') && instance.processed_options.hasOwnProperty(opt.name)) {
						opt.value = instance.processed_options[opt.name].value;
						
						opt.media = instance.processed_options[opt.name].media;
						
						//console.log('aveva già un processed_option: ' + opt.value);
					}
					
					options[opt.name] = opt.value;
					processed_options[opt.name] = opt;
					
					//console.log('final option: ' + options[opt.name]);
					//console.log('final processed_option: ' + JSON.stringify(processed_options[opt.name]));
					//console.log('------------');
				});
			}
			*/
			
			//console.log('Sto per creare una instance con queste options: ' + JSON.stringify(options));
			//console.log('e queste processed options: '  + JSON.stringify(processed_options));
			
			var defaultInstance = {
				id: (instance && instance.hasOwnProperty('id')) ? instance.id : 'fakeinstance'+this.makeid(12),
				element_id: element.id,
				element: element,
				parent_id: (instance && instance.hasOwnProperty('parent_id')) ? instance.parent_id : null,
				parent: (instance && instance.hasOwnProperty('parent')) ? instance.parent : null,
				
				/*
				organization_id: null,
				team_id: null,
				
				instantiable_type: null,
				instantiable_id: null,
				instantiable: null,
				
				referenceable_type: null,
				referenceable_id: null,
				referenceable: null,
				
				min_role_level: 0,
				order_column: 0,
				*/
				enabled: (instance && instance.hasOwnProperty('enabled')) ? instance.enabled : true,
				
				options: options,
				processed_data: [],
				processed_options: processed_options,
			};
			
			//console.log('Sto creando questa instance:');
			//console.log(JSON.stringify(defaultInstance.processed_options));
			
			return defaultInstance;
			
			return {...defaultInstance, ...instance};
		},
		
		
		formatCurrency: function(value=0, currency='EUR', style='en-US', adaptStripeMode=false) {
			if (! currency) { // se non c'è la currency per qualche motivo mi fermo subito
				return value;
			}
			
			if (adaptStripeMode) { // L'importo è nella sua unità più piccola disponibile per la valuta
				if (['eur', 'chf'].includes(currency.toLowerCase()) && value) { // in questo caso centesimi
					value = value/100;
				}
			}
			
			if (style == 'auto') {
				style = 'en-US';
				style = 'it-IT';
				
				if (this.$route && this.$route.params.hasOwnProperty('countryCode')) {
					style = this.$route.params.countryCode;
				}
				
				// if (this.getUserLang()) {
				// 	style = this.getUserLang();
				// }
			}
			
			var formatter = new Intl.NumberFormat(style, {
				style: 'currency',
				currency: currency,
				
				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});
				
			return formatter.format(value);
		  },
		
		
		/* Momentjs conversions */
		toUserTz(datetime, tz=null) {
			var utcmom = moment.utc(datetime);
			
			utcmom.locale('it-IT'); // default language: italiano
			
			if (tz == null) {
				var userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				
				if (userTimezone) {
					return utcmom.tz(userTimezone);
				}
				else { // forzo il timezone italiano
					return utcmom.tz('Europe/Rome');
				}
			}
			else {
				return utcmom.tz(tz);
			}
			
			return utcmom;
		},
		
		
		/* Other... */
		getVueFullDomain: function(path=null) {
			return window.location.origin+path;
		},
		
		removeVueDomain(path=null) {
			return (path) ? path.replace(window.location.origin, '') : path;
		},
		
		removeParam: function(key, sourceURL) {
			var rtn = sourceURL.split("?")[0],
				param,
				params_arr = [],
				queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
			if (queryString !== "") {
				params_arr = queryString.split("&");
				for (var i = params_arr.length - 1; i >= 0; i -= 1) {
					param = params_arr[i].split("=")[0];
					if (param === key) {
						params_arr.splice(i, 1);
					}
				}
				if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
			}
			return rtn;
		},
		
		asset(path) {
			var base_path = window._asset || '';
			return base_path + path;
		},
		
		cdn_path(path) {
			var base_path = window.cdn_asset || '';
			return base_path + path;
		},
		
		nl2br(str, is_xhtml) { // Convert new lines into <br> tag
			if (typeof str === 'undefined' || str === null) {
				return '';
			}
			var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
			return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
		},
		
		urlify(text) { // Detect urls in string and convert it in html links
			const vm = this;
			var urlRegex = /(https?:\/\/[^\s]+)/g;
			
			if (! text || text === null) {
				return text;
			}
			
			return text.replace(urlRegex, function(url) {
				if (vm.isExternalUrl(url)) {
					return '<a href="'+url+'" rel="nofollow" target="_blank" class="target-blank">'+url+'</a>';
				}
				return '<a href="' + url + '" data-b5-href="'+vm.removeVueDomain(url)+'">' + url + '</a>';
			})
			// or alternatively
			// return text.replace(urlRegex, '<a href="$1">$1</a>')
		},
		
		isValidUrl: function(url) {
			var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
				'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
			  return !!pattern.test(url);
		},
		
		isExternalUrl: function(url) {
			if (! this.isValidUrl(url)) {
				return false;
			}
			
			return (new URL(url).origin !== location.origin);
		},
		
		encodeHTML(s) { // Al pari di {{ variabile }}, converte il testo in leggibile in front end, disabilitando i tags html
			if (s && s !== null) {
				return s
					.replace(/&/g, '&amp;')
					.replace(/</g, '&lt;')
					.replace(/"/g, '&quot;');
			}
			return s;
		},
		
		makeid: function(length=12) {
			var result = '';
			var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			var charactersLength = characters.length;
			for ( var i = 0; i < length; i++ ) {
				result += characters.charAt(Math.floor(Math.random() * 
				charactersLength));
			}
			return result;
		},
		
		invalidResponseManager: function(error) {
			const vm = this;
			//console.log('%cError triggered through response manager', 'color: green;font-size:1.5rem');
			//console.log(error);
			//console.log(JSON.stringify(error));
			
			if (error) {
				if (error.hasOwnProperty('status') && error.status == 429) {
					vm.createToastAndShow({
						message: 'Si è verificato un errore. Riprova tra qualche minuto.',
						color: 'bg-warning',
					});
				}
				else if (
					error.hasOwnProperty('status') && (
						error.status == 500 || error.status == 404 || error.status == 405 // server error + missing route + route method not allowed
					)
				) {
					vm.createToastAndShow({
						message: 'Si è verificato un errore. Riprova tra qualche minuto.',
						color: 'bg-warning',
					});
				}
				else if (error.hasOwnProperty('status') && error.status == 401) { // forcing refresh for logout
					window.location.href = this.getVueFullDomain('/');
				}
				else if (error.hasOwnProperty('status') && error.status == 419) { // csrf token mismatch "Request failed with status code 419"
					// refresh and add a flash message
					var url = window.location.href;    
					if (url.indexOf('?') > -1){
					   //url += '&flashmessage='+vm.__('gui.status.error_419_page_refreshed')
					}else{
					   //url += '?flashmessage='+vm.__('gui.status.error_419_page_refreshed')
					}
					window.location.href = url;
				}
				else if (error.hasOwnProperty('message') && error.message !== null) {
					if (error.message == 'redirect:homepage') { // forzo redirect alla homepage
						window.location.href = this.getVueFullDomain('/');
					}
					else if (error.message == "Request failed with status code 419") {
						// refresh and add a flash message
						var url = window.location.href;    
						if (url.indexOf('?') > -1){
						   //url += '&flashmessage='+vm.__('gui.status.error_419_page_refreshed')
						}else{
						   //url += '?flashmessage='+vm.__('gui.status.error_419_page_refreshed')
						}
						window.location.href = url;
					}
					else if (error.message == 'not-available-on-test-team') {
						if (! this.pushToMainViewErrors({ message: 'test-team' })) {
							vm.hideAllBSModals();
							vm.$router.push({ name: 'error.test-team' });
						}
					}
					else if (error.message == 'pending-teams') {
						if (! this.pushToMainViewErrors({ message: 'pending-teams' })) {
							vm.hideAllBSModals();
							vm.$router.push({ name: 'error.pending-teams' });
						}
					}
					else if (error.message == 'terms-updated') {
						window.location.href = '/terms/latest';
					}
					else if (error.message == 'no-teams' || error.message == 'You have no teams') {
						if (! this.pushToMainViewErrors({ message: 'no-teams' })) {
							vm.hideAllBSModals();
							vm.$router.push({ name: 'error.no-teams' });
						}
					}
					else if (error.message == 'Missing privileges') {
						vm.hideAllBSModals();
						if (! this.pushToMainViewErrors(error)) {
							vm.hideAllBSModals();
							vm.hideAllBSOffcanvas();
							vm.hideAllBSTooltips();
							setTimeout(function() {
								vm.$router.push({ name: 'error.missing-privileges' });
							}, 750);
						}
					}
					
					else if (error.message == "current_team_ecg_membership_required") { // Quota della membership richiesta dal team non pagata ecg
						//if (! this.pushToMainViewErrors({ message: 'current_team_membership_required' })) {
							vm.$router.push({ name: 'ecg.membership.index' });
							//vm.$router.push({ name: 'error.current_team_membership_required' });
						//}
					}
					
					else if (error.message == "current_team_membership_required") { // Quota della membership richiesta dal team non pagata
						//if (! this.pushToMainViewErrors({ message: 'current_team_membership_required' })) {
							vm.$router.push({ name: 'manage.team.membership.index' });
							//vm.$router.push({ name: 'error.current_team_membership_required' });
						//}
					}
					else if (error.message == "current_team_membership_no_more_required") { // Quota membership non più richiesta ma abbonamento ancora attivo
						vm.$router.push({ name: 'error.current_team_membership_no_more_required' });
					}
					else if (error.message == "current_team_membership_require_quantity_update") { // Il membro del team deve approvare il cambiamento di quantità nella quota obbligatoria aggiornata dall'admin del team
						//if (! this.pushToMainViewErrors({ message: 'current_team_membership_required' })) {
							vm.$router.push({ name: 'error.current_team_membership_require_quantity_update' });
						//}
					}
					else if (error.message == "current_organization_quota_required_for_owner") { // L'organization owner non ha pagato la quota dell'organizzazione: messaggio per l'organization_owner
						//if (! this.pushToMainViewErrors({ message: 'current_organization_quota_required_for_owner' })) {
							vm.$router.push({ name: 'error.current_organization_quota_required_for_owner' });
						//}
					}
					else if (error.message == "current_organization_quota_required_for_user") { // L'organization owner non ha pagato la quota dell'organizzazione: messaggio per tutti gli altri utenti
						//if (! this.pushToMainViewErrors({ message: 'current_organization_quota_required_for_user' })) {
							vm.$router.push({ name: 'error.current_organization_quota_required_for_user' });
						//}
					}
					
					else if (error.message == 'user-banned') {
						window.location.href = '/error/banned';
					}
					else if (error.message == 'Unauthenticated.' || error.message == 'Request failed with status code 401') { // forcing refresh for logout
						window.location.href = this.getVueFullDomain('/');
					}
					else if (
						error.message == 'Quicklink not found'
					) {
						if (! this.pushToMainViewErrors({ message: '404 Not Found' })) {
							vm.createToastAndShow({
								message: 'Si è verificato un errore: ' + error.message,
								color: 'bg-danger',
							});
						}
					}
					else {
						vm.createToastAndShow({
							message: 'Si è verificato un errore: ' + error.message,
							color: 'bg-danger',
						});
					}
				}
				else {
					vm.createToastAndShow({
						message: 'Si è verificato un errore imprevisto. Riprova tra qualche minuto.',
						color: 'bg-warning',
					});
				}
			}
			else {
				vm.createToastAndShow({
					message: 'Si è verificato un errore imprevisto. Riprova tra qualche minuto.',
					color: 'bg-warning',
				});
			}
		},
		
	},
}