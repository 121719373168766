<template>
	<div class="elements--post-category--hero-section-v1">
		<section :class="getSectionClasses()" v-bind:data-src="hasBackgroundPicture() ? getFileVersionUrl(this.instance.processed_options.background_picture.media, {
			  types: [ 'image_1920', 'image_1080', 'image_720', 'original' ],
		  }) : ''">
			<div :class="getOverlay()+' position-relative'">
			<div :class="getOverlayClasses()">
				<div class="b5-container-xl">
				<div v-if="hasMainPicture()" class="col-12 col-lg-5 position-relative" v-bind:class="getMediaPosition() == 'start' ? 'order-1' : 'order-2'">
					<img :data-src="getFileVersionUrl(instance.processed_options.main_picture.media)" class="lazy img-fluid card-img h-100" alt="" />
					<div class="img-bg lazy bg-cover position-absolute top-0 start-0 end-0 bottom-0 w-100 h-100" :data-src="getFileVersionUrl(instance.processed_options.main_picture.media)"></div>
				</div>
				<div :class="getMainColClasses()">
					<div class="position-relative h-100">
						<div :class="getBodyClasses()">
							
							
							<div v-if="instance.hasOwnProperty('childrens') && Array.isArray(instance.childrens)">
								<div v-for="(item, index) in instance.childrens" :key="item.id">
									<dynamic-element-render group="post.category" :instance="item"></dynamic-element-render>
								</div>
							</div>
							
							
							<div class="mt-auto b5-container-sm" v-if="instance.options.cta_text || instance.options.cta_secondary_text">
								<button type="button" v-if="instance.options.cta_text" :class="'btn-lg '+getButtonClasses('primary')">
									{{ instance.options.cta_text }}
								</button>
								
								<button type="button" v-if="instance.options.cta_secondary_text" :class="getButtonClasses('secondary')">
									{{ instance.options.cta_secondary_text }}
								</button>
							</div>
						</div>
					</div>
				</div>
				</div>
				
			</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			getSectionClasses: function() {
				var classes = [
					'b5-bg-body2 d-block p-0'
				];
				
				classes.push('bg-gradient-'+this.getBackgroundGradient());
				
				if (this.hasBackgroundPicture()) {
					classes.push('lazy bg-cover');
				}
				
				if (! this.instance.options.hasOwnProperty('container_size')) {
					classes.push('b5-container-lg');
				}
				else {
					if (this.instance.options.container_size == 'small') {
						classes.push('b5-container-sm');	
					}
					else if (this.instance.options.container_size == 'default') {
						classes.push('b5-container-lg');	
					}
					else if (this.instance.options.container_size == 'large') {
						classes.push('b5-container-xl');	
					}
					else if (this.instance.options.container_size == 'xl') {
						classes.push('b5-container-xxl');	
					}
					else if (this.instance.options.container_size == 'full-width') {
						classes.push('w-100');	
					}
				}
				
				return classes.join(' ');
			},
			
			getOverlayClasses: function() {
				var classes = ['d-flex w-100 align-items-center justify-content-center py-3 py-lg-5 position-relative h-100'];
				
				if (this.getTextColor()) {
					classes.push(this.getTextColor());
				}
				
				classes.push(this.getTextAlignment());
				
				if (this.getMediaPosition()) {
					classes.push('order-2');
				}
				else {
					classes.push('order-1');
				}
				
				return classes.join(' ');
			},
			
			getMainColClasses: function() {
				var classes = ['col-12 main-col'];
				
				if (this.hasMainPicture()) {
					classes.push('col-lg-7');
				}
				
				return classes.join(' ');
			},
			
			getBodyClasses: function() {
				var classes = ['p-4'];
				
				if (this.getBackgroundImageStyle() == 'full-image') {
					classes.push('position-absolute top-0 end-0 start-0 bottom-0 d-flex w-100 h-100 flex-column');
				}
				else {
					classes.push('d-flex w-100 h-100 flex-column justify-content-center');
				}
				
				return classes.join(' ');
			},
			
			getBackgroundImageStyle: function() {
				const vm = this;
				const defaultStyle = 'cover';
				
				if (! vm.instance.options.hasOwnProperty('background_picture_style') || ! this.hasBackgroundPicture()) {
					return defaultStyle;
				}
				
				if (vm.instance.options.background_picture_style == 'full-image') {
					return 'full-image';
				}
				
				return defaultStyle;
			},
			
			
			
			
			hasMainPicture: function() {
				const main_picture = this.getProcessedOption('main_picture');
				return (main_picture && main_picture.hasOwnProperty('media') && main_picture.media && this.getFileVersionUrl(main_picture.media));
			},
			
			
			getMediaPosition: function() {
				const vm = this;
				const defaultPosition = 'start';
				
				if (! vm.instance.options.hasOwnProperty('main_media_position')) {
					return 'start';
				}
				
				if (vm.instance.options.main_media_position == 'end') {
					return 'end';
				}
				
				return defaultPosition;
			},
			
			
			getTextColor: function() {
				const text_color = this.getProcessedOption('text_color');
				if (text_color && text_color.hasOwnProperty('value')) {
					if (text_color.value == 'dark') {
						return 'text-dark';
					}
					else if (text_color.value == 'light') {
						return 'text-light';
					}
				}
			},
			
			
			getButtonClasses: function(btn='primary') {
				const vm = this;
				var classes = [];
				
				if (btn == 'primary') {
					classes.push('btn rounded-app d-block w-100 mt-2 b5-bg-opaque');
					
					if (vm.instance.options.hasOwnProperty('text_color')) {
						if (vm.instance.options.text_color == 'dark') {
							classes.push('btn-opaque-dark-sm');
						}
						else if (vm.instance.options.text_color == 'light') {
							classes.push('btn-opaque-light');
						}
						else {
							if (this.hasBackgroundPicture()) {
								classes.push('btn-opaque');
							}
							else {
								classes.push('btn-primary');
							}
						}
					}
					else {
						if (this.hasBackgroundPicture()) {
							classes.push('btn-opaque');
						}
						else {
							classes.push('btn-primary');
						}
					}
					
				}
				else if (btn == 'secondary') {
					classes.push('btn rounded-app d-block w-100 mt-2');
					
					if (vm.instance.options.hasOwnProperty('text_color')) {
						if (vm.instance.options.text_color == 'dark') {
							classes.push('btn-outline-dark');
						}
						else if (vm.instance.options.text_color == 'light') {
							classes.push('btn-outline-light');
						}
						else {
							if (this.hasBackgroundPicture()) {
								classes.push('btn-outline');
							}
							else {
								classes.push('btn-evidence');
							}
						}
					}
					else {
						if (this.hasBackgroundPicture()) {
							classes.push('btn-outline');
						}
						else {
							classes.push('btn-evidence');
						}
					}
				}
				
				return classes.join(' ');
			},
			
			getTextAlignment: function() {
				const vm = this;
				
				if (! vm.instance.options.hasOwnProperty('text_alignment')) {
					return '';
				}
				
				if (vm.instance.options.text_alignment == 'center') {
					return 'text-center';
				}
				
				return '';
			},
			
			  
			  getBackgroundGradient: function() {
				  const bg_gradient = this.getProcessedOption('bg_gradient');
				  return (bg_gradient && bg_gradient.hasOwnProperty('value')) ? bg_gradient.value : null;
			  },
			
			getOverlay: function() {
				const vm = this;
				var str = '';
				
				
				if (this.instance.options.hasOwnProperty('full_height') && this.instance.options.full_height) {
					str = 'min-vh-90 d-flex align-items-center justify-content-center w-100';
				}
				
				if (! vm.instance.options.hasOwnProperty('background_overlay')) {
					return str;
				}
				
				if (vm.instance.options.background_overlay == 'light-sm') {
					return str+' overlay overlay-light overlay-sm';
				}
				else if (vm.instance.options.background_overlay == 'light-md') {
					return str+' overlay overlay-light overlay-md';
				}
				else if (vm.instance.options.background_overlay == 'light-xl') {
					return str+' overlay overlay-light overlay-xl';
				}
				else if (vm.instance.options.background_overlay == 'dark-sm') {
					return str+' overlay overlay-dark overlay-sm';
				}
				else if (vm.instance.options.background_overlay == 'dark-md') {
					return str+' overlay overlay-dark overlay-md';
				}
				else if (vm.instance.options.background_overlay == 'dark-xl') {
					return str+' overlay overlay-dark overlay-xl';
				}
				
				return;
			},
			
			
			
			hasBackgroundPicture: function() {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty('background_picture') 
					&& this.instance.processed_options.background_picture !== null 
					&& this.instance.processed_options.background_picture.hasOwnProperty('media')
					&& this.getFileVersionUrl(this.instance.processed_options.background_picture.media)
				);
			},
			
			
			
			hasThumbnail: function() {
				
				if (this.instance.hasOwnProperty('processed_options') && this.instance.processed_options.hasOwnProperty('picture') && this.instance.processed_options.picture.hasOwnProperty('media')) {
					return this.getFileVersionUrl(this.instance.processed_options.picture.media);
				}
			  return false;
		  },
		  
		  getThumbnail: function() {
			  if (this.hasThumbnail()) {
				  return this.getFileVersionUrl(this.instance.processed_options.picture.media);
			  }
			  
			  return '';
		  },
		  
		  
		  
		  
		  
		  getProcessedOption: function(name) {
			  return (this.instance.hasOwnProperty('processed_options') && this.instance.processed_options && this.instance.processed_options.hasOwnProperty(name)) ? this.instance.processed_options[name] : null;
		  },
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				loadLazy();
			});
		}
	}
</script>