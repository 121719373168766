<template>
	<div :class="getClasses()">
		<dynamic-element-render v-if="instance.childrens?.length"
			v-for="(item, index) in instance.childrens" :key="'col-'+makeid(12)+'-'+item.id"
			group="page" :instance="item" />
	</div>
</template>

<script>
export default {
	props: {
		instance: null
	},
	
	methods: {
		getClasses: function() {
			var classes = [];
			
			if (this.instance.options.column_width_mobile == 'dynamic' && this.instance.options.column_width_desktop == 'dynamic') {
				classes.push('col');
			}
			else {
				classes.push('col-'+this.instance.options.column_width_mobile);
				classes.push('col-lg-'+this.instance.options.column_width_desktop);
			}
			
			return classes;
		}
	},
}
</script>