<template>
	<div class="elements--post-category--quick-video">
		
		<a data-bs-toggle="modal" :data-bs-target="'#' + modal_id" class="text-decoration-none text-reset cursor-pointer">
			<div class="card shadow-sm border-0">
				<div class="d-flex align-items-stretch w-100" :class="getColorScheme()" v-bind:class="(hasOption('text_alignment') && instance.options.text_alignment == 'center') ? 'text-center' : ''">
				
					<div class="ratio ratio-1x1" style="width:80px" v-show="hasMainPicture()">
						<div class="bg-cover lazy" v-bind:data-src="(hasMainPicture()) ? instance.processed_options.main_picture.media.url : null"></div>
					</div>
					
					<div class="d-flex w-100 align-items-center bg-cover lazy rounded" v-bind:class="(hasOption('text_alignment') && instance.options.text_alignment == 'center') ? 'justify-content-center' : 'justify-content-start'" v-bind:data-src="(hasBackgroundPicture()) ? instance.processed_options.background_picture.media.url : null">
						<div class="card-body" v-bind:class="(hasMainPicture()) ? 'pl-lg-0' : 'pl-4'">
							<h4 class="card-title mb-0 fs-5 fw-bold" v-show="hasOption('title')">{{ instance.options.title }}</h4>
							
							<p class="text-sm mb-0 text-secondary" v-show="hasOption('excerpt') && instance.options.excerpt">{{ instance.options.excerpt }}</p>
						</div>
						
						<div class="ms-auto align-self-center" v-show="(hasOption('play_icon') && instance.options.play_icon)">
							<button type="button" class="btn btn-lg" v-bind:class="getColorScheme()">
								<i class="bi bi-play-circle-fill fs-4"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</a>
		
		<div class="modal fade modal-quick-video" :id="modal_id" tabindex="-1" :aria-labelledby="modal_id+'-label'" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header border-0" style="background:#000">
						<h5 class="modal-title" :id="modal_id+'-label'"></h5>
						<button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body px-0 py-0 d-flex align-items-center justify-content-center" style="background:#000">
						<div class="position-absolute top-0 start-50 h-100 translate-middle-x">
							<img class="d-block w-auto h-100" src="https://dummyimage.com/1080x1920/red/fff.gif">
							<div class="position-absolute top-0 bottom-0 start-0 end-0">
								[video...]
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				modal_id: 'modal-quick-video-'+this.makeid(12),
				data: {
					
				}
			};
		},
		
		methods: {
			makeid: function(length) {
				var result           = '';
				var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				var charactersLength = characters.length;
				for ( var i = 0; i < length; i++ ) {
				  result += characters.charAt(Math.floor(Math.random() * 
			 charactersLength));
			   }
			   return result;
			},
			
			getColorScheme: function() {
				if (this.instance.options.color_scheme == 'dark') {
					return 'bg-dark text-white';
				}
				else {
					return 'bg-white text-dark';
				}
			},
			
			hasMainPicture: function() {
				var instance = this.instance;
				return (
					instance.processed_options.main_picture 
					&& instance.processed_options.main_picture.media 
					&& instance.processed_options.main_picture.media.url
				);
			},
			
			hasBackgroundPicture: function() {
				var instance = this.instance;
				return (
					instance.processed_options.background_picture 
					&& instance.processed_options.background_picture.media 
					&& instance.processed_options.background_picture.media.url
				);
			},
			
			hasOption: function(name) {
				return (
					this.instance.hasOwnProperty('processed_options')
					&& this.instance.processed_options !== null
					&& this.instance.processed_options.hasOwnProperty(name)
					&& this.instance.processed_options[name] !== null
				);
			}
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
			
			this.$nextTick(function() {
				var beQVM = document.getElementById(vm.modal_id)
				var videoEl = document.getElementById(vm.modal_id).getElementsByClassName("b5-video-player")[0];
				
				if (videoEl !== undefined && videoEl !== null) {
					beQVM.addEventListener('hidden.bs.modal', function (event) {
					  // stop video...
					  var mVideoId = videojs(videoEl.id);
					  mVideoId.pause();
					  mVideoId.currentTime(0);
					});
					
					beQVM.addEventListener('show.bs.modal', function (event) {
					  // auto play video...
					  var mVideoId = videojs(videoEl.id);
					  mVideoId.play();
					});
				}
				
				loadLazy();
				
			});
		}
	}
</script>