<template>
	<div class="elements--post---go-herbalife-button b5-container-lg" v-if="getUrl()">
		<a :href="getUrl()" class="btn btn-success rounded-pill btn-lg d-block w-100 target-blank bg-gradient shadow-sm" target="_blank" rel="nofollow">
			<i class="fas fa-shopping-cart fs-4 me-1"></i> {{ data.options.cta_text || 'Vai su GoHerbalife' }}
		</a>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			getUrl: function() {
				if (this.data.hasOwnProperty('processed_data') && this.data.processed_data && this.data.processed_data.hasOwnProperty('goherbalife_url') && this.data.processed_data.goherbalife_url) {
					if (this.data.options.path) {
						return (this.data.processed_data.goherbalife_url +'/' + this.data.options.path).replace(/([^:]\/)\/+/g, "$1"); // removing extra double slash //
					}
					return this.data.processed_data.goherbalife_url;
				}
			}
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>