<template>
	<div class="elements--post-category--heading b5-container-lg">
		<div class="container-xl pt-5 mb-3">
			<h1 :class="'mb-0 '+getTitleClasses()" v-if="instance.options.title">
				{{ instance.options.title }}
			</h1>
			
			<p class="mb-0" v-if="instance.options.excerpt">
				{{ instance.options.excerpt }}
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			getTitleClasses: function() {
				var classes = [];
				
				if (this.instance.options.hasOwnProperty('font_family')) {
					classes.push('font-'+this.instance.options.font_family);
				}
				
				return classes.join(' ');
			}
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>