<template>
	<div class="elements--post---vimeo-video b5-container-lg" v-if="hasVideoUrl()">
		<div class="ratio ratio-16x9">
			<iframe :src="'https://player.vimeo.com/video/'+data.options.video_id" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="rounded-app"></iframe>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			hasVideoUrl: function() {
				return (this.data && this.data.hasOwnProperty('options') && this.data.options && this.data.options.hasOwnProperty('video_id') && this.data.options.video_id);
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
		}
	}
</script>