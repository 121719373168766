<template>
	<div class="elements--page--countdown">
		<section class="countdown-timer-widget-card d-block countdown-timer" :data-countdown-to="getCountdownDateTime()">
			<div class="b5-container-sm">
				<div class="countdown-area">
					<div class="countdown-elements d-flex justify-content-around align-items-center">
						<div class="px-2 py-2 text-center rounded mx-1 flex-fill coutdown-card-days" v-bind:class="getColorScheme()">
							<div class="countdown-fill-days fs-3 fw-bold mb-0 lh-1" data-amount="0">0</div>
							<span class="text-sm lh-1">gio</span>
						</div>
						<div class="px-2 py-2 text-center rounded mx-1 flex-fill coutdown-card-hours" v-bind:class="getColorScheme()">
							<div class="countdown-fill-hours fs-3 fw-bold lh-1" data-amount="0">0</div>
							<span class="text-sm lh-1">ore</span>
						</div>
						<div class="px-2 py-2 text-center rounded mx-1 flex-fill coutdown-card-minutes" v-bind:class="getColorScheme()">
							<div class="countdown-fill-minutes fs-3 fw-bold lh-1" data-amount="0">0</div>
							<span class="text-sm lh-1">min</span>
						</div>
						<div class="px-2 py-2 text-center rounded mx-1 flex-fill coutdown-card-seconds" v-bind:class="getColorScheme()">
							<div class="countdown-fill-seconds fs-3 fw-bold lh-1" data-amount="0">0</div>
							<span class="text-sm lh-1">sec</span>
						</div>
					</div>
				</div>
				<div class="on-expired-area" style="display:none">
					<alert :settings="{
						type: 'warning text-center'
					}">Il conto alla rovescia è terminato</alert>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			getColorScheme: function() {
				if (this.instance.options.color_scheme == 'dark') {
					return 'bg-dark text-white';
				}
				else {
					return 'bg-white text-dark';
				}
			},
			
			getCountdownDateTime: function() {
				return this.instance.processed_options.to_date.value +' '+this.instance.processed_options.to_time.value +':00';
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				loadLazy();
			});
		}
	}
</script>