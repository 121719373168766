<template>
	<div class="elements--post-category--tags-collection b5-container-xl">
		<div class="heading mb-2">
			<div class="d-flex justify-content-between align-items-top">
				<h4 v-if="instance.options.title" class="mb-0 fw-bold text-opposite">
					{{ instance.options.title }}
				</h4>
			
				<b5-link v-if="hasCtaAction() && hasCtaText()" :href="instance.options.cta_web_url" class="btn btn-ghost text-primary text-nowrap text-decoration-none p-0">
					{{ instance.options.cta_text }}
					
					<i class="b5-fa-icon fa-chevron-right"></i>
				</b5-link>
			</div>
			
			<p v-if="instance.options.excerpt" class="mb-0 text-secondary">
				{{ instance.options.excerpt }}
			</p>
		</div>
		
		<div :class="getRowClasses()">
			<div :class="getColumnClasses()" v-for="(item, index) in hasData() ? instance.processed_data : 10" :key="index">
			
				
				<router-link :to="{ name: 'post.tag.show', params: { tagSlug: item.slug } }" v-if="getDesign() == 'pills-simple'" class="text-decoration-none text-center text-reset py-1 px-4 rounded-pill border" v-bind:class="hasData() ? 'list-group-item list-group-item-action' : 'placeholder'">
					<span class="fw-bold text-opposite" v-bind:class="hasData() ? '' : 'w-100 h-100 mx-5'">{{ item.name }}</span>
				</router-link>
				
				<router-link :to="{ name: 'post.tag.show', params: { tagSlug: item.slug } }" v-else-if="getDesign() == 'link'" class="btn btn-link text-decoration-none" v-bind:class="hasData() ? '' : 'text-secondary'">
					<i class="b5-fa-icon fa-hashtag"></i> <span v-bind:class="hasData() ? '' : 'px-5 b5-bg-body2 rounded'">{{ item.name }}</span>
				</router-link>
				
				
				<router-link :to="{ name: 'post.tag.show', params: { tagSlug: item.slug } }" v-else-if="getDesign() == 'pills'" class="text-decoration-none text-reset d-flex w-100 align-items-center justify-content-start ps-3 pe-4 py-1 rounded-pill border" v-bind:class="hasData() ? 'list-group-item list-group-item-action' : 'placeholder'">
					<div v-if="hasItemIcon(item)" class="me-2">
						<div v-if="getItemOption(item, 'icon_type') == 'emoji'">
						  <i class="fs-5 emoji">{{ getItemOption(item, 'icon') }}</i>
						</div>
						<div v-else-if="getItemOption(item, 'icon_type') == 'font-awesome' || getItemOption(item, 'icon_type') == 'bootstrap-icons'">
						  <i class="fs-5 b5-fa-icon fa-fw" v-bind:class="getItemOption(item, 'icon')"></i>
						</div>
					</div>
					<div v-else-if="getFileVersionUrl(item.thumbnail)" style="width:22px;height:22px" class="me-2">
						<div class="ratio ratio-1x1 rounded-circle">
							<div v-if="getFileVersionUrl(item.thumbnail)" class="bg-cover lazy w-100 h-100 rounded-circle" :data-src="getFileVersionUrl(item.thumbnail)"></div>
						</div>
					</div>
					<span class="fw-bold text-opposite" v-bind:class="hasData() ? '' : 'w-100 h-100 mx-5 py-3'">{{ item.name }}</span>
				</router-link>
					
				<div v-else-if="getDesign() == 'overlay'" class="card border-0 list-group-item list-group-item-action rounded-app w-100 p-0 h-100 bg-cover lazy" :data-src="getFileVersionUrl(item.thumbnail)">
					<router-link :to="{ name: 'post.tag.show', params: { tagSlug: item.slug } }" class="p-0 text-reset text-decoration-none" v-bind:class="(getFileVersionUrl(item.thumbnail)) ? 'card-img-overlay' : ''">
						<div class="d-flex justify-content-center align-items-center w-100 h-100 text-center px-3 py-5 fw-bold rounded-app border" v-bind:class="{
							'overlay-dark overlay-sm text-white' : getFileVersionUrl(item.thumbnail),
							'text-opposite' : ! getFileVersionUrl(item.thumbnail),
							'placeholder' : ! hasData()
						}">
							{{ item.name }}
						</div>
					</router-link>
				</div>
				
				<div v-else-if="getDesign() == 'circle'" class="text-center">
					<router-link :to="{ name: 'post.tag.show', params: { tagSlug: item.slug } }" class="text-reset text-decoration-none d-block w-100 text-center">
						<div class="ratio ratio-1x1 rounded-circle mb-2 border p-0" v-bind:class="hasData() ? 'list-group-item list-group-item-action' : 'placeholder'">
							<div v-if="getFileVersionUrl(item.thumbnail)" class="bg-cover lazy w-100 h-100 rounded-circle" :data-src="getFileVersionUrl(item.thumbnail)"></div>
							<div v-else-if="hasItemIcon(item)" class="d-flex justify-content-center align-items-center w-100 h-100">
								<div v-if="getItemOption(item, 'icon_type') == 'emoji'">
								  <i class="fs-1 emoji">{{ getItemOption(item, 'icon') }}</i>
								</div>
								<div v-else-if="getItemOption(item, 'icon_type') == 'font-awesome' || getItemOption(item, 'icon_type') == 'bootstrap-icons'">
								  <i class="fs-1 b5-fa-icon fa-fw" v-bind:class="getItemOption(item, 'icon')"></i>
								</div>
							</div>
						</div>
						<span class="fw-bold text-opposite">{{ item.name }}</span>
					</router-link>
				</div>
				
				<div v-else-if="getDesign() == 'classic'" class="text-center">
					<router-link :to="{ name: 'post.tag.show', params: { tagSlug: item.slug } }" class="text-reset text-decoration-none d-block w-100 text-center">
						<img v-if="getFileVersionUrl(item.thumbnail)" :data-src="getFileVersionUrl(item.thumbnail)" class="img-fluid w-100 mx-auto rounded-app mb-2 lazy" alt="" />
						<span class="fw-bold text-opposite">{{ item.name }}</span>
					</router-link>
				</div>
				
				
			</div>
		</div>
		<!--
		<div v-else class="placeholder-glow row flex-row g-1 flex-nowrap justify-content-start overflow-hidden">
			<div v-for="index in 10" :key="index" class="d-inline-flex w-auto mb-2">
				<span class="text-center py-1 px-4 rounded-pill border placeholder">
					<span class="h-100 w-100 b5-bg-body mx-5"></span>
				</span>
			</div>
		</div>
	-->
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {
					
				}
			};
		},
		
		methods: {
			getRowClasses: function() {
				var classes = ['row'];
				const design = this.getDesign();
				const size = this.getSize();
				const layout = this.getLayout();
				
				if (! this.hasData()) {
					classes.push('placeholder-glow');
				}
				
				if (layout == 'scroll') {
					classes.push('magnetic-scroll-on-mobile');
					
					if (design == 'link' || design == 'pills' || design == 'pills-simple') {
						classes.push('flex-row g-1 flex-nowrap justify-content-start overflow-auto');
					}
					else {
						classes.push('flex-row g-2 flex-nowrap justify-content-start overflow-auto');
					}
					
					if (this.hasData()) {
						classes.push('overflow-auto');
					}
					else {
						classes.push('overflow-hidden');
					}
				}
				else if (layout == 'full_list') {
					classes.push('align-items-stretch');
					
					if (design == 'overlay' || design == 'classic') {
						classes.push('g-2');
					}
				}
				
				return classes.join(' ');
			},
			
			getColumnClasses: function() {
				var classes = ['magnetic-column'];
				const design = this.getDesign();
				const size = this.getSize();
				const layout = this.getLayout();
				
				if (design == 'link' || design == 'pills' || design == 'pills-simple') {
					classes.push('mb-2');
					
					if (layout == 'scroll') {
						classes.push('d-inline-flex w-auto');
					}
					else if (layout == 'full_list') {
						classes.push('col-6 col-lg-4');
					}
				}
				else if (design == 'circle') {
					if (layout == 'scroll') {
						if (size == 'large') {
							classes.push('col-11 b5-col-lg-3');
						}
						else if (size == 'default') {
							classes.push('b5-col-3 b5-col-md-4 b5-col-lg-5');
						}
						else if (size == 'small') {
							classes.push('b5-col-5');
						}
					}
					else if (layout == 'full_list') {
						if (size == 'large') {
							classes.push('col-12 col-lg-4');
						}
						else if (size == 'default') {
							classes.push('col-6');
						}
						else if (size == 'small') {
							classes.push('col-4');
						}
					}
				}
				else if (design == 'overlay' || design == 'classic') {
					if (layout == 'scroll') {
						if (size == 'large') {
							classes.push('col-10');
						}
						else if (size == 'default') {
							classes.push('col-8');
						}
						else if (size == 'small') {
							classes.push('col-4 col-md-3 col-lg-3 col-xl-2');
						}
					}
					else if (layout == 'full_list') {
						if (size == 'large') {
							classes.push('col-12');
						}
						else if (size == 'default') {
							classes.push('col-6');
						}
						else if (size == 'small') {
							classes.push('col-4 col-md-3 col-lg-3 col-xl-2');
						}
					}
				}
				
				return classes.join(' ');
			},
			
			hasCtaText: function() {
				return (
					this.instance.options.hasOwnProperty('cta_text')
					&& this.instance.options.cta_text !== null
					&& this.instance.options.cta_text.length
				);
			},
			
			hasCtaAction() {
				return (
					this.instance.options.hasOwnProperty('cta_web_url')
					&& this.instance.options.cta_web_url !== null
				);
			},
			
			getDesign: function() {
				if (this.instance.options.hasOwnProperty('design') && this.instance.options.design !== null) {
					return this.instance.options.design;
				}
				
				return 'pills_simple';
			},
			
			getSize: function() {
				if (this.instance.options.hasOwnProperty('size') && this.instance.options.size !== null) {
					return this.instance.options.size;
				}
				
				return 'default';
			},
			
			getLayout: function() {
				if (this.instance.options.hasOwnProperty('layout') && this.instance.options.layout !== null) {
					return this.instance.options.layout;
				}
				
				return 'scroll';
			},
			
			hasItemIcon: function(item) {
				return (this.getItemOption(item, 'icon_type') !== null && this.getItemOption(item, 'icon') !== null);
			},
			  
			  getItemOption(item, name, fallback=null) {
				  if (this.itemHasOptions() && item.options.hasOwnProperty(name) && item.options[name] !== null) {
					  return item.options[name];
				  }
				  
				  return fallback;
			  },
			
			itemHasOptions: function(item) {
				return (item && item.hasOwnProperty('options') && item.options);
			},
			
			hasData: function() {
				return (this.instance.hasOwnProperty('processed_data') && this.instance.processed_data && this.instance.processed_data.length);
			},
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			const vm = this;
			
			this.$nextTick(function() {
				vm.loadLazy();
			});
		}
	}
</script>