var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elements--post-category--banner b5-container-xl position-relative"},[_c('b5-link',{staticClass:"text-decoration-none text-reset b5-transitionable b5-hover-scale stretched-link d-block banner-container",attrs:{"href":_vm.getDestination()}},[_c('div',{class:_vm.getClasses(),style:(_vm.getStyle())},[_c('div',{class:_vm.getMediaContainerClasses(),style:(_vm.getMediaContainerStyle())},[(_vm.hasMainPicture())?_c('img',{staticClass:"img-fluid banner-picture h-100z",attrs:{"src":_vm.getFileVersionUrl(_vm.instance.processed_options.main_picture.media),"alt":""}}):_vm._e(),_vm._v(" "),(_vm.hasMainVideo())?_c('video-player',{staticClass:"vjsplayer",attrs:{"video_options":{
				  autoplay: true,
				  controls: false,
				  controlBar: false,
				  track_watching: false,
				  muted: true,
				  loop: true,
				  poster: _vm.hasMainPicture() ? _vm.getFileVersionUrl(_vm.instance.processed_options.main_picture.media) : _vm.getFileVersionUrl(_vm.instance.processed_options.video.media, {
					types: ['image_720'],
				}),
				  sources: [
					{
						src: _vm.getFileVersionUrl(_vm.instance.processed_options.video.media, {
							types: ['mp4_360p_128kbps', 'original'],
						}),
						type: 'video/mp4'
					},
					{
						src: _vm.getFileVersionUrl(_vm.instance.processed_options.video.media, {
							types: ['mp4_480p_128kbps', 'original'],
						}),
						type: 'video/mp4'
					},
					{
						src: _vm.getFileVersionUrl(_vm.instance.processed_options.video.media, {
							types: ['mp4_720p_128kbps', 'original'],
						}),
						type: 'video/mp4'
					}
				  ]
			  },"settings":{
				  file_id: _vm.instance.processed_options.video.media.id,
			  }}}):_vm._e()],1),_vm._v(" "),_c('div',{class:_vm.getMainColClasses(),style:(_vm.getMainColStyle())},[_c('div',{staticClass:"h-100"},[(_vm.hasBackgroundPicture() && _vm.getBackgroundImageStyle() == 'full-image')?_c('img',{staticClass:"img-fluid w-100 img-base",attrs:{"src":_vm.getFileVersionUrl(_vm.instance.processed_options.background_picture.media),"alt":""}}):_vm._e(),_vm._v(" "),_c('div',{class:_vm.getBodyClasses()},[(_vm.instance.options.slogan)?_c('p',{class:'mb-0 fw-medium ' + _vm.getTextColor() + ' ' + _vm.getTextAlignment(),domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.urlify(_vm.encodeHTML(_vm.instance.options.slogan))))}}):_vm._e(),_vm._v(" "),(_vm.instance.options.title)?_c('h3',{class:'fw-bold mb-0 ' + _vm.getTextColor() + ' ' + _vm.getTextAlignment(),domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.urlify(_vm.encodeHTML(_vm.instance.options.title))))}}):_vm._e(),_vm._v(" "),(_vm.instance.options.excerpt)?_c('p',{class:'mb-0 ' + _vm.getTextColor() + ' ' + _vm.getTextAlignment(),domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.urlify(_vm.encodeHTML(_vm.instance.options.excerpt))))}}):_vm._e(),_vm._v(" "),(_vm.instance.options.subtext || _vm.instance.options.cta_text || _vm.instance.options.cta_secondary_text)?_c('div',{staticClass:"mt-auto pt-3"},[(_vm.instance.options.subtext)?_c('p',{class:'mb-0 fw-bold text-sm ' + _vm.getTextColor() + ' ' + _vm.getTextAlignment(),domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.urlify(_vm.encodeHTML(_vm.instance.options.subtext))))}}):_vm._e(),_vm._v(" "),(_vm.instance.options.cta_text)?_c('button',{class:_vm.getButtonClasses('primary'),attrs:{"type":"button"}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.instance.options.cta_text)+"\n\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(_vm.instance.options.cta_secondary_text)?_c('button',{class:_vm.getButtonClasses('secondary'),attrs:{"type":"button"}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.instance.options.cta_secondary_text)+"\n\t\t\t\t\t\t\t")]):_vm._e()]):_vm._e()])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }