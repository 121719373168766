<template>
	<div class="elements--post---star-points b5-container-lg" v-if="data.options.to_earn || data.options.to_achieve">
		<div class="row g-2">
			<div class="col" v-if="data.options.to_earn">
				<div class="rounded-app text-center text-white py-3 lh-sm px-3 bg-gradient" style="background-color:#cf1b78">
					<div class="fs-4 fw-bold mb-0">
						{{ data.options.to_earn }} 🌟
					</div>
					<span class="text-sm">guadagni</span>
				</div>
			</div>
			<div class="col" v-if="data.options.to_achieve">
				<div class="rounded-app text-center text-white py-3 lh-sm px-3 bg-gradient" style="background-color:#cf1b78">
					<div class="fs-4 fw-bold mb-0">
						{{ data.options.to_achieve }} 🌟
					</div>
					<span class="text-sm">riscattabile con</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;
			const defaultData = {
				id: null,
				element: {
					id: null,
					name: null,
					type: null,
				},
				options: {
					
				},
				processed_options: {
					
				}
			};
			vm.data = {...defaultData, ...vm.instance};
		},
		
		mounted() {
			this.$nextTick(function() {
				
			});
		}
	}
</script>